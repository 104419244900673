import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";

function MultipleCheckboxes({intl, settings, ...rest}) {
  const {currentStep, setFormData, setErrors} = useContext(FormContext);
  const {checkboxes, title, state, errors, hidden = false, required = true, divClasses = ''} = settings || {};

  const renderTitle = () => {
    if (!title) {
      return null;
    }

    return (
      <legend className="form-group__label">{intl.formatMessage({id: title})}</legend>
    )
  };

  if (hidden) {
    return '';
  }

  const handleChange = e => {
    const {name, checked = false} = e.target;

    setFormData(prev => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }

      prev[currentStep][settings.name] = {
        ...prev[currentStep][settings.name],
        [name]: checked
      };

      if (!checked) {
        delete(prev[currentStep][settings.name][name]);
      }

      return {
        ...prev
      }
    });

    setErrors((prev) => {
      if (prev && prev.hasOwnProperty(settings.name)) {
        delete prev[settings.name];
      }

      return {
        ...prev,
      };
    });
  }

  const hasErrors = (errors && (errors.hasOwnProperty(settings.name) && errors[settings.name].length) || (required && errors && errors.hasOwnProperty('generalError')));

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(settings.name) && errors[settings.name].length) {
      renderable.push(
        <div key={`errorField-${settings.name}`} className="form-group__feedback">{errors[settings.name]}</div>
      );
    }
    return renderable;
  };

  const renderCheckboxes = () => {
    const renderable = [];
    checkboxes.forEach((checkbox, id) => {
      const {name, label} = checkbox;

      let checked = false;
      if (state && state[settings.name] && state[settings.name].hasOwnProperty(name)) {
        checked = state[settings.name][name];
      }

      renderable.push(
        <div className={`form-group__input ${hasErrors ? 'form-group--error' : ''} ${divClasses}`} key={`checkboxes-${name}-${id}`}>
          <label htmlFor={`checkbox-${name}-${id}`} className="selection-control selection-control--checkbox">
            <span className="selection-control__input">
              <input
                id={`checkbox-${name}-${id}`}
                name={name}
                type="checkbox"
                value={true}
                checked={checked}
                {...rest}
                onChange={handleChange}
                required={required}
              />
              <span className="icon selection-control__control" aria-hidden="true">
                <svg viewBox="0 0 16 16">
                  <polyline points="2.1,8.5 6.2,12.4 13.9,5.1"/>
                </svg>
              </span>
            </span>
            <span className="selection-control__label">
              {intl.formatMessage({id: label})}
            </span>
          </label>
        </div>
      );
    })

    return renderable;
  };

  return (
    <fieldset className={`form-group ${hasErrors ? 'form-group--error' : ''} form-group--selection-control`}>
      {renderTitle()}
      {renderCheckboxes()}
      {renderErrors()}
    </fieldset>
  );

}

export default injectIntl(MultipleCheckboxes);
