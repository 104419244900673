import React, {useContext} from 'react';
import RadioButtons from "@FormElements/RadioButtons";
import FormContext from "formContext";
import SelectField from "@FormElements/SelectField";

function JobTypeSalaryField ({intl, settings, ...rest}) {

  const {state} = settings;
  const {setFormData, currentStep} = useContext(FormContext);
  let salaryRateOptions = [];
  try {
    salaryRateOptions = require(`../../../../i18n/${process.env.REACT_APP_COUNTRY}/salaryList/en.json`);
  }
  catch (e) {}

  const SalaryRadioButtons = () => {

    if (!state || !state.job_type || state.job_type === 'permanent') {
      return null;
    }

    const handleChange = (event) => {
      if (state && state.salary_rate_amount) {
        setFormData(prev => {
          if (!prev[currentStep]) {
            prev[currentStep] = {};
          }
          prev[currentStep]['salary_rate_amount'] = '';
          return {...prev};
        });
      }
      rest.onChange(event);
    }

    return (
      <>
        <RadioButtons
          settings={{...settings, ...settings.salary_rate_type}}
          {...rest}
          onChange={handleChange}
        />
      </>
    );
  }

  const SalarySelectField = () => {

    if (!state || !state.job_type) {
      return null;
    }

    if (state.job_type !== 'permanent' && !state.salary_rate_type) {
      return null;
    }

    const rateType = state.job_type === 'permanent' ? 'yearly' : state.salary_rate_type;

    settings.salary_rate_amount.options = salaryRateOptions[rateType];

    return (
      <SelectField
        settings={{...settings, ...settings.salary_rate_amount}}
        {...rest}
      />
    )
  }

  const handleJobTypeChange = (event) => {
    if (state) {
      if (event.target && event.target.value === 'permanent') {
        setFormData(prev => {
          if (!prev[currentStep]) {
            prev[currentStep] = {};
          }
          prev[currentStep]['salary_rate_type'] = 'yearly'
          return {...prev};
        });
      }
      else if (!state.job_type || state.job_type === "permanent") {
        setFormData(prev => {
          if (!prev[currentStep]) {
            prev[currentStep] = {};
          }
          if (state.salary_rate_amount) {
            prev[currentStep]['salary_rate_amount'] = '';
          }
          if (state.salary_rate_type) {
            prev[currentStep]['salary_rate_type'] = ''
          }
          return {...prev};
        });
      }
    }
    return rest.onChange(event);
  }

  return (
    <>
      <SelectField
        settings={settings}
        {...rest}
        onChange={handleJobTypeChange}
      />
      <SalaryRadioButtons />
      <SalarySelectField />
    </>
  )
}
export default JobTypeSalaryField;