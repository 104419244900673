import React from 'react';
import {injectIntl} from "react-intl";

function RadioButtons({intl, settings, ...rest}) {
  const {label, state, required = true, options, errors, divClasses = ''} = settings || {};
  let {name} = settings || {};
  if (rest.hasOwnProperty('name')) {
    name = rest.name;
  }

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError')));

  const renderOptions = () => {
    const optionsRenderable = [];
    options.forEach((option, index) => {
      const additionalProps = {};
      if (state && state[name] && state[name] === option.value) {
        additionalProps.defaultChecked = 'checked';
      }
      else {
        additionalProps.defaultChecked = false;
      }
      optionsRenderable.push(
        <div className="form-group__input" key={`radiobuttons-${name}-${index}`}>
          <label htmlFor={`${name}-${index}`} className="selection-control selection-control--radio-button">
            <span className="selection-control__input">
              <input id={`${name}-${index}`} name={name} type="radio" value={option.value} {...additionalProps} {...rest}/>
              <span className="icon selection-control__control" aria-hidden="true" />
            </span>
            <span className="selection-control__label">{intl.formatMessage({id: option.label})}</span>
          </label>
        </div>
      );
    });
    return optionsRenderable;
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  return (
    <fieldset className={`form-group form-group--selection-control ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
      <legend className="form-group__label">
        {intl.formatMessage({id: label})}
        {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
      </legend>
      {renderOptions()}
      {renderErrors()}
    </fieldset>
  );
}

export default injectIntl(RadioButtons);
