import { useState, useEffect } from "react";
import axios from "axios";
import GetRouteByName from 'getRouteByName';
import Logger from '@ffw/logger-lib';

/**
 * React hook to acquire statuses for job applications by user id.
 * @param {object} user The user object.
 * @returns Array of application statuses.
 */
const useApplicationStatuses = (user) => {
  const [applicationStatuses, setApplicationStatuses] = useState([]);
  const myApplicationsUrl = GetRouteByName('my-applications') ? GetRouteByName('my-applications').url : '/my-randstad/my-applications/';
  
  useEffect(() => {
    if (!user) return;

    const idParam = process.env.REACT_APP_CARDS_USE_ATS_CANDIDATEID === 'true' ?
      `candidateId=${user.atsCandidateId}` : `userId=${user.userId}`;
    axios.get(`${process.env.REACT_APP_API_PREFIX}/application-statuses-list?${idParam}`)
      .then(response => {
        if (response.data && response.data.applications) {
          setApplicationStatuses(response.data.applications);
        }
      }).catch((error) => {
      Logger.error(error, 'Calling application-statuses-list');
    });
  }, [])

  return applicationStatuses;
};

export default useApplicationStatuses;
