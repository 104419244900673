import React from 'react'
import { injectIntl } from "react-intl";

const ShowMore = ({ onClick, jobsList, totalJobs, offsetPerPage, intl }) => {
  if (jobsList.length < 1 || totalJobs.length < 1) return null;
  if (jobsList.length === totalJobs.length) return null;

  const indicatorAmount = (100 * jobsList.length) / totalJobs.length;

  const onPressShowMore = (e) => {
    if (e.key === 'Enter') {
      onClick(e)
    }
  }  

  return (
    <div className="show-more">
      <div className="show-more__indicator">
        <div className="indicator-percentage">
          <div className="indicator-percentage__background">
            <div className="indicator-percentage__amount" style={{ width: `${indicatorAmount}%` }} />
          </div>
        </div>
      </div>
      <div className="section-separator section-separator--border">
      <span>
        {intl.formatMessage({id: 'Job.Jobs.Seen'}, {i: jobsList.length, j: totalJobs.length})}
      </span>
      </div>
      <a className="button button--m" onClick={onClick} onKeyDown={onPressShowMore} tabIndex="0">
        {intl.formatMessage({id: 'Show.N.More'}, {n: offsetPerPage})}
      </a>
    </div>
  );
};

export default injectIntl(ShowMore)