import React from 'react';
import AutoSuggestField from "@FormElements/AutoSuggestField";
import Tags from "@Components/Tags";

function AutoSuggestWithTags({settings, tagsArray, handleRemove, ...rest}) {
  const {tags, ...restSettings} = settings || {};
  return (
    <>
      <AutoSuggestField
        settings={restSettings}
        {...rest}
      />
      <Tags tags={tagsArray} handleRemove={handleRemove}/>
    </>
  );
}

export default AutoSuggestWithTags;
