import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import SelectField from "@FormElements/SelectField";

function SpecialismSelectFields({intl, settings, ...rest}) {
  const {state, name} = settings || {};
  const {onChange} = rest;
  const {additionalData: {specialisms}, currentStep, setFormData} = useContext(FormContext);

  const SpecialismField = () => {
    if (!specialisms || !Object.keys(specialisms).length) {
      return null;
    }
    const specialismOptions = [];
    specialismOptions.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
    specialisms.forEach((specialism) => {
      specialismOptions.push({
        value: specialism.name,
        label: specialism.name,
      });
    });

    settings.options = specialismOptions;
    return (
      <SelectField
        settings={settings}
        {...rest}
        onChange={handleSpecialismChange}
      />
    )
  };

  const handleSpecialismChange = event => {
    if (settings && settings.subspecialism && settings.subspecialism.name) {
      setFormData(prev => {
        if (!prev[currentStep]) {
          prev[currentStep] = {};
        }
        const subSpecialismName = settings.subspecialism.name;
        prev[currentStep][subSpecialismName] = '';
        prev[currentStep]['specialism_id'] = '';
        prev[currentStep]['sub_specialism_id'] = '';
        return {
          ...prev
        }
      });
    }
    onChange(event);
  };

  const SubSpecialismField = () => {

    if (!specialisms || !Object.keys(specialisms).length) {
      return null;
    }

    let selectedSpecialism;
    if (state && state.hasOwnProperty(name) && state[name] && state[name].length) {
      selectedSpecialism = state[name];
    }

    const subSpecialismOptions = [];
    const currentSpec = specialisms.find(spec => spec.name === selectedSpecialism);
    if (selectedSpecialism && specialisms.length) {
      if (currentSpec && currentSpec.nested.length) {
        subSpecialismOptions.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
        currentSpec.nested.forEach(subSpec => {
          subSpecialismOptions.push({ value: subSpec.name, label: subSpec.name });
        });
      }
    }
    if (!subSpecialismOptions.length) {
      return '';
    }
    settings.subspecialism.options = subSpecialismOptions;
    return (
      <SelectField
        settings={{...settings, ...settings.subspecialism}}
        {...rest}
      />
    )
  };

  return (
    <>
      <SpecialismField />
      <SubSpecialismField />
    </>
  )
}

export default injectIntl(SpecialismSelectFields);
