import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";

function ResetPasswordEmailText({intl, settings}) {
  const {text, divClasses = "form-group", pClasses = ""} = settings || {};
  const {formData} = useContext(FormContext);

  const renderHtml = () => {
    if (formData && formData[0].email) {
      return (
        <div className={divClasses}>
          <p className={pClasses} dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: text}, {email: formData[0].email})}} />
        </div>
      )
    }
    else return '';
  }

  return renderHtml();
}

export default injectIntl(ResetPasswordEmailText);
