import React from "react";
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon";

function SkillItem({intl, name, level, index, handleEdit, handleDelete, isMobile = false}) {
  // @TODO: Remove hardcoded 4 and use a dynamic counter.
  const rating = level * (100 / 4);
  const renderRatingBlock = (rating) => {
    return (

      <div className="rating__wrapper">
        <div className="rating-readonly rating--m">
          <div className="rating-readonly__description"> {intl.formatMessage({id: `SkillBlock.RatingType.${level}`})}</div>
          <div className="rating-readonly__icon--wrapper">
            <div className="icon--filled" style={{width: `${rating}%`}}>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (isMobile) {
    return (
      <li key={index} className="list-item divider">
        <div className="list-item__info">
          <div className="list-item__title list-item__title--split">
            <h3 className="list-item__header">{name}</h3>
            <div className="list-item__controls">
              <button className="button--clean" onClick={e => handleEdit(index)}>
                <Icon type="edit" className="icon icon--inline icon--hover"/>
                <span className="hidden--visually"/>
              </button>
              <button className="button--clean" onClick={e => handleDelete(index)}>
                <Icon type="trash" className="icon icon--inline icon--hover"/>
                <span className="hidden--visually"/>
              </button>
            </div>
          </div>
          {renderRatingBlock(rating)}
        </div>
      </li>
    );
  }

  return (
    <div className="my-environment-item__list my-environment-item__list-removable pb-s divider closable"
         {...{[`data-rs-notification-${index}`]: ''}} >
      <div className="my-environment-item__list-title" data-rs-closable-fadeout="">{name}</div>
      <div className="my-environment-item__list-content mt-xs" data-rs-closable-fadeout="">
        {renderRatingBlock(rating)}
      </div>
      <div className="my-environment__controls my-environment__controls--list" data-rs-closable-fadeout="">
        <button className="button--clean" onClick={e => handleEdit(index)}>
          <Icon type="edit" className="icon icon--inline"/>
          <span className="hidden--visually"/>
        </button>
        <button className="button--clean" data-rs-closable={`data-rs-notification-${index}`} onClick={e => handleDelete(index)}>
          <Icon type="trash" className="icon icon--inline"/>
          <span className="hidden--visually"/>
        </button>
      </div>
    </div>
  );
}

export default injectIntl(SkillItem);
