import React, {useContext} from 'react';
import AutoSuggestWithTags from "@FormElements/AutoSuggestWithTags";
import FormContext from "formContext";

function IndustryPreferences({settings, ...rest}) {
  const {formData, currentStep, setFormData, additionalData} = useContext(FormContext);
  const {storeTagsIn} = settings || {};
  const {masterLists} = additionalData || {};
  const {IndustrySectors} = masterLists || {};
  let options = [];
  let tagsArray = [];
  if (formData && formData[currentStep] && formData[currentStep][storeTagsIn]) {
    tagsArray = formData[currentStep][storeTagsIn];
  }

  if (IndustrySectors) {
    IndustrySectors.forEach(industry => {
      if (industry.hasOwnProperty('ListValue') && industry.hasOwnProperty('Code')) {
        let shouldSkip = false;
        if (formData && formData[currentStep] && formData[currentStep][storeTagsIn]) {
          const match = formData[currentStep][storeTagsIn].find(el => el === industry.ListValue);
          if (match) {
            shouldSkip = true;
          }
        }

        if (!shouldSkip && formData && formData[currentStep] && formData[currentStep][settings.name] && formData[currentStep][settings.name].length) {
          if (!industry.ListValue.toLowerCase().includes(formData[currentStep][settings.name].toLowerCase())) {
            shouldSkip = true;
          }
        }

        if (!shouldSkip) {
          options.push(industry.ListValue);
        }
      }
    });
  }

  if (!options) {
    return '';
  }
  settings['options'] = options.sort();
  rest.onChange = e => {
    const {value} = e.target;
    const match = options.find(el => el === value);
    let alreadyExisting = false;
    if (formData && formData[currentStep] && formData[currentStep][storeTagsIn]) {
      alreadyExisting = formData[currentStep][storeTagsIn].find(el => el === value);
    }
    const industryCode = getSectorCodeByName(match);
    if (match && industryCode && !alreadyExisting) {
      setFormData(prev => {
        if (!prev[currentStep][storeTagsIn]) {
          prev[currentStep][storeTagsIn] = [];
        }
        if (!prev[currentStep]['industry_sector_codes']) {
          prev[currentStep]['industry_sector_codes'] = [];
        }
        prev[currentStep][storeTagsIn].push(match);
        prev[currentStep]['industry_sector_codes'].push(industryCode);
        delete prev[currentStep][settings.name];
        return {
          ...prev
        }
      });
    }
    else {
      setFormData(prev => {
        if (!prev[currentStep]) {
          prev[currentStep] = {};
        }
        prev[currentStep][settings.name] = value;
        return {
          ...prev
        }
      });
    }
  };

  const getSectorCodeByName = name => {
    let result;
    IndustrySectors.forEach(industry => {
      if (industry.hasOwnProperty('ListValue') && industry.hasOwnProperty('Code')) {
        if (industry.ListValue === name) {
          result = industry.Code;
        }
      }
    });
    return result;
  }

  const handleRemove = key => {
    setFormData(prev => {
      prev[currentStep][storeTagsIn].splice(key, 1);
      prev[currentStep]['industry_sector_codes'].splice(key, 1);
      if (!prev[currentStep][storeTagsIn]) {
        delete prev[currentStep];
      }
      return {
        ...prev
      }
    });
  };

  settings['readonly'] = tagsArray.length >= 3;

  if (settings.errors && settings.errors.hasOwnProperty(storeTagsIn)) {
    settings.errors[settings.name] = settings.errors[storeTagsIn];
  }

  return (
    <AutoSuggestWithTags
      tagsArray={tagsArray}
      settings={settings}
      handleRemove={handleRemove}
      {...rest}
    />
  );
}

export default IndustryPreferences;
