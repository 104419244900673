import React from "react";

const HeaderIllustration = ({ title, text, image }) => {
  if (!title && !text) return;

  return (
    <div className={`header bg-variant-brand-tertiary`}>
      <div className="header__wrapper wrapper">
        <div className="header__content header__content--l content-block">
          <h1 className="content-block__title">
            {title}
          </h1>
          <div className="content-block__description" dangerouslySetInnerHTML={{ __html: text}}>
          </div>
        </div>
        <div className="header__media media-block">
          <img src={image} alt=""/>
        </div>
      </div>
    </div>
  )
}

export default HeaderIllustration;