import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import Checkbox from "@FormElements/Checkbox";
import FormContext from "formContext";
import axios from "axios";

const AutomatedSystemParagraph = ({intl}) => {
  const {additionalData, showToast, setToastMessage, triggerRerender} = useContext(FormContext);

  const handleChange = event => {
    event.preventDefault();
    const value = event.target.checked ? 'yes' : 'no';

    axios.put(`${process.env.REACT_APP_API_PREFIX}/user/optional-consent`, {accept_ai_training: value})
      .then(() => {
        const message = intl.formatMessage({id: 'Profile.AccountUpdated.Toast.SuccessMessage'});
        setToastMessage(message);
        showToast();
        triggerRerender();
      })
      .catch(() => {});
  };

  const checked = !!(additionalData && additionalData['user'] && additionalData['user']['accept_ai_training'] === 'yes');

  return (
    <div className="my-environment-item__content mt-xs l:mt-s">
      <p dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: 'AccountSettings.AutomatedSystems.Text'})}} />
      <div className="form-group form-group--selection-control">
        <Checkbox
          settings={{
            name: 'optional-consent',
            label: 'AccountSettings.AutomatedSystems.Checkbox.Label',
            state: {'optional-consent': checked},
            yesValue: true
          }}
          onChange={handleChange}/>
      </div>
    </div>
  );
};

export default injectIntl(AutomatedSystemParagraph);
