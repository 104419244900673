import React from "react";

const SplitViewPhoto = ({title, text, imageTablet, imageDesktop, background}) => {
  return (
    <div className={`banner split-view--photo ${background}`}>
      <div className="banner__wrapper wrapper">
        <div className="banner__media media-block media-block--center media-block--half-width">
          <picture>
            <source
              srcSet={`${imageDesktop} 1x`}
              media="(min-width: 941px)" type="image/jpeg" />
            <source
              srcSet={`${imageTablet} 1x`}
              media="(min-width: 501px)" type="image/jpeg" />
            <source
              srcSet={`${imageTablet} 1x`}
              media="(min-width: 0px)" type="image/jpeg" />
            <img
              src={imageDesktop}
              alt="" />

          </picture>
        </div>
        <div className="banner__content content-block">
          <h2 className="content-block__title">{title}</h2>
          <div className="content-block__description body-copy" dangerouslySetInnerHTML={{ __html: text}} />
        </div>
      </div>
    </div>
  )
}

export default SplitViewPhoto;