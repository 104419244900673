import React from 'react';
import Icon from "@Components/Icon";
import { injectIntl } from "react-intl";

const CardBackside = ({ intl, text, label, url }) => {
  const description = text ? text.replace(/<[^>]*>?/gm, "") : "";

  return (
    <div className="cards__backside-card">
      <div className="cards__backside-description" dangerouslySetInnerHTML={{__html: description}} />
      <div className="cards__backside-footer">
        <a href={url} className="cards__backside-footer--horizontal cards__backside-footer--job-link" aria-label="" tabIndex="-1">
          <Icon type='eye' className='icon icon--inline' />
          {label}
        </a>
        <div data-rs-card-hide-backside="" className="cards__backside-footer__button cards__backside-footer--close-backside" role="button" aria-label={intl.formatMessage({ id: "Close" })} tabIndex="-1">
          <Icon type="close" className="icon icon--inline" />
        </div>
      </div>
    </div>
  );
};

export default injectIntl(CardBackside);
