import React, { useContext } from "react";
import { injectIntl, createIntl, createIntlCache } from "react-intl";
import Icon from "@Components/Icon";
import Link from "@Components/Link";
import FavIcon from "@Components/FavIcon";
import CardMeta from "@Components/CardMeta";
import CardBackside from "@Components/CardBackside";
import useDateHelper from "dateHelper";
import FormContext from "formContext";
import GetRouteByName from "getRouteByName";
import { pushApplicationDatalayerEvent } from "dataLayerHelper";
import useOrbitComponent from "useOrbitComponent";

function JobCard({ job, onFavIconClick, intl, bg = "", application = {} }) {
  const useLowerCase = process.env.REACT_APP_USE_LOWER_CASE === "true";
  const useShowDate = process.env.REACT_APP_CARD_USE_SHOW_DATE === "true";
  const useTimestamp = process.env.REACT_APP_CARD_USE_TIMESTAMP === "true";
  const dateFormat = process.env.REACT_APP_DATE_FORMAT;
  const trackAndTrace = process.env.REACT_APP_TRACK_AND_TRACE === 'true';
  const dateFormatType = process.env.REACT_APP_DATE_FORMAT_TYPE;
  const dateFormatLocale = process.env.REACT_APP_DATE_FORMAT_LOCALE;

  const {translations} = useContext(FormContext);
  const { customDateFormat, dateHasExpired } = useDateHelper();
  const {
    jobId,
    title,
    url,
    details,
    jobPostDate,
    showDate,
    description,
    jobExpireDate,
    applicationCardType,
    applicationExpired,
    created_timestamp,
  } = job || {};
  const [orbitRef] = useOrbitComponent('card');

  const isOpenApplication = applicationCardType === 'open';
  const hideCardTitle = isOpenApplication && applicationExpired && trackAndTrace
  const showCardBackSide = !(application.hasId || isOpenApplication);

  // Use applicationExpired of the job item, when handling application cards.
  // It is set when using the Application component.
  const expired = applicationExpired !== undefined ? applicationExpired : dateHasExpired(jobExpireDate);

  /* Application tracking conditions (Track & Trace). */
  let jobCardUrl = url;
  // Change the card url if there's an application id.
  if (application.hasId  && application.status) {
    const trackingHistoryRoute = GetRouteByName('tracking-history');
    if (trackingHistoryRoute) {
      const trackingHistoryUrl = GetRouteByName('tracking-history').url.replace(':applicationId/', '');
      jobCardUrl = trackingHistoryUrl + job.details.applicationId + '/';
    }
  }

  /* Job card language conditions. */
  let jobLang = process.env.REACT_APP_DEFAULT_LANGUAGE;
  if (url) {
    jobLang = url[0] === '/' && url[3] === '/' ? url.substr(1, 2) : jobLang;
  }
  if (jobLang) {
    const cache = createIntlCache();
    intl = createIntl({
      locale: jobLang,
      messages: translations[jobLang]
    }, cache);
  }

  const postDate = jobPostDate ? jobPostDate : created_timestamp;
  let dateFormatted = customDateFormat(useTimestamp ? created_timestamp : postDate, dateFormat, intl);
  let dateTypeText = useShowDate
    ? intl.formatMessage({ id: "JobCard.AppliedOn" })
    : intl.formatMessage({ id: "JobCard.PublishedOn" });

  // Handle case if there is multiple cards with different dates.
  if (useShowDate && (showDate && job.hasOwnProperty(showDate) && job[showDate])) {
    dateFormatted = customDateFormat(job[showDate], dateFormat, intl);
    dateTypeText = intl.formatMessage({id: 'JobCard.PublishedOn'});
  }

  /* Custom date format for multilingual OpCos. */
  if (dateFormatType && dateFormatLocale) {
    dateFormatted = intl && intl.locale === dateFormatLocale ?
    customDateFormat(jobPostDate, dateFormatType, intl) :
    customDateFormat(jobPostDate, dateFormat, intl).toLowerCase();
  }

  /**
   * @param {boolean} reactLink Whether we use <Link> OR <a> (true/false)
   * @returns The content for the job card's title.
   */
   const JobCardTitleContent = ({reactLink, open}) => {
    const InnerContent = () => {
      if (open) {
        return (
          <h3 className="cards__title">
            <span className="cards__link" tabIndex="0">
              {intl.formatMessage({ id: "OpenApplicationCard.Title" })}
              <span className="make-entire-card-clickable" />
            </span>
          </h3>
        )
      }
      return (
        <h3 className="cards__title">
          {title ? (useLowerCase ? title.toLowerCase() : title) : ''}
          <span className="make-entire-card-clickable" />
        </h3>
      );
    }

    if (open) {
      return <InnerContent />
    }

    return (
      <>
      {!reactLink ? (
        <a href={jobCardUrl} className="cards__link" tabIndex="0">
          <InnerContent />
        </a>
      ) : (
        <Link to={jobCardUrl} className="cards__link" tabIndex="0">
          <InnerContent />
        </Link>
      )}
      </>
    )
  }

  let disabledClass = expired ? "cards__item--disabled" : "";
  if (trackAndTrace) {
    disabledClass = expired && !application.hasId ? "cards__item--disabled" : "";
  }

  return (
    <li
      className={`cards__item ${disabledClass} ${bg}`}
      ref={orbitRef}
      data-rs-card=""
      data-rs-carousel-card=""
      onMouseDown={() => trackAndTrace ? pushApplicationDatalayerEvent(expired, application) : undefined}
    >
      <div className="cards__header">
        {!hideCardTitle && <JobCardTitleContent open={isOpenApplication} reactLink={application.hasId && application.status} />}
        { !applicationCardType && onFavIconClick && <FavIcon active onClick={() => onFavIconClick(jobId)} /> }
      </div>

      <CardMeta intl={intl} details={isOpenApplication ? job : details} />

      <div className={`cards__footer ${application.hasId ? 'items-end' : ''}`}>
        {application.hasId && application.status ? (
        <span className={`badge ${application.rejected ? 'badge--negative' : 'badge--secondary'} badge--l inline-flex items-center rounded-xxl mr-s`}>
          <span className="badge__text">{application.rejected ? intl.formatMessage({id: 'Applications.Rejected'}) : application.status}</span>
        </span>
        ) : (
          <div className="cards__time-info">
            <span className="cards__date text--alternative">
              {dateTypeText} {dateFormatted}
            </span>
          </div>
        )}
        {showCardBackSide && (
          <div className="cards__info-wrapper" tabIndex="0" data-rs-card-show-backside="">
            <span className="cards__info-button text--alternative">
              <Icon type="info" className="icon icon--inline" />
            </span>
          </div>
        )}
      </div>

      {showCardBackSide && (
        <CardBackside
          text={description}
          label={intl.formatMessage({id: 'JobCard.ShowDetails'})}
          url={url} />
      )}
    </li>
  );
}

export default injectIntl(JobCard)
