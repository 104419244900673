import React from 'react';
import GetClientRoutes from 'GetClientRoutes';
import {useIntl} from "react-intl";

function GetRouteByName(name) {
  let {locale} = useIntl();
  return GetClientRoutes()[locale][name];
}

function getRouteByNameAndLanguage(name, language) {
  return GetClientRoutes()[language] ? GetClientRoutes()[language][name] : undefined;
}

export {getRouteByNameAndLanguage};
export default GetRouteByName;
