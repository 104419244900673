import React from 'react';

// Load the routes for the specific country.
const routesCore = require(`../../config/core/client.routes.json`);
let routesOpCo = {};
try {
  routesOpCo = require(`../../config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
}
catch (e) {}
const routes = {
  ...routesCore,
  ...routesOpCo
};

function GetClientRoutes() {
  return routes;
}

export default GetClientRoutes;
