import React from "react";
import Icon from "@Components/Icon";

function ListItem({children, title, handleEdit, handleAddItem, label, divider = false}) {
  return (
    <div className={`my-environment-container pb-m l:pb-l ${divider ? "divider" : ""}`}>
      <div className="my-environment-header mb-s">
        <h2 className="my-environment-header__title--m mr-xxs">{title}</h2>
        {handleEdit && (
          <div className="my-environment__controls mt-xxs">
            <button data-label={label} className="button--clean" onClick={handleEdit}>
              <Icon type="edit" className="icon icon--inline"/>
              <span className="hidden--visually">{label}</span>
            </button>
          </div>
        )}
      </div>
      {handleAddItem && (
        <a href="#" className="button button--s mt-s" onClick={handleAddItem}>
          <Icon type="add" className="icon icon--inline fill-brand--blue"/>
          {label}
        </a>
      )}
      {children}
    </div>
  );
}

export default ListItem;
