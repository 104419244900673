import React, {useContext, useState} from 'react';
import {injectIntl} from "react-intl";
import Checkbox from "@FormElements/Checkbox";
import Button from "@FormElements/Button";
import FormContext from "formContext";

const DeleteAccountParagraph = ({intl}) => {
  const {setModalOpen, setFormName, buttonLoading, setButtonLoading} = useContext(FormContext);
  const [checked, setChecked] = useState(false);

  const handleChange = event => {
    const value = event.target.checked;
    setChecked(value);
  };

  const handleDeleteAccountButtonClick = () => {
    setFormName('account-delete');
    setModalOpen(true);
    setButtonLoading(true);
  };

  /**
   * FeedbackParagraph for displaying helpful text.
   * 
   * Some OpCos have feedback link, some does not. We figure this out based on REACT_APP_ACCOUNT_FEEDBACK_LINK env variable.
   * For PT - it has different structure (email, url) so we handle it separately.
   */
  const FeedbackParagraph = () => {
    let feedParams = {};
    if (process.env.REACT_APP_ACCOUNT_FEEDBACK_LINK === 'true') {
      const defaultFormUrl = process.env.REACT_APP_CONTACT_FORM_URL;
      const currentLanguageFormUrl = `REACT_APP_CONTACT_FORM_URL_${intl.locale.toUpperCase()}`;
      const feedbackLink = <a href={process.env[currentLanguageFormUrl] || defaultFormUrl}>{intl.formatMessage({id: 'AccountSettings.DeleteAccount.Link'})}</a>;
      feedParams = { feedback: feedbackLink };
    }

    if (process.env.REACT_APP_COUNTRY === 'pt') {
      feedParams = {
        email: <a href="mailto:privacidade@randstad.pt">privacidade@randstad.pt</a>,
        url: <a href="https://www.randstad.pt/politica-de-privacidade/">https://www.randstad.pt/politica-de-privacidade/</a>
      }
    }

    return intl.formatMessage({id: 'AccountSettings.DeleteAccount.Text'}, {...feedParams})
  }

  return (
    <>
      <div className="my-environment-item__content mt-xs l:mt-s">
        <p>
          <FeedbackParagraph />
        </p>
        <div className="form-group form-group--selection-control">
          <Checkbox
            settings={{
              name: 'account-delete',
              label: 'AccountSettings.DeleteAccount.Checkbox.Label',
              state: {'account-delete': checked}
            }}
            onChange={handleChange}/>
          <Button 
            onClick={handleDeleteAccountButtonClick} 
            className="button button--m account-delete" 
            settings={{
              text: 'AccountSettings.DeleteAccount.Button.Text',
              disabled: !checked,
              withWrapperDiv: false,
              loading : buttonLoading
            }} />
        </div>
      </div>
    </>
  );
};

export default injectIntl(DeleteAccountParagraph);
