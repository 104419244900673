import React from 'react';
import {injectIntl} from "react-intl";
import Icon from '@Components/Icon';

function NoticeInPage({intl, settings, ...rest}) {
  const {type, text, message, additionalClasses} = settings || {};
  let classes, iconName;

  switch (type) {
    case 'positive':
      classes = 'notice-in-page notice-in-page--positive';
      iconName = 'check';
      break
    case 'informative':
      classes = 'notice-in-page notice-in-page--informative';
      iconName = 'info';
      break
    case 'negative':
      classes = 'notice-in-page notice-in-page--negative';
      iconName = 'warning';
      break
    case 'warning':
      classes = 'notice-in-page notice-in-page--warning';
      iconName = 'warning';
      break
    default:
      classes = 'notice-in-page notice-in-page--informative';
      iconName = 'info';
      break
  }

  return (
    <div className={`${classes} ${additionalClasses}`} {...rest}>
      <Icon type={iconName} className="icon icon--inline"/>
      <span className='notice-in-page__body-copy body-copy' dangerouslySetInnerHTML={{ __html: !!message ? message : intl.formatMessage({id: text})}}/>
    </div>
  )
}

export default injectIntl(NoticeInPage);
