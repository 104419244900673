import React, {useContext, useState} from 'react';
import AutoSuggestField from "@FormElements/AutoSuggestField";
import FormContext from "formContext";
import axios from "axios";
import Logger from '@ffw/logger-lib';

function WorkplaceAutosuggest({settings, ...rest}) {
  const {additionalData, setAdditionalData, currentStep, setFormData} = useContext(FormContext);
  const [data, setData] = useState([]);

  rest.onChange = e => {
    const {value} = e.target;
    if (value) {
      axios.get(`/api/search/masked-location-autocomplete?text=${value}`)
        .then(result => {
          const newOptions = [];
          if (result.data.items.length) {
            result.data.items.forEach(item => {
              if (item.hasOwnProperty('displayValue')) {
                if (Array.isArray(item.displayValue)) {
                  newOptions.push(item.displayValue[0])
                }
                else {
                  newOptions.push(item.displayValue)
                }
              }
            });
            setData(result.data.items);
          }
          setAdditionalData(prev => {
            return {
              ...prev,
              workplaces: newOptions
            }
          });
        })
        .catch(error => {
          Logger.error(`Error getting workplace suggested values: ${error.message}`, "WorkplaceAutosuggest");
        });
    }
    setFormData(prev => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }
      prev[currentStep][settings.name] = value;
      // Set workplaceNearbyPostalCode if we have a match.
      let workplaceNearbyPostalCode = '';
      if (data && value) {
        data.forEach(option => {
          if (option.hasOwnProperty('displayValue') && option.hasOwnProperty('postalCode')) {

            // If the search is performed directly by ZIP CODE the displayValue is returned in the form of an array
            // with 1 element. We are just getting that element here, so the check for the postal code is correctly checked.
            let displayValue = option.displayValue;
            if(Array.isArray(displayValue) && displayValue.length > 0) {
              displayValue = displayValue[0];
            }

            if (displayValue === value) {
              workplaceNearbyPostalCode = option.postalCode;
            }
          }
        })
      }
      prev[currentStep]['workplaceNearbyPostalCode'] = workplaceNearbyPostalCode;
      return {
        ...prev
      }
    });
  };
  settings['options'] = additionalData['workplaces'] || [];

  return (
    <AutoSuggestField
      settings={settings}
      {...rest}
    />
  );
}

export default WorkplaceAutosuggest;
