import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import SelectField from "@FormElements/SelectField";

function BullhornListDataSelectField({intl, settings, ...rest}) {

    const context = useContext(FormContext);
    const selectOptions = context.additionalData[settings.dataType] ? context.additionalData[settings.dataType] : [];

    const BullhornListDataField = () => {
        if (!selectOptions || !Object.keys(selectOptions).length) {
            return null;
        }
        const options = [];
        options.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
        Object.values(selectOptions).forEach((option) => {
            options.push({
                value: option.value,
                label: option.name,
            });
        });

        settings.options = options;
        return (
            <SelectField
                settings={settings}
                {...rest}
            />
        )
    };


    return (
        <>
            <BullhornListDataField />
        </>
    )
}

export default injectIntl(BullhornListDataSelectField);
