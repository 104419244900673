import React, {useContext, useEffect, useState} from "react";
import { injectIntl } from "react-intl";

function Default({ settings, intl }) {
    const totalSlides = 3;
    const [privacySettings, setPrivacySettings] = useState();

    useEffect(() => {
        setPrivacySettings(settings)
    }, [settings])

    const component_title = privacySettings ? privacySettings.component_title : '';
    const component_body = privacySettings ? privacySettings.component_body : '';
    const slide1_title = privacySettings ? privacySettings.first_slide_title : '';
    const slide1_body = privacySettings ? privacySettings.first_slide_body : '';
    const slide2_title = privacySettings ? privacySettings.second_slide_title : '';
    const slide2_body = privacySettings ? privacySettings.second_slide_body : '';
    const slide3_title = privacySettings ? privacySettings.third_slide_title : '';
    const slide3_body = privacySettings ? privacySettings.third_slide_body : '';

    return (
        <div
            className="block bg-brand--off-white blog-overview blog-overview--carousel blog-overview--carousel-on-s application-process">
            <div className="block__wrapper block__wrapper--stacked">
                <div className="block__header block__header--split block__header--l wrapper">
                    <h2 className="block__title">{component_title}</h2>
                    <div className="block__description">
                        <p>{component_body}</p>
                    </div>
                </div>
                <div className="block__content">
                    <div className="blog-overview__wrapper wrapper"
                         data-rs-carousel-wrapper="data-rs-carousel-wrapper">
                        <ul className="blog-overview__list" data-rs-carousel="application-process"
                            data-rs-carousel-button-id="job">
                            <li className="blog-overview__item">
                                <div className="banner" data-rs-carousel-image="data-rs-carousel-image">
                                    <div className="banner__wrapper bg-brand--yellow">
                                        <div className="banner__media media-block">
                                            <img
                                                src="/myrandstad-app/assets/image/application-process/arrow-inhouse.svg"
                                                alt="alt"/>
                                        </div>
                                        <div className="banner__content content-block">
                      <span className="badge badge--l bg-brand--dark-blue">
                        {intl.formatMessage({id: 'RequestConfirmation.ItemNumber'}, {currentSlide: 1, totalSlides})}
                      </span>
                                            <h3 className="content-block__title">{slide1_title}</h3>
                                            <p className="content-block__description">{slide1_body}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="blog-overview__item">
                                <div className="banner" data-rs-carousel-image="data-rs-carousel-image">
                                    <div className="banner__wrapper bg-brand--blue">
                                        <div className="banner__media media-block">
                                            <img
                                                src="/myrandstad-app/assets/image/application-process/magnifying-glass.svg"
                                                alt="alt"/>
                                        </div>
                                        <div className="banner__content content-block">
                      <span className="badge badge--l bg-brand--white">
                        {intl.formatMessage({id: 'RequestConfirmation.ItemNumber'}, {currentSlide: 2, totalSlides})}
                      </span>
                                            <h3 className="content-block__title">{slide2_title}</h3>
                                            <p className="content-block__description">{slide2_body}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li className="blog-overview__item">
                                <div className="banner" data-rs-carousel-image="data-rs-carousel-image">
                                    <div className="banner__wrapper bg-brand--dark-blue">
                                        <div className="banner__media media-block">
                                            <img src="/myrandstad-app/assets/image/application-process/envelope.svg"
                                                 alt="alt"/>
                                        </div>
                                        <div className="banner__content content-block">
                      <span className="badge badge--l bg-brand--white">
                        {intl.formatMessage({id: 'RequestConfirmation.ItemNumber'}, {currentSlide: 3, totalSlides})}
                      </span>
                                            <h3 className="content-block__title">{slide3_title}</h3>
                                            <p className="content-block__description">{slide3_body}</p>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default injectIntl(Default);