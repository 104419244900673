import React from "react";
import Icon from "@Components/Icon";

function Error({message}) {
  return (
    <div style={{
      background: '#fff2f2',
      border: '1px solid red',
      margin: '0 0 20px',
      padding: '15px 15px 15px 45px',
      position: 'relative',
      width: 'calc((38 / 43) * 100%)',
    }}>
      <Icon
        type='warning'
        style={{
          right: 'auto',
          left: '14px',
          top: '22px',
        }}
        svgProps={{
          style: {
            fill: 'red',
          }
        }}
      />
      <div dangerouslySetInnerHTML={{__html: message}}></div>
    </div>
  );
}

export default Error;
