import React, {useContext} from 'react';
import FormContext from "formContext";
import axios from 'axios';
import GetRouteByName from 'getRouteByName';
import { pushCandidateEvent } from "dataLayerHelper";

const useRegisterSubmitHandler = () => {
  const {formData, handleErrors, currentStep, setLoading} = useContext(FormContext);
  const registerConfirmationUrl = GetRouteByName('register-confirmation').url;

  const handleRegisterSubmit = () => {
    setLoading(true);

    // Fetch saved jobs.
    const favoriteJobs = JSON.parse(localStorage.getItem("favJobs"));
    if (favoriteJobs && formData[currentStep]) {
      formData[currentStep].savedJobs = favoriteJobs;
    }

    if (formData[currentStep] && captcha?.getToken('mrnd_captcha')) {
      formData[currentStep].captchaToken = captcha?.getToken('mrnd_captcha');
    }

    axios.post(`${process.env.REACT_APP_API_PREFIX}/signup`, formData[currentStep])
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          pushCandidateEvent("register");
          window.location = registerConfirmationUrl + '?userId=' + response.data.userId;
          setLoading(false);
          captcha?.reset('mrnd_captcha');
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
        captcha?.reset('mrnd_captcha');
        setLoading(false);
      });
  }

  return {
    handleRegisterSubmit
  }
};

export default useRegisterSubmitHandler;
