import React from 'react';
import {injectIntl} from "react-intl";
import SelectField from "@FormElements/SelectField";
import {useCountriesList} from "i18nHelper";

function CountrySelectField({intl, settings, ...rest}) {
  const {locale} = intl;
  settings.options = useCountriesList(locale, true) || [];

  return (
    <SelectField
      settings={settings}
      {...rest}
    />
  )

}

export default injectIntl(CountrySelectField);
