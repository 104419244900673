import React, {useContext, useState} from "react";
import {injectIntl} from "react-intl";
import useDateHelper from 'dateHelper';
import ExperienceItem from "@Components/ExperienceItem";
import FormContext from "formContext";
import Modal from "@Components/Modal";
import Button from "@FormElements/Button";
import axios from "axios";

function ExperienceItemList({intl, items, listType = 'workXp', setModalOpen}) {
  const {setFormData, setFormName, handleErrors, setErrors, triggerRerender, setEditedItemKey} = useContext(FormContext);
  const {customDateFormat} = useDateHelper();
  const [showAllItems, setShowAllItems] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [indexForDelete, setIndexForDelete] = useState(null);

  const isWorkXpListType = (type) => {
    return type === 'workXp';
  }
  const formName = isWorkXpListType(listType) ? 'user-work-xp' : 'user-qualifications';

  const handleEditWorkXpItem = (index) => {
    const item = items.items[index];
    const {key} = item;
    const itemProps = ['employer', 'position', 'ongoing', 'description', 'date_start', 'date_end'];
    let data = {};
    itemProps.forEach(key => item[key] !== undefined ? data[key] = item[key] : false);

    setEditedItemKey(key);
    setFormData([data]);
    setFormName(formName);
    setModalOpen(true);
  }

  const handleEditQualificationItem = (index) => {
    const item = items.items[index];
    const {key} = item;
    const itemProps = ['qualificationType', 'sub_type', 'ongoing', 'main_focus', 'date_start', 'date_end', 'degree', 'facility_name', 'title'];
    let data = {};
    itemProps.forEach(key => item[key] !== undefined ? data[key] = item[key] : false);

    setEditedItemKey(key);
    setFormData([data]);
    setFormName(formName);
    setModalOpen(true);
  }

  const handleDeleteItem = (index) => {
    setConfirmationModalOpen(true);
    setIndexForDelete(index);
  }

  const handleDeleteSubmit = () => {
    setLoading(true);

    const item = items.items[indexForDelete];
    const submitApiPath = isWorkXpListType(listType) ? 'work-xp' : 'qualifications';
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/user/${submitApiPath}/${item.key}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setConfirmationModalOpen(false);
          setLoading(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          triggerRerender();
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  }

  // Sort items by date.
  const sortItems = (items) => {
    return [...items].sort((a, b) => {
      return new Date(b.date_start) - new Date(a.date_start);
    });
  };

  const parseItem = (item) => {
    let parsedItem = {...item};
    let titleProp, metaProps;
    if (isWorkXpListType(listType)) {
      titleProp = 'position';
      metaProps = [
        {name: 'employer'}
      ];
    } else {
      titleProp = 'title';
      metaProps = [
        {name: 'qualificationType', 'translationId': 'SelectField.QualificationType.'},
        {name: 'sub_type', 'translationId': 'SelectField.QualificationSubType.'},
        {name: 'facility_name'},
        {name: 'degree'}
      ];
      parsedItem.description = parsedItem.main_focus;
    }
    parsedItem.title = item[titleProp];
    parsedItem.meta = {};

    metaProps.map(prop => {
      if (item[prop.name]) {
        parsedItem.meta[prop.name] = prop.translationId ?
          intl.formatMessage({id: `${prop.translationId}${item[prop.name]}`}) : item[prop.name];
      }
    })

    if (item.date_start) {
      parsedItem.meta.date = customDateFormat(item.date_start);
      if (item.ongoing === 'yes') {
        parsedItem.meta.date = `${parsedItem.meta.date} - ${intl.formatMessage({id: 'Utils.Ongoing'})}`;
      }
      else if (item.date_end) {
        parsedItem.meta.date = `${parsedItem.meta.date} - ${customDateFormat(item.date_end)}`;
      }
    }

    return parsedItem;
  };

  const renderItemMeta = (meta) => {
    const metaItems = [];
    Object.keys(meta).map((key, index) => {
      metaItems.push((
        <li className={`pl-25 ${index !== 0 ? 'mt-xxs': ''}`} key={index}>{meta[key]}</li>
      ));
    });

    return metaItems;
  }

  const renderItems = () => {
    const renderedItems = [];
    if (items && items.count > 0) {
      let itemsToShow = items.count;
      items.items = sortItems(items.items);

      if (items.count > 2 && !showAllItems) {
        itemsToShow = 2;
      }

      items.items.slice(0, itemsToShow).forEach((item, index) => {
        const parsedItem = parseItem(item);
        renderedItems.push(
          <ExperienceItem
            key={index}
            title={parsedItem.title}
            index={index}
            handleEditItem={isWorkXpListType(listType) ? handleEditWorkXpItem : handleEditQualificationItem}
            handleDeleteItem={handleDeleteItem}
            label={intl.formatMessage({id: 'Profile.PersonalDetails.ContactDetails.Modal.Title'})}
            divider="true"
          >
            <div className="my-environment-item__content mt-xs l:mt-s" data-rs-closable-fadeout="">
              <div className="my-environment-item__description body-copy">
                <ul>
                  {renderItemMeta(parsedItem.meta)}
                </ul>
              </div>
              {parsedItem.description &&
                <div className="my-environment-item__description mt-xs body-copy">
                  <span>{parsedItem.description}</span>
                </div>}
            </div>
          </ExperienceItem>
        );
      });
    }
    return renderedItems;
  };

  const onCloseModal = () => {
    setConfirmationModalOpen(false);
  }

  const modalFooter = () => {
    const modalButtonSettings = {
      medium: true,
      withWrapperDiv: false
    }

    return (
      <>
        <div className="button-group button-group--full-width hidden--from-l">
          <Button
            onClick={handleDeleteSubmit}
            settings={{
              ...modalButtonSettings,
              fullWidth: true,
              filled: true,
              loading,
              text: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.DeleteButtonText"
            }} />
          <Button
            onClick={onCloseModal}
            settings={{
              ...modalButtonSettings,
              fullWidth: true,
              plain: true,
              text: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.CancelButtonText"
            }} />
        </div>
        <div className="button-group--options button-group hidden--until-l">
          <Button
            onClick={handleDeleteSubmit}
            settings={{
              ...modalButtonSettings,
              filled: true,
              loading,
              text: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.DeleteButtonText"
            }} />
          <Button
            onClick={onCloseModal}
            settings={{
              ...modalButtonSettings,
              plain: true,
              text: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.CancelButtonText"
            }} />
        </div>
      </>
    )
  }

  const renderModal = () => {
    if (!confirmationModalOpen) {
      return '';
    }
    return (
      <Modal
        title={intl.formatMessage({id: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.Title"})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={false}
        modalOverflow={false}
      >
        <p>{intl.formatMessage({id: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.Description"})}</p>
      </Modal>
    );
  }

  return (
    <>
      {renderItems()}
      {renderModal()}
      {items.count > 2 ?
        <div className="view-more-button">
          <button onClick={() => {setShowAllItems(prevState => !prevState)}} className="button button--plain">
            {showAllItems ? intl.formatMessage({id: 'Utils.ShowLess'}) : intl.formatMessage({id: 'Utils.ShowAll'}, {n: items.count})}
          </button>
        </div> : null}

      {!items.count ?
        <div className="my-environment--empty-state text--alternative">
          <p>{isWorkXpListType(listType) ? intl.formatMessage({id: 'PersonalInformation.Experience.NoItems.Text'}) : intl.formatMessage({id: 'PersonalInformation.Qualifications.NoItems.Text'})}</p>
        </div> : null}
    </>
  )
}

export default injectIntl(ExperienceItemList);
