import React from 'react';
import StepItem from "@Components/StepItem";

function Step({ stepFlow, intl }) {
  const renderStepItems = () => {
    let stepItems = [];
    let passed = true;
    let lastStep = false;

    stepFlow.map((item, key) => {
      if (item.active) {
        passed = false;
      }

      /* Find last step and mark it. */
      if (key === stepFlow.length - 1) {
        lastStep = true;
        if (item.active) {
          passed = true;
        }
      }

      stepItems.push(
        <StepItem
          item={item}
          key={`step-item-${key}`}
          passed={passed}
          lastStep={lastStep}
          intl={intl}
        />
      );
    });

    return stepItems;
  }

  return (
    <div className="block">
      <div className="block__wrapper">
        <div className="grid grid-cols-6">
          <div
            className="timeline col-span-6 l:col-span-5 flex flex-col">
            { renderStepItems() }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step;