import React from 'react'
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon"

const FavIcon = ({ intl, active, onClick }) => (
  <button onClick={onClick} className={`icon__toggler icon--l ${active ? "icon__toggler--active" : ""}`} aria-label={intl.formatMessage({ id: "Favorite" })}>
    <Icon type="heart-30" className="icon icon--inline icon--l" tabIndex="0"/>
    <Icon type="heart-filled-30" className="icon icon--inline icon--l"/>
  </button>
);

export default injectIntl(FavIcon);
