import {useIntl} from "react-intl";
import Logger from '@ffw/logger-lib';

const useCountriesList = (locale, inSelectListOptionsFormat = false) => {
  const intl = useIntl();
  const emptyValueLabel = intl.formatMessage({id: 'SelectField.EmptyOption.Label'});
  if (!locale) {
    locale = intl.locale;
  }

  let countries = {};
  try {
    countries = require(`../../i18n/${process.env.REACT_APP_COUNTRY}/countries/${locale}.json`);
  } catch (e) {
    Logger.info(`Missing country list json for '${locale}' language of '${process.env.REACT_APP_COUNTRY}' OpCo`, "i18nHelper");
  }

  // Build options for the CountrySelectField component.
  if (inSelectListOptionsFormat) {
    const options = [];
    options.push({value: "", label: emptyValueLabel});
    Object.keys(countries.countries).forEach(code => {
      options.push({value: code, label: countries.countries[code]})
    })

    return options;
  }

  return countries;
}

const useLanguagesList = (locale) => {
  let languages = {};
  try {
    languages = require(`../../i18n/${process.env.REACT_APP_COUNTRY}/languages/${locale}.json`);
  } catch (e) {
    console.log(`Missing languages list json for '${locale}' language of '${process.env.REACT_APP_COUNTRY}' OpCo`);
  }

  return languages;
}

const useLanguageLevelsList = (locale) => {
  let languageLevels = [];
  try {
    languageLevels = require(`../../i18n/${process.env.REACT_APP_COUNTRY}/languageLevels/${locale}.json`);
  } catch (e) {
    console.log(`Missing specialisms list json for '${locale}' language of '${process.env.REACT_APP_COUNTRY}' OpCo`);
  }

  return languageLevels;
}

const useRegionsList = (locale) => {
  const intl = useIntl();
  if (!locale) {
    locale = intl.locale;
  }

  let regions = {};
  try {
    regions = require(`../../i18n/${process.env.REACT_APP_COUNTRY}/regions/${locale}.json`);
  } catch (e) {
    Logger.info(`Missing regions list json for '${locale}' language of '${process.env.REACT_APP_COUNTRY}' OpCo`, "i18nHelper");
  }

  return regions;
}

const useSpecialismsList = (locale) => {
  let specialisms = [];
  try {
    specialisms = require(`../../i18n/${process.env.REACT_APP_COUNTRY}/specialisms/${locale}.json`);
  } catch (e) {
    console.log(`Missing specialisms list json for '${locale}' language of '${process.env.REACT_APP_COUNTRY}' OpCo`);
  }

  return specialisms;
}

const getSpecialismByID = (specialismId, lang) => {
  if (!lang) {
    lang = process.env.REACT_APP_DEFAULT_LANGUAGE;
  }
  const currentSpecialisms = useSpecialismsList(lang);
  for (let curSpecialismId in currentSpecialisms) {
    if (curSpecialismId === specialismId) {
      return currentSpecialisms[curSpecialismId];
    }
  }
  return null;
}

const getSpecialismIdByName = (specialismName, lang) => {
  let specialismId = null;
  let currentSpecialisms;

  currentSpecialisms = useSpecialismsList(lang);

  const specialismIds = Object.keys(currentSpecialisms);
  specialismIds.forEach(item => {
    // Using include instead of comparing directly because some saved specialisms have old names.
    //  Example: "Logistică" is saved in the database but the new one is "Logistică, achiziții".
    if (currentSpecialisms[item].includes(specialismName)) {
      specialismId = item;
    }
  })
  return specialismId;
}


export {
  useCountriesList,
  useLanguageLevelsList,
  useLanguagesList,
  useRegionsList,
  useSpecialismsList,
  getSpecialismByID,
  getSpecialismIdByName
}
