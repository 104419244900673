import React, {useContext} from "react";
import {injectIntl} from "react-intl";
import Modal from "@Components/Modal";
import FormContext from "formContext";

function ConfirmationModal({intl, children, handleConfirmation, buttonFilled = true, footerButtonText, ...rest}) {
  const {setModalOpen, setFormData, setEditedItemKey} = useContext(FormContext);

  const onCloseModal = () => {
    setModalOpen(false);
    setEditedItemKey('');
    setFormData([]);
  };

  const modalFooter = () => (
    <>
      <button onClick={handleConfirmation} className={`button button--m ${buttonFilled ? 'button--filled' : ''} button--full-width`}>
        {footerButtonText}
      </button>
    </>
  );

  return (
    <Modal
      onClose={onCloseModal}
      footer={modalFooter()}
      {...rest}
    >
      {children}
    </Modal>
  );
}

export default injectIntl(ConfirmationModal);
