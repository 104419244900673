import React, {useEffect} from 'react';
import xss from "xss";
import {useLocation} from "react-router-dom";

const useDataLayer = (locale, staticContext, pageName = "account") => {
  const location = useLocation();
  // Handles dataLayer on server side.
  if (staticContext) {
    staticContext.dataLayer = generateDataLayer(locale, location, false, pageName);
  }

  useEffect(
    () => {
      // Handles dataLayer when we don't have a page refresh.
      if (window && window.__ORIGINAL_LOCATION__ && window.__ORIGINAL_LOCATION__.isFreshPage) {
        delete window.__ORIGINAL_LOCATION__.isFreshPage;
      }
      else {
        generateDataLayer(locale, location, true, pageName);
      }
    },
    [location]
  );
};

const initDataLayerObject = (pageType, locale) => {
  let env = process.env.ENVIRONMENT;
  switch (env) {
    case "development":
      env = 'DEV';
      break;
    case "test":
    case "tst2":
      env = 'QA';
      break;
    case "production":
    case "prod":
    case "prd2":
      env = 'PROD';
      break;
    default:
      env = 'DEV';
  }
  let breadcrumb = [];
  if (typeof window !== 'undefined') {
    // Store the current page type in sessionStorage and load the previous ones.
    breadcrumb = JSON.parse(sessionStorage.getItem('breadcrumb')) || [];
    sessionStorage.setItem('breadcrumb', JSON.stringify(breadcrumb));
  }
  return {
    environment: env.toUpperCase(),
    country: process.env.REACT_APP_COUNTRY.toUpperCase(),
    language: locale,
    type: pageType,
    breadcrumb: breadcrumb,
    originalLocation: "",
    search_results: {
      search_result_amount: 0,
      search_result_distance: 0,
      search_result_page: 0,
      search_result_zip_code: '',
      search_result_keyword: '',
    }
  };
};

const generateDataLayer = (locale, location, noPageRefresh = false, pageName = "account") => {
  const page = initDataLayerObject(pageName, locale);
  // Handles dataLayer when we don't have a page refresh.
  if (noPageRefresh) {
    if (window && window.__ORIGINAL_LOCATION__ && window.__ORIGINAL_LOCATION__.originalUrl) {
      page.originalLocation = xss(process.env.REACT_APP_BASE_URL + window.__ORIGINAL_LOCATION__.originalUrl);
    }
    // Push the dataLayer as new array item. This is used when we have no page
    // refresh.
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'pageview',
      virtualPage: location.pathname + location.search,
      page,
      user: typeof window !== 'undefined' && window.dataLayer[0] ? window.dataLayer[0].user : undefined
    });
  }
  return {
    page,
    user: typeof window !== 'undefined' && window.dataLayer[0] ? window.dataLayer[0].user : undefined
  };
};

const push = (data, key = false, subKey = false) => {
  if (key !== false && subKey !== false) {
    return `window.dataLayer = window.dataLayer || []
            window.dataLayer[${key}].${subKey} = data.${subKey};`;
  }
  return `window.dataLayer = window.dataLayer || [];
            let newData = ${JSON.stringify(data)}; window.dataLayer.push(newData);`;
};

// Build a generic event tracker object.
const buildGenericEvent = (category, action, label, eventType = 'eventTracker') => {
  return {
    'eventCategory': category,
    'eventAction': action,
    'eventLabel': label,
    'event': eventType,
  };
};

const pushCandidateEvent = (type) => {
  let label;
  switch (type) {
    case "register":
      label = "[H] Profile Created";
      break;

    case "login":
      label = "[H] Logged Into Profile";
      break;

    case "delete":
      label = "[H] Profile removed";
      break;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eventCategory: "[H] Candidate",
    eventAction: "[H] Soft Conversion",
    eventLabel: label,
    event: "eventTracker"
  });

  if (type === 'register') {
    window.dataLayer.push({event_params: null});
    window.dataLayer.push({
      event: 'interaction',
      event_params: {
        'event_name': 'account_created',
        'user_type': 'talent'
      }
    });
  }

  if (type === 'login') {
    window.dataLayer.push({event_params: null});
    window.dataLayer.push({
      'event': 'interaction',
      'event_params': {
        'event_name': 'login',
        'user_type': 'talent',
      },
    });
  }

  const allowedGA4EventOpCos = [
    'my',
    'pt',
    'au',
  ];

  if (allowedGA4EventOpCos.includes(process.env.REACT_APP_COUNTRY) && type === 'delete') {
    window.dataLayer.push({event_params: null});
    window.dataLayer.push({
      'event': 'interaction',
      'event_params' : {
        'event_name': 'account_removed'
      }
    });
  }
};

const pushJobAlertRemovedEvent = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eventCategory: "[H] Candidate",
    eventAction: "[H] Soft Conversion",
    eventLabel: "[H] JobAlert removed",
    event: "eventTracker"
  });


  const allowedGA4EventOpCos = [
    'pt',
    'au',
  ];

  if (allowedGA4EventOpCos.includes(process.env.REACT_APP_COUNTRY)) {
    window.dataLayer.push({event_params: null});
    window.dataLayer.push({
      'event': 'interaction',
      'event_params' : {
        'event_name': 'job_alert_removed'
      }
    });
  }
};

const pushFavJobRemovedFromListEvent = () => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: "eventTracker",
    eventCategory: "Merkliste",
    eventAction: "von Merkliste entfernen",
    eventLabel: window.location.pathname
  });
};

// Available actions are "Link" and "Login".
const pushItsmeDatalayerEvent = (action) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eventCategory: "[B]Authentication",
    eventAction: action,
    eventLabel: "itsme"
  });
};

const pushFavoriteDatalayerEvent = (label = '') => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eventCategory: 'jobs',
    eventAction: "unfavorite",
    eventLabel: label,
    event: 'eventTracker'
  });

  window.dataLayer.push({event_params: null});
  window.dataLayer.push({
    'event': 'interaction',
    'event_params' : {
      'event_name': 'job_save',
      'item_name': label,
      'action': 'remove'
    }
  });
}

const pushApplicationDatalayerEvent = (expired, application) => {
  // If the Job Card doesn't have an application ID (Track&Trace) - don't sent the dataLayer event.
  if (!application || !application.hasId) return;
  const past = expired || application.rejected;
  // Push datalayer event on candidate login.
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    eventCategory: 'TrackTrace',
    eventAction: 'My Applications',
    eventLabel: past ? 'Past application' : 'Active application',
    event: 'eventTracker',
  });
};

export {
  useDataLayer,
  generateDataLayer,
  pushCandidateEvent,
  pushJobAlertRemovedEvent,
  pushFavJobRemovedFromListEvent,
  pushItsmeDatalayerEvent,
  pushFavoriteDatalayerEvent,
  pushApplicationDatalayerEvent,
};
