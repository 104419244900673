import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import SelectField from "@FormElements/SelectField";
import FormContext from "formContext";

function BranchSelectField({intl, settings, ...rest}) {
    const {additionalData} = useContext(FormContext);

    if (!additionalData.branches || !Object.keys(additionalData.branches).length) {
        return '';
    }

    let branches = {};
    Object.values(additionalData.branches).forEach(data => {
        Object.entries(data.children).forEach(child => {
            branches[child[1].branchName] = child[1].branchId
        });
    });

    settings.options = [{
        value: '',
        label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'})
    }];

    Object.entries(branches).forEach(branch => {
        settings.options.push({
            label: branch[0],
            value: branch[1]
        })
    })

    return (
        <SelectField
            settings={settings}
            {...rest}
        />
    )

}

export default injectIntl(BranchSelectField);
