import React from 'react';
import { useIntl } from 'react-intl';

function FormatFileSize(bytes, decimals = 0) {
  const intl = useIntl();

  if (bytes === 0) {
    return `0 ${intl.formatMessage({id: 'UploadField.Bytes'})}`
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${intl.formatMessage({id: `UploadField.${sizes[i]}`})}`;
}

export default FormatFileSize
