import React from "react";
import Icon from "@Components/Icon";

function SectionHeader({title, handleAddItem, label, description}) {
  return (
    <div className="block__header">
      <h2 className="block__title">{title}</h2>
      {handleAddItem && (
        <a href="#" className="block__control" onClick={handleAddItem}>
          <Icon type="add" className="icon icon--inline fill-brand--blue"/>
          {label}
        </a>
      )}
      {description && (
        <div className="block__description block__description--s text--alternative">
          <p>{description}</p>
        </div>
      )}
    </div>
  );
}

export default SectionHeader;
