import React, {useEffect, useState, useCallback} from 'react';
import {Switch, Route, Redirect, useLocation} from 'react-router-dom'
import {hot} from 'react-hot-loader/root';
import {IntlProvider} from 'react-intl'
import Dashboard from "@Pages/Dashboard";
import MyApplications from "@Pages/MyApplications";
import Homepage from "@Pages/Homepage";
import NotFound from "@Pages/NotFound";
import Login from "@Pages/Login";
import Settings from "@Pages/Settings";
import Favourites from "@Pages/Favourites";
import Register from "@Pages/Register";
import Register2Step from "@Pages/Register2Step";
import RegisterConfirmation from "@Pages/RegisterConfirmation";
import ResendConfirmation from "@Pages/ResendConfirmation";
import ForgotPassword from "@Pages/ForgotPassword";
import ManageEmailAlerts from "@Pages/ManageEmailAlerts";
import RtbfLoggedOut from "@Pages/RtbfLoggedOut";
import InterviewPreparation from "@Pages/InterviewPreparation";
import {FormProvider} from "formContext";
import axios from 'axios';
import { useCookies } from "react-cookie";
import GetClientRoutes from 'GetClientRoutes';
import useLanguageSwitcher from 'languageSwitcher';
import RequestConfirmation from "@Pages/RequestConfirmation";
import JobPreferences  from "@Pages/JobPreferences";
import Logger from '@ffw/logger-lib';
import TrackingHistory from "@Pages/TrackingHistory";
import PersonalInformation from "@Pages/PersonalInformation";
import { Navigation, initEventsRel } from '@rel/experience';

const routes = GetClientRoutes();
let axiosInterceptorAlreadyDefined = false;

const isMyRandstadEnabled = process.env.REACT_APP_MYRANDSTAD_ENABLED === 'true';
const isSavedJobIconEnabled = process.env.REACT_APP_ENABLE_SAVED_JOB_ICON === 'true';

function App({currentLanguage, messages, context, authData, links}) {
  const logoUrl = currentLanguage === process.env.REACT_APP_DEFAULT_LANGUAGE ? '' : currentLanguage;

  const navigationProps = {
    ...isMyRandstadEnabled && {
      myRandstad: {
        text: 'my randstad',
        href: '',
        user: null,
      },
    },
    ...isSavedJobIconEnabled && {
      savedJobs: {
        text: '0',
        href: '/',
      },
    },
    logo: {
      title: messages?.['MegaMenu.Logo.Title'],
      href: `/${logoUrl}`,
    },
    aria: {
      navigation: {
        menu: messages?.['MegaMenu.Aria.Navigation.Menu'],
        navigation: messages?.['MegaMenu.Aria.Navigation.Navigation'],
        close: messages?.['MegaMenu.Aria.Navigation.Close'],
      },
      megaMenu: {
        main: messages?.['MegaMenu.Aria.MegaMenu.Main'],
        close: messages?.['MegaMenu.Aria.MegaMenu.Close'],
      },
    },
  };

  const [myRandstadProps, setMyRandstadProps] = useState(navigationProps.myRandstad);
  const [savedJobsProps, setSavedJobsProps] = useState(navigationProps.savedJobs);
  const [itemProps, setItemProps] = useState(links?.items || []);

  // We have to collect all routes outside the App function, because of the
  // pageLoader. If we use the pageLoader inside a function, SSR wont load the
  // components.
  let routeComponents = [];
  const [cookies, setCookie, removeCookie] = useCookies([]);
  // Get the language.
  const lang = currentLanguage ? currentLanguage : process.env.REACT_APP_DEFAULT_LANGUAGE;

  // Get configuration to use REL navigation
  let isEnableRelNavigation;
  if (
    !isEnableRelNavigation &&
    typeof window !== "undefined" &&
    window.isEnableRelNavigation
  ) {
    isEnableRelNavigation = window.isEnableRelNavigation;
  }

  if (!messages && typeof window !== "undefined" && window.__MESSAGES_DATA__) {
    messages = window.__MESSAGES_DATA__;
  }

  const location = useLocation();
  const {initLanguageSwitcher} = useLanguageSwitcher();

  const updateNavigationProps = useCallback((newProps) => {
    if (isMyRandstadEnabled && newProps.myRandstad) {
      setMyRandstadProps(newProps.myRandstad);
    }

    if (newProps.myRandstadMenuItems) {
      setItemProps([...itemProps, (newProps.myRandstadMenuItems || {})]);
    }
  }, []);

  useEffect(() => {
    if (isMyRandstadEnabled) {
      setMyRandstadProps({
        text: window.myRandstadText,
        href: window.myRandstadUrl,
        user: window.myRandstadUsername ? {
          initials: window.myRandstadUsername?.charAt(0)?.toUpperCase(),
          names: window.myRandstadUsername,
        } : null,
      });
    }

    const updateSavedJobsCounter = () => setSavedJobsProps({
      text: window.relNavFavoritesCounter,
      href: window.relNavFavoritesUrl,
    });

    if (isSavedJobIconEnabled) {
      updateSavedJobsCounter();
      document.addEventListener('favoritesCounterUpdated', updateSavedJobsCounter);
    }

    // This event only dispatch in auth-widget of OPCOs it
    document.addEventListener('updateNavigationProps', (event) => {
      updateNavigationProps(event.detail);
    });

    return () => {
      document.removeEventListener('updateNavigationProps', (event) => {
        updateNavigationProps(event.detail);
      });

      if (isSavedJobIconEnabled) {
        document.removeEventListener('favoritesCounterUpdated', updateSavedJobsCounter);
      }
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0,0);
    initLanguageSwitcher();
  }, [location]);

  useEffect(() => {
    document.querySelector('html').classList.add('js')
  }, []);

  /**
   * Implement datalayer events from REL for the Mega menu.
   */
  useEffect(() => {
    if (isEnableRelNavigation) {
      initEventsRel();
    }
  }, []);

  let isAuthenticated = false;
  if (authData && authData.isAuthenticated) {
    isAuthenticated = authData.isAuthenticated
  }
  else if (typeof window !== "undefined" && window.__AUTH_DATA__) {
    isAuthenticated = window.__AUTH_DATA__.isAuthenticated;
  }

  const ProtectedRoute = ({component: Component, lang, routes, access, ...rest}) => {
    let shouldRedirect = false;
    let redirectTarget;

    if (isAuthenticated && access === 'anonymous') {
      shouldRedirect = true;
      redirectTarget = routes[lang]['dashboard']['url'];
    }
    // Adding case for IT Classic. User should be logged out from the environment due to cutover period.
    else if ((!isAuthenticated && access === 'authenticated') || (process.env.REACT_APP_COUNTRY === 'it' && access === 'authenticated')) {
      shouldRedirect = true;
      redirectTarget = routes[lang]['login']['url'];

      removeCookie('MyRandInfo');
      removeCookie('AccessToken');
      removeCookie('RefreshToken');
      // Temporary, to protect my sanity:
      removeCookie('RefreshToken', {path: '/mein-randstad'});
    }

    return (
      <Route {...rest} render={props => (
        (!shouldRedirect) ? <Component {...props} /> :
          <Redirect to={redirectTarget}/>
      )}/>
    )
  };

  // Iterate through all supported languages for the country.
  for (let language in routes) {
    // Iterate through all supported routes and components for the country.
    for (let routeName in routes[language]) {
      if (routes[language].hasOwnProperty(routeName)) {
        const route = routes[language][routeName];
        let Component = null;
        switch (route.component) {
          case 'dashboard':
            Component = Dashboard;
            break;
          case 'personal-information':
            Component = PersonalInformation;
            break;
          case 'job-preferences':
            Component = JobPreferences;
            break;
          case 'my-applications':
            Component = MyApplications;
            break;
          case 'tracking-history':
            Component = TrackingHistory;
            break;
          case 'login':
            Component = Login;
            break;
          case 'account':
            Component = Settings;
            break;
          case 'favourites':
            Component = Favourites;
            break;
          case 'register':
            Component = Register;
            break;
          case 'register-2-step':
            Component = Register2Step;
            break;
          case 'register-confirmation':
            Component = RegisterConfirmation;
            break;
          case 'resend-confirmation':
            Component = ResendConfirmation;
            break;
          case 'request-confirmation':
            Component = RequestConfirmation;
            break;
          case 'forgot-password':
            Component = ForgotPassword;
            break;
          case 'manage-email-alerts':
            Component = ManageEmailAlerts;
            break;
          case 'request-to-be-forgotten':
            Component = RtbfLoggedOut;
            break;
          case 'homepage':
            Component = Homepage;
            break;
          case 'interview-preparation':
            Component = InterviewPreparation;
            break;
          default:
            break;
        }
        // Add the route in the renderable array.

        let RouteComponent = Route;
        let access;
        if (route.hasOwnProperty('access')) {
          access = route.access;
          RouteComponent = ProtectedRoute;
        }
        let exact=true;

        if (route.hasOwnProperty('exact')) {
          exact=route.exact;
        }
        routeComponents.push(
          <RouteComponent
            exact={exact}
            key={`${language}-${routeName}`}
            path={`${route.url}`}
            component={Component}
            routes={routes}
            lang={lang}
            access={access}
          />
        );
      }
    }
  }
  // Everything that is not defined as route will lead to NotFound page.
  routeComponents.push(<Route key='not-found' component={NotFound}/>);

  if (!axiosInterceptorAlreadyDefined) {
    axiosInterceptorAlreadyDefined = true;
    axios.interceptors.request.use(config => {
      if (!config.hasOwnProperty('skipInterceptor') || config.skipInterceptor === false) {
        config.params = {language: lang};
      }
      return config
    });
  }

  const onIntlError = e => {
    if (e.includes('in Intl.NumberFormat. Using default locale: ')) {
      return
    }
    Logger.error(e, "App.js");
  };

  return (
    <IntlProvider defaultLocale={process.env.REACT_APP_DEFAULT_LANGUAGE} locale={lang} messages={messages[lang]} onError={onIntlError}>
      {isEnableRelNavigation && (
        <Navigation
          {...navigationProps}
          myRandstad={myRandstadProps}
          savedJobs={savedJobsProps}
          {...links}
          items={itemProps}
        />
      )}
      <FormProvider context={context} authData={authData} messages={messages}>
        <Switch>
          {routeComponents}
        </Switch>
      </FormProvider>
    </IntlProvider>
  )
}

export default hot(App)
