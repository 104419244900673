import React from "react";
import Modal from "@Components/Modal";

const PrivacyPolicyModal = props => {
  return (
    <Modal
      {...props}
    >
      <p>
        <strong>
          Einwilligung in die Erhebung und Verarbeitung von Daten durch die Randstad Gruppe.
        </strong>
      </p>
      <p>
        Mir ist bewusst, dass ich mich bei Randstad Deutschland GmbH &amp; Co KG („Randstad“), einem mit höchst
        modernen Methoden der IT und des Personalwesens arbeitenden Unternehmen, bewerbe. Folgende Gesellschaften
        der Randstad Gruppen agieren hierbei als “Gemeinsam Verantwortliche” im Sinne von Art. 26 DSGVO:
      </p>
      <ul>
        <li>Randstad Deutschland GmbH &amp; Co. KG</li>
        <li>Randstad Outsourcing GmbH</li>
        <li>Randstad Automotive GmbH &amp; Co. KG</li>
        <li>GULP Information Services GmbH</li>
        <li>GULP Consulting Services GmbH</li>
        <li>GULP Solution Services GmbH &amp; Co. KG</li>
        <li>GULP Schweiz AG</li>
        <li>Mühlenhoff & Partner GmbH (Risesmart)</li>
        <li>Tempo Team Management Holding GmbH</li>
        <li>Tempo-Team Personaldienstleistungen GmbH</li>
        <li>Tempo-Team Engineering GmbH</li>
        <li>Tempo-Team Outsourcing GmbH</li>
        <li>Tempo-Team Managed Service Provider GmbH</li>
      </ul>
      <br />
      <p>
        Zusätzlich werden meine Daten, die ich im Rahmen meiner Bewerbung Randstad zur Verfügung stelle,
        an andere Gesellschaften innerhalb der Randstad-Gruppe weitergegeben.
      </p>
      <p>
        1.&nbsp; &nbsp; Mit Blick darauf willige ich ein, dass meine Daten für den Zweck meiner Bewerbung und
        dem Abschluss eines Arbeitsverhältnisses innerhalb der Randstad Gruppe (dazu gehören:
        Randstad Sourceright GmbH, Randstad N.V., Monster Worldwide Deutschland GmbH,
        Twago Team2Venture GmbH) genutzt und auch weitergegeben werden.
        Meine Daten dürfen zudem über das Ende des konkreten Bewerbungsverfahrens hinaus gespeichert werden,
        um mich später für andere Stellen zu kontaktieren.
      </p>
      <p>
        2.&nbsp; &nbsp; Meine Einwilligung gilt zu meinem eigenen Schutz nur für eine Dauer von maximal zwölf Monaten.
        Danach wird durch die Randstad Gruppe keine Verarbeitung meiner personenbezogenen Daten auf Basis dieser
        Einwilligung mehr vorgenommen.
      </p>
      <p>
        3.&nbsp; &nbsp; Ich habe das Recht, diese Einwilligung jederzeit, also auch vor Ablauf der zwölf Monate,
        ohne Angabe einer Begründung für die Zukunft zu widerrufen. Durch den Widerruf wird die Rechtmäßigkeit der
        aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung nicht berührt.
      </p>
      <p>
        4.&nbsp; &nbsp; Ich habe das Recht, dieser Einwilligungserklärung nicht zuzustimmen. In diesem Fall kann ich
        meine Bewerbung nicht online abgeben, sondern muss diese per Post übersenden.
      </p>
      <p>
        Mir ist bewusst, dass meine personenbezogenen Daten nicht nur nach der vorstehenden Einwilligung für die
        vorgenannten Maßnahmen verarbeitet werden, sondern auch aufgrund gesetzlicher Grundlagen, einschließlich
        des berechtigten Interesses von Randstad. Weitere Informationen, insbesondere zur verantwortlichen Stelle
        und meinen Rechten,
        finde ich in den <a href="/datenschutzbestimmungen/" target="_blank" rel="noopener noreferrer">Datenschutzhinweisen von Randstad</a>.
      </p>
      <p>Stand Mai. 2022</p>
    </Modal>
  );
};

export default PrivacyPolicyModal;
