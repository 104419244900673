import React from "react";
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon";

function ExperienceItem({children, title, handleEditItem, handleDeleteItem, label, index = 0}) {
  return (
    <>
      <div className="closable" {...{[`data-rs-notification-${index}`]: ''}}>
        <div className="my-environment-item__card p-s mb-s l:p-m">
          <div className="my-environment-header">
            <h2 className=" my-environment-header__title--s mr-xxs" data-rs-closable-fadeout="">
              {title}
            </h2>
            <div className="my-environment__controls mt-xxs" data-rs-closable-fadeout="">
              {handleEditItem && (
                <button className="button--clean" onClick={e => handleEditItem(index)}>
                  <Icon type="edit" className="icon icon--inline"/>
                  <span className="hidden--visually">{label}</span>
                </button>
              )}
              {handleDeleteItem && (
                <button className="button--clean" data-rs-closable={`data-rs-notification-${index}`} onClick={e => handleDeleteItem(index)}>
                  <Icon type="trash" className="icon icon--inline icon--hover"/>
                  <span className="hidden--visually"/>
                </button>
              )}
            </div>
          </div>
          {children}
        </div>
      </div>
    </>
  );
}

export default injectIntl(ExperienceItem);
