import React from 'react';

function dispatchRerender() {
  // Dispatch event to re-attach orbit functionality.
  if (typeof document !== 'undefined') {
    let event;
    if (typeof (Event) === "function") {
      event = new Event('react-rerender');
    }
    else {
      event = document.createEvent("Event");
      event.initEvent("react-rerender", true, true);
    }
    document.dispatchEvent(event);
  }
}

export default dispatchRerender;
