import React, {useContext} from 'react';
import AutoSuggestWithTags from "@FormElements/AutoSuggestWithTags";
import FormContext from "formContext";
import {injectIntl} from "react-intl";
import {useLanguagesList} from "i18nHelper";

function MultipleLanguages({intl, settings, ...rest}) {
  const {formData, currentStep, setFormData} = useContext(FormContext);
  const {storeTagsIn} = settings || {};

  let languages = useLanguagesList(intl.locale);

  let options = [];
  let tagsArray = [];
  if (formData && formData[currentStep] && formData[currentStep][storeTagsIn]) {
    // Languages are being saved as IDs in 'language_ids' so we need to transform them to readable strings.
    //   We compare the stored ID to the languagesList from the i18nHelper and we get the readable string so we use it as a tag.
    formData[currentStep][storeTagsIn].forEach(language_id => {
      let languageId = Object.keys(languages).find(foundLanguage => foundLanguage === language_id.toString())
      tagsArray.push(languages[languageId]);
    })
  }

  if (languages) {
    Object.keys(languages).forEach((k, index) => {
      let shouldSkip = false;
      if (formData && formData[currentStep] && formData[currentStep][storeTagsIn]) {
        const match = formData[currentStep][storeTagsIn].find(el => el.toString() === k);
        if (match) {
          shouldSkip = true;
        }
      }

      if (!shouldSkip && formData && formData[currentStep] && formData[currentStep][settings.name] && formData[currentStep][settings.name].length) {
        if (!languages[k].toLowerCase().includes(formData[currentStep][settings.name].toLowerCase())) {
          shouldSkip = true;
        }
      }

      if (!shouldSkip) {
        options.push(languages[k]);
      }
    })
  }

  if (!options) {
    return '';
  }

  settings['options'] = options.sort();
  rest.onChange = e => {
    const {value} = e.target;
    const match = options.find(el => el === value);
    let alreadyExisting = false;
    if (formData && formData[currentStep] && formData[currentStep][storeTagsIn]) {
      alreadyExisting = formData[currentStep][storeTagsIn].find(el => el === value);
    }
    const languageId = getLanguageIdByName(match);
    if (match && languageId && !alreadyExisting) {
      setFormData(prev => {
        if (!prev[currentStep][storeTagsIn]) {
          prev[currentStep][storeTagsIn] = [];
        }
        prev[currentStep][storeTagsIn].push(languageId);
        delete prev[currentStep][settings.name];
        return {
          ...prev
        }
      });
    }
    else {
      setFormData(prev => {
        if (!prev[currentStep]) {
          prev[currentStep] = {};
        }
        prev[currentStep][settings.name] = value;
        return {
          ...prev
        }
      });
    }
  };

  const getLanguageIdByName = name => {
    let result;
    Object.keys(languages).forEach((k, index) => {
      if (languages[k] === name) {
        result = index
      }
    });
    return result + 1;
  }


  const handleRemove = key => {
    setFormData(prev => {
      prev[currentStep][storeTagsIn].splice(key, 1);
      if (!prev[currentStep][storeTagsIn]) {
        delete prev[currentStep];
      }
      return {
        ...prev
      }
    });
  };

  if (settings.errors && settings.errors.hasOwnProperty(storeTagsIn)) {
    settings.errors[settings.name] = settings.errors[storeTagsIn];
  }

  return (
    <AutoSuggestWithTags
      tagsArray={tagsArray}
      settings={settings}
      handleRemove={handleRemove}
      {...rest}
    />
  );
}

export default injectIntl(MultipleLanguages);
