import React, {useContext, useEffect, useRef} from "react";
import {useRouteMatch} from 'react-router-dom';
import { useHistory } from "react-router-dom";
import Link from "@Components/Link";
import Icon from "@Components/Icon";
import {injectIntl} from "react-intl";
import GetRouteByName from 'getRouteByName';
import FormContext from "formContext";

const SubMenu = ({intl, skipBreadcrumbs = false}) => {
  const {isAuthenticated, additionalData: {user}} = useContext(FormContext);
  const currentLanguage = intl.locale;
  const route = useRouteMatch();

  const history = useHistory();

  function handleMenuItemClick(routes) {
    history.push(routes.url.replace(':type', ''));
  }

  const ref = useRef();

  useEffect(() => {
    // Check how many menuItems we have. The min. requirement is 2 for the TabBar to work.
    const menuItems = process.env.REACT_APP_MENU_ITEMS_AUTHENTICATED.split(', ');
    let menuItemsLength = 0;
    menuItems.forEach((menuName) => {
      if (routes[menuName]) {
        menuItemsLength++;
      }
    });
    if (!ref.current || menuItemsLength < 2) return;

    // Fix window undefined issue.
    const { TabBar } = require("@ffw/randstad-local-orbit/js/components/tab-bar");
    new TabBar(ref.current);
  }, []);

  // Load the routes for the specific country.
  const routesCore = require(`../../../config/core/client.routes.json`);
  let routesOpCo = {};
  try {
    routesOpCo = require(`../../../config/${process.env.REACT_APP_COUNTRY}/client.routes.json`);
  }
  catch (e) {
  }
  const routes = {
    ...routesCore,
    ...routesOpCo
  }[currentLanguage];

  const hasEntitlements = user && user.entitlements && user.entitlements.OBJ;

  let menuItems;

  if (!isAuthenticated) {
    menuItems = process.env.REACT_APP_MENU_ITEMS_UNAUTHENTICATED.split(', ');
  }
  else {
    menuItems = process.env.REACT_APP_MENU_ITEMS_AUTHENTICATED.split(', ');
    if (hasEntitlements) {
      menuItems = process.env.REACT_APP_MENU_ITEMS_AUTHENTICATED_ENTITLEMENTS.split(', ');
    }
  }

  const menuItemsRenderable = [];
  let currentRoute;
  menuItems.forEach((menuName, menuIndex) => {

    let isActiveMenu = false;
    if (routes.hasOwnProperty(menuName) && routes[menuName].hasOwnProperty('menuTitle')) {
      if (routes[menuName].url === route.path) {
        isActiveMenu = true;
        currentRoute = routes[menuName];
      }
      if (routes[menuName]['menuTitle']) {
        if (menuName === 'logout') {
          let logoutRouteUrl = process.env.REACT_APP_LOGOUT_URL;
          if (currentLanguage !== process.env.REACT_APP_DEFAULT_LANGUAGE) {
            logoutRouteUrl = `${logoutRouteUrl}?destination=/${currentLanguage}`;
          }

          if (isAuthenticated) {
            menuItemsRenderable.push(
              <div className={`tab-bar__item`} data-rs-tab-bar-item="" key={`menu-item-${menuIndex}`}>
                <a id="logout-link-app" href={logoutRouteUrl}>{intl.formatMessage({id: routes[menuName]['menuTitle']})}</a>
              </div>
            );
          } else {
            menuItemsRenderable.push(
              <li className={`navigation__menu-item`} key={`menu-item-${menuIndex}`}>
                <a id="logout-link-app" href={logoutRouteUrl}>{intl.formatMessage({id: routes[menuName]['menuTitle']})}</a>
              </li>
            );
          }
        }
        else {
          if (isAuthenticated) {
            const handleClick = () => handleMenuItemClick(routes[menuName]);
            let attr = { 'data-rs-tab-bar-item': '', onClick: handleClick};
            let externalUrl = '';
            if (
                routes[menuName].external && routes[menuName]['menuTitle'] !== 'Menu.WorkerServices' ||
                routes[menuName].external && hasEntitlements && routes[menuName]['menuTitle'] === 'Menu.WorkerServices'
            ) {
              externalUrl = process.env[routes[menuName].externalUrlEnvKey] ?? '';
              attr = {};
            }
            menuItemsRenderable.push(
              <a href={externalUrl || "#?"} className={`tab-bar__item ${isActiveMenu ? 'active' : ''}`} {...attr} key={`menu-item-${menuIndex}`}>
                {routes[menuName]['menuTitle'] == 'Menu.Dashboard' ? <Icon type='dashboard'/> : null}
                {routes[menuName]['menuTitle'] == 'Menu.Profile' ? <Icon type='account-circle'/> : null}
                {routes[menuName]['menuTitle'] == 'Menu.Settings' ? <Icon type='settings'/> : null}
                {routes[menuName]['menuTitle'] == 'Menu.MyApplications' ? <Icon type='briefcase'/> : null}
                {hasEntitlements && routes[menuName]['menuTitle'] == 'Menu.WorkerServices' ? <Icon type='badge'/> : null}
                {routes[menuName]['menuTitle'] == 'Menu.JobPreferences' ? <Icon type='filter'/> : null}
                {intl.formatMessage({id: routes[menuName]['menuTitle']})}
              </a>
            );
          } else {
            let attr = {'attributes': (routes[menuName]['attributes']) ? routes[menuName]['attributes'] : null};
            menuItemsRenderable.push(
              <li className={`navigation__menu-item ${isActiveMenu ? 'navigation__menu-item--active' : ''}`} key={`menu-item-${menuIndex}`}>
                <Link to={routes[menuName].url.replace(':type', '')} {...attr}>{intl.formatMessage({id: routes[menuName]['menuTitle']})}</Link>
              </li>
            );
          }
        }
      }
    }
  });

  const renderBreadcrumbs = () => {
    if (isAuthenticated) {
      if (currentRoute && currentRoute['component'] && currentRoute['component'] !== 'manage-email-alerts') {
        return '';
      }
    }

    const dashboard = GetRouteByName('dashboard');

    if (!dashboard) {
      return '';
    }

    return (
      <div className="navigation__bottom">
        <nav className="breadcrumb" aria-label="breadcrumb">
          <Link to={dashboard.url} className="breadcrumb__link hidden--from-l">{intl.formatMessage({id: dashboard['breadcrumbTitle']})}</Link>
          <ul className="breadcrumb__list hidden--until-l">
            <li className="breadcrumb__item">
              <a href={intl.formatMessage({id: 'Breadcrumbs.HomeLink'})} className="breadcrumb__link">{intl.formatMessage({id: 'Breadcrumb.Home'})}</a>
            </li>
            <li className="breadcrumb__item">
              <Link to={dashboard.url} className="breadcrumb__link">{intl.formatMessage({id: dashboard['breadcrumbTitle']})}</Link>
            </li>
            {currentRoute && currentRoute['breadcrumbTitle'] && <li className="breadcrumb__item">{intl.formatMessage({id: currentRoute['breadcrumbTitle']})}</li>}
          </ul>
        </nav>
      </div>
    )
  };

  return (
    <>
    {!isAuthenticated ?
    <div className="navigation custom-navigation">
      <ul className="navigation__menu navigation__menu--sub hidden--until-l">
        {menuItemsRenderable}
      </ul>
      {!skipBreadcrumbs && renderBreadcrumbs()}
    </div>
    :
    <div className="block bg-greyscale--grey-10 my-environment__sub-menu">
      <div className="wrapper">
        <div ref={ref} className="tab-bar tab-bar--icon" data-rs-tab-bar="" data-rs-tab-bar-animation-type="instant">
          {menuItemsRenderable}
        </div>
        {!skipBreadcrumbs && renderBreadcrumbs()}
      </div>
    </div>
    }
    </>
  )
};

export default injectIntl(SubMenu);
