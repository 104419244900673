import React from "react";
import Link from "@Components/Link";
import Icon from "@Components/Icon";

function DashboardItem({children, title, id="", tooltip, linkText, linkPath, isExternal = false, bg = "bg-brand--white", stacked = true, wrapper = true, blockWrapper = false, blockDescription = null, blockDescriptionLinkText = null, blockDescriptionLinkUrl, spacing = true}) {
	const renderToolTip = (text) => {
		if (!text) {
			return null;
		}
		return (
			<span className="tooltip">
      <span className="tooltip__trigger text--alternative" tabIndex="0">
        <Icon type="help" className="icon icon--inline"/>
        <span className="tooltip__content ">
          <span className="tooltip__text">{text}</span>
          <span className="tooltip__pointer" style={{left: "5px"}}/>
        </span>
      </span>
    </span>
		);
	}

	const renderLink = () => {
		if (!linkPath || !linkText) {
			return '';
		}
		if (isExternal) {
			return (
				<a className="title--xxs l:text-title-s" href={linkPath}>{linkText}</a>
			);
		}
		else {
			return (
				<Link className="title--xxs l:text-title-s" to={linkPath}>{linkText}</Link>
			);
		}
	}

	return (
		<div id={`${id}`} className={`${bg} ${spacing ? "pb-l" : ""}`}>
			<div className={`${blockWrapper ? 'block__wrapper l:pt-l' : ''} ${stacked ? "block__wrapper--stacked" : ""} ${wrapper ? "wrapper" : ""}`}>
				{title || (linkText && linkPath) ?
					<div className="block__header block__header--split">
						{ title && <h2 className="title--s pb-xs l:pb-none l:text-title-m">{title} {renderToolTip(tooltip)}</h2> }
						{renderLink()}

            {blockDescription &&
            <div className="block__description block__description--s block__description--dark block__description--optional" style={{ width: "100%" }}>
              <Icon type="info" className="icon icon--inline" />
              <p>
                {blockDescription} <Link to={blockDescriptionLinkUrl}>{blockDescriptionLinkText}</Link>.
              </p>
            </div>
            }
					</div> : null
				}
				{children}
			</div>
		</div>
	);
}

export default DashboardItem;
