import React from 'react';
import {injectIntl} from "react-intl";
import Link from "@Components/Link";
import GetRouteByName from 'getRouteByName';

function ButtonWithLink({intl, settings, ...rest}) {

  const {text, filled, icon, plain, fullWidth, medium, withWrapperDiv = true, link, isExternal = false, divClasses = ''} = settings || {};

  const classes = ['button'];
  if (filled) {
    classes.push('button--filled');
  }
  if (icon) {
    classes.push('button--icon button--full-width-to-icon');
  }
  if (plain) {
    classes.push('button--plain');
  }
  if (fullWidth) {
    classes.push('button--full-width');
  }
  if (medium) {
    classes.push('button--m');
  }

  const fieldProps = {
    className: classes.join(' ')
  };

  const renderLink = () => {
    if (link && text) {
      const url = GetRouteByName(link).url;
      return isExternal ?
        <a href={url} {...fieldProps} {...rest}>{intl.formatMessage({id: text})}</a> :
        <Link to={url} {...fieldProps} {...rest}>{intl.formatMessage({id: text})}</Link>
    }
  }

  if (!withWrapperDiv) {
    return (
      <>
        {renderLink()}
      </>
    )
  }
  return (
    <div className={`form-group ${divClasses}`}>
      {renderLink()}
    </div>
  );
}

export default injectIntl(ButtonWithLink);
