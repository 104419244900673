const getGreetingMessage = ( { country }) => {
  const hours = new Date().getHours();

  if (hours < 6 && (country !== 'de' || country !== 'fr')) {
    return 'Dashboard.Header.Greeting.GoodNight';
  } else if (hours < 12 && (country === 'de' || country === 'fr')) {
    return 'Dashboard.Header.Greeting.GoodMorning';
  } else if (hours >= 6 && hours < 12) {
    return 'Dashboard.Header.Greeting.GoodMorning';
  } else if (hours >= 12 && hours < 19 && country === 'fr') {
    return 'Dashboard.Header.Greeting.GoodAfternoon';
  } else if (hours >= 12 && hours < 18) {
    return 'Dashboard.Header.Greeting.GoodAfternoon';
  } else if (country === 'de' && hours >= 18 && hours < 24) {
    return 'Dashboard.Header.Greeting.GoodEvening';
  } else if (country === 'fr' && hours >= 19 && hours < 24) {
    return 'Dashboard.Header.Greeting.GoodEvening';
  } else if (hours >= 18) {
    return 'Dashboard.Header.Greeting.GoodEvening';
  }
};

export default getGreetingMessage;
