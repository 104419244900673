import { useCallback } from "react";

function useOrbitComponent(component) {
  const ref = useCallback(node => {
    if (!component) return;
    
    if (node !== null) {
      if (component === 'carousel') {
        const { Carousel } = require(`@ffw/randstad-local-orbit/js/components/carousel`);
        new Carousel(node);
      }
      else if (component === 'card') {
        const { Card } = require(`@ffw/randstad-local-orbit/js/components/card`);
        new Card(node);
      }
    }
  }, []);
  
  return [ref];
}

export default useOrbitComponent;
