import React, { useContext, useState } from "react";
import { injectIntl } from "react-intl";
import SkillItem from "@Components/SkillItem";
import FormContext from "formContext";
import Modal from "@Components/Modal";
import Button from "@FormElements/Button";
import axios from "axios";

function SkillItemList({intl, skills, handleSkillsAdd}) {
  const {
    setFormData,
    setFormName,
    handleErrors,
    setErrors,
    setModalOpen,
    triggerRerender,
    setEditedItemKey,
  } = useContext(FormContext);

  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [indexForDelete, setIndexForDelete] = useState(null);

  const handleSkillEdit = (index) => {
    const item = skills.items[index];
    let {name, level, key} = item;
    level = level.toString();
    setFormData([{name, level}]);
    setEditedItemKey(key);
    setFormName("user-skills");
    setModalOpen(true);
  }

  const handleSkillDelete = (index) => {
    setConfirmationModalOpen(true);
    setIndexForDelete(index);
  }

  const handleDeleteSubmit = () => {
    setLoading(true);

    const item = skills.items[indexForDelete];
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/user/skills/${item.key}`)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setConfirmationModalOpen(false);
          setLoading(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          triggerRerender();
        }
      })
      .catch(error => {
        handleErrors(error.response.data);
      });
  }

  const renderSkills = (isMobile = false) => {
    const skillItems = [];
    if (skills && skills.count > 0) {
      skills.items.forEach((skill, index) => {
        skillItems.push(<SkillItem index={index} handleEdit={handleSkillEdit} handleDelete={handleSkillDelete} isMobile={isMobile} {...skill}/>)
      });
    } else {
      return <div className="my-environment--empty-state text--alternative">
        <p>{intl.formatMessage({id: 'PersonalInformation.Skills.NoItems.Text'})}</p>
      </div>
    }
    return skillItems;
  };



  const onCloseModal = () => {
    setConfirmationModalOpen(false);
  }

  const modalFooter = () => {
    const modalButtonSettings = {
      medium: true,
      withWrapperDiv: false
    }

    return (
      <>
        <div className="button-group button-group--full-width hidden--from-l">
          <Button
            onClick={handleDeleteSubmit}
            settings={{
              ...modalButtonSettings,
              fullWidth: true,
              filled: true,
              loading,
              text: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.DeleteButtonText"
            }} />
          <Button
            onClick={onCloseModal}
            settings={{
              ...modalButtonSettings,
              fullWidth: true,
              plain: true,
              text: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.CancelButtonText"
            }} />
        </div>
        <div className="button-group--options button-group hidden--until-l">
          <Button
            onClick={handleDeleteSubmit}
            settings={{
              ...modalButtonSettings,
              filled: true,
              loading,
              text: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.DeleteButtonText"
            }} />
          <Button
            onClick={onCloseModal}
            settings={{
              ...modalButtonSettings,
              plain: true,
              text: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.CancelButtonText"
            }} />
        </div>
      </>
    )
  }

  const renderModal = () => {
    if (!confirmationModalOpen) {
      return '';
    }
    return (
      <Modal
        title={intl.formatMessage({id: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.Title"})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={false}
        modalOverflow={false}
      >
        <p>{intl.formatMessage({id: "PersonalInformation.WorkPreferences.DeleteSectionModalConfirm.Description"})}</p>
      </Modal>
    );
  }

  return (
    <>
      {renderSkills()}
      {renderModal()}
    </>
  );
}

export default injectIntl(SkillItemList);
