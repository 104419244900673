import React from "react";

function Icon({type, className = 'icon', svgProps, ...rest}) {
  if (className) {
    return (
      <span className={className} {...rest}>
        <svg {...svgProps}>
          <use xlinkHref={`/themes/custom/bluex/dist/assets/image/icons.svg#${type}`}/>
        </svg>
      </span>
    );
  }
  else {
    return (
      <>
        <svg {...svgProps}>
          <use xlinkHref={`/themes/custom/bluex/dist/assets/image/icons.svg#${type}`}/>
        </svg>
      </>
    );
  }
}

export default Icon;
