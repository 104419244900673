import React from "react";
import Icon from "@Components/Icon";
import { injectIntl } from "react-intl";

import useToast from 'useToast'

const Toast = ({ intl, message, onClose, icon = 'close', links = [] }) => {
  const [ref] = useToast(onClose)

  return (
    <div ref={ref} className="toast bg-variant-brand-secondary" data-rs-toast="3" data-rs-toast-3="">
      <p className="toast__message">{message}</p>
      {links.length > 0 && <div className="toast__cta">
        {links.map((link, key) => {
          const { text, classes = [], ...attr } = link;
          classes.push('toast__cta-link')

          return <a {...attr} key={key} className="toast__cta button button--s button--white">{text}</a>
        })}
      </div>}
      <button className="button--icon-only" data-rs-closable="data-rs-toast-3" aria-label={intl.formatMessage({ id: "Close" })} tabIndex="0">
        {icon && (
          <Icon type={icon} className='icon icon fill--off-white-alpha-50' />
        )}
        <span className="hidden--visually">close</span>
      </button>
    </div>
  );
};

const withToast = Component => ({ show, ...props }) => (show ? <Component {...props} /> : null);

export default withToast(injectIntl(Toast));
