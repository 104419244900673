import React, {useEffect, useState} from 'react'
import wnumb from 'wnumb';
import Icon from "@Components/Icon"
import {compensation, currency, jobTypes} from "../../../../config/job.config.js";
import useJobCardHelper from "jobCardHelper";

const CardMeta = ({ details, intl }) => {
  /* These should be optimised with react hook (useConfig). */
  const useLowerCase = process.env.REACT_APP_USE_LOWER_CASE === "true";
  const metaSalary = process.env.REACT_APP_CARD_META_SALARY === "true";
  const metaLocation = process.env.REACT_APP_CARD_META_LOCATION === "true";
  const metaJobtype = process.env.REACT_APP_CARD_META_JOBTYPE === "true";
  const metaCompany = process.env.REACT_APP_CARD_META_COMPANY === "true";
  const metaDuration = process.env.REACT_APP_CARD_META_DURATION === "true";
  const metaHours = process.env.REACT_APP_CARD_META_HOURS === "true";
  const usePrefix = process.env.REACT_APP_CARD_SALARY_PREFIX === "true";
  const useSuffix = process.env.REACT_APP_CARD_SALARY_SUFFIX === "true";
  const internalJobtype = process.env.REACT_APP_CARD_META_INTERNAL_JOBTYPE === "true";
  const locationWithPostal = process.env.REACT_APP_CARD_LOCATION_WITH_POSTAL === "true";
  const jobTypeIntl = process.env.REACT_APP_CARD_JOBTYPE_WITH_INTL === "true";
  const salaryDecimals = process.env.REACT_APP_CARD_SALARY_DECIMALS ?? 0;
  const salaryHourlyDecimals = process.env.REACT_APP_CARD_SALARY_HOURLY_DECIMALS ?? 2;
  const salaryWithCommas = process.env.REACT_APP_CARD_SALARY_WITH_COMMAS === "true";
  const salaryWithRate = process.env.REACT_APP_CARD_SALARY_RATE === "true";

  const { getDistance } = useJobCardHelper();

  // Helper function that prepares job details.
  const getCardMeta = async (details) => {
    const {
      region = "",
      city = "",
      postal_code = "",
      jobType,
      company,
      job_type,
      compensationTypeId,
      currencyId,
      salaryMin,
      salaryMax,
      salaryRate,
      jobTypeId,
      duration,
      hours: hoursField = "",
      sub_specialism,
      applicationCardType
    } = details || {};

    const isOpenApplication = applicationCardType && applicationCardType === 'open';

    // Currency and compensation- get name by id and then translate.
    const c = currency[currencyId] || "";
    let jobCompensation = compensation[compensationTypeId] || "";
    let separator = process.env.REACT_APP_THOUSANDS_SEPARATOR ? process.env.REACT_APP_THOUSANDS_SEPARATOR : ',';

    const formatSalaryWithCommas = (n) => {
      return parseInt(n).toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }

    function formatSalary(value, compensationTypeId) {
      const prefixFormula = c ? c : process.env.REACT_APP_CURRENCY_SYMBOL;
      const suffixFormula = c ? ` ${c}` : ` ${process.env.REACT_APP_CURRENCY_SYMBOL}`;

      const salaryFormat = wnumb({
        decimals: salaryDecimals,
        thousand: process.env.REACT_APP_THOUSANDS_SEPARATOR ? process.env.REACT_APP_THOUSANDS_SEPARATOR : ',',
        ... usePrefix ? { prefix: prefixFormula } : {},
        ... useSuffix ? { suffix: suffixFormula } : {},
      });

      const salaryHourlyFormat = wnumb({
        mark: process.env.REACT_APP_MARK_SEPARATOR ? process.env.REACT_APP_MARK_SEPARATOR : '.',
        decimals: salaryHourlyDecimals,
        ... usePrefix ? { prefix: prefixFormula } : {},
        ... useSuffix ? { suffix: suffixFormula } : {},
      });

      return (compensationTypeId === '1' || compensationTypeId === '2')
        ? salaryHourlyFormat.to(parseFloat(value))
        : salaryFormat.to(parseFloat(value));
    }

    // Location separator.
    const sep = region && city ? ", " : "";
    let locationText = city + sep + region;
    if (locationWithPostal) {
      locationText = city.toLowerCase() + ( postal_code ? ` (${postal_code.toString().substring(0,2)})` : '');
    }
    let salaryText, jobTypeText;
    let salaryRateText = salaryRate ? `, ${salaryRate}` : '';
    if (internalJobtype) {
      jobTypeText = jobTypes[jobTypeId] || "";
    }
    else {
      jobTypeText = job_type ? job_type.toLowerCase() : '';
    }
    if (jobTypeText) {
      if (jobTypeIntl) {
        jobTypeText = intl.formatMessage({id: `Job.Type.${jobTypeText}`})
      }
      else {
        jobTypeText = internalJobtype ? jobType : jobTypeText;
      }
    }
    if (metaSalary) {
      if (salaryWithCommas) {
        let salaryTextString = `${c}${formatSalaryWithCommas(salaryMin)} - ${c}${formatSalaryWithCommas(salaryMax)} \
          ${intl.formatMessage({id: `Job.Compensation.${jobCompensation}`})}${salaryRateText}`;
        if (intl && intl.locale === 'fr') {
          salaryTextString = `${formatSalaryWithCommas(salaryMin)} ${c} - ${formatSalaryWithCommas(salaryMax)} ${c} \
          ${intl.formatMessage({id: `Job.Compensation.${jobCompensation}`})}${salaryRateText}`;
        }
        salaryText = salaryMin && salaryMin !== '0.00' && salaryMin !== '0' ? salaryTextString : "";
      }
      else if (salaryWithRate) {
        salaryRateText = salaryRate ? salaryRate : '';
        salaryText = salaryMin && salaryMin !== '0.00' && salaryMin !== '0' ?
          `${formatSalary(salaryMin, compensationTypeId)} - ${formatSalary(salaryMax, compensationTypeId)},\
          ${intl.formatMessage({id: `Job.Compensation.${jobCompensation}`})}, ${salaryRateText}` : "";
      }
      else {
        salaryText = (salaryMin && salaryMin !== '0') && (compensationTypeId && compensationTypeId !== '0') ?
        `${formatSalary(salaryMin, compensationTypeId)} ${intl.formatMessage({id: `Job.Compensation.${jobCompensation}`})}` : "";
      }
    }
    const durationText = duration ? intl.formatMessage({id: "Job.Duration"}, {n:duration}) : "";
    const hoursText = hoursField || "";

    const locationMetaField = {
      icon: "marker",
      text: locationText
    };

    const jobTypeMetaField = {
      icon: "briefcase",
      text: jobTypeText
    };

    const companyMetaField = {
      icon: "building",
      text: company
    };

    const salaryMetaField = {
      icon: "salary",
      text: salaryText
    };

    const hoursMetaField = {
      icon: "clock",
      text: hoursText
    };

    const jobDurationMetaField = {
      icon: "clock",
      text: durationText
    };
    
    const subSpecialismMetaField = {
      icon: "career",
      text: sub_specialism
    };

    /* Building the meta fields. */
    const metaFields = [
      ... metaLocation ? [locationMetaField] : [],
      ... metaJobtype ? [jobTypeMetaField] : [],
      ... metaCompany ? [companyMetaField] : [],
      ... metaSalary ? [salaryMetaField] : [],
      ... metaHours ? [hoursMetaField] : [],
      ... metaDuration ? [jobDurationMetaField] : [],
      ... isOpenApplication ? [subSpecialismMetaField] : [],
    ];

    // Set order to meta fields, filter empty value, and limit to {offset}.
    return metaFields.filter(i => i.text);
  }

  const [fields, setFields] = useState([]);
  const [distance, setDistance] = useState();

  useEffect(() => {
    (async () => {
      const meta = await getCardMeta(details);
      setFields(meta);

      const { latitude, longitude } = details || {};
      // Get distance. unit options: K(km), N(nautical miles).
      const dist = latitude && longitude ? await getDistance(latitude, longitude, "K") : "";
      const distanceText = dist ? dist.toFixed().toString() + " " + intl.formatMessage({id: "Job.Distance.Away"}) : "";
      setDistance(distanceText);
    })();
  }, []);

  return (
    <ul className="cards__meta">
      {distance && (
        <li className="cards__meta-item">
          <Icon type="gps-filled" className="icon icon--inline" />
          {distance}
        </li>
      )}
      {fields.map((item, i) => (
        <li key={i} className="cards__meta-item">
          <Icon type={item.icon} className="icon icon--inline" />
          {useLowerCase && item.icon !== 'salary' ? item.text.toLowerCase() : item.text}
        </li>
      ))}
    </ul>
  );
}

export default CardMeta;
