import React from 'react';
import Icon from "@Components/Icon";

function AccordionItem({index, title, subtitle, text}) {
  return (
    <li key={index} className="link-list__item">
      <div className="collapsible__trigger" data-rs-collapsible="" role="button" aria-expanded="false"
           data-rs-toggable="" tabIndex="0">
        <h3 className="link-list__link">
          {title}
          {subtitle && <p className="text--alternative pt-xs mb-none">{subtitle}</p>}
          <Icon type='chevron-down' className='hidden--from-l toggle-arrow icon'/>
          <Icon type='chevron-down-30' className='hidden--until-l toggle-arrow icon icon--l'/>
        </h3>
      </div>
      <div className="collapsible__content body-copy" data-rs-collapsible-content="" style={{maxHeight: 0}}>
        <div className="collapsible__content--wrapper" dangerouslySetInnerHTML={{__html: text}} />
      </div>
    </li>
  )
}

export default AccordionItem;