import React from 'react';
import {injectIntl} from "react-intl";
import JobCardList from "@Components/JobCardList";
import TextWithLink from "@Components/TextWithLink";

function Applications({intl, jobApplications, isSlider}) {

  const renderNoApplicationsText = () => {
    const settings = {
      text: 'Applications.NoApplications.Text',
      link: 'jobs',
      linkText: 'Utils.JobSearch',
      linkClasses: 'button button--m mt-s',
      divClasses: '',
      pClasses: 'content-block__description mt-s',
      isExternal: true
    }

    return <TextWithLink settings={settings}/>;
  };

  return (
    <>
      {jobApplications && jobApplications.length ?
        <div className="block__content block__content--full-on-s block__content--full-on-m">
          <JobCardList
            jobs={jobApplications}
            showMoreVisibility={false}
            offsetPerPage={3}
            isSlider={isSlider}
          />
        </div>
        : renderNoApplicationsText()
      }
    </>
  );
}

export default injectIntl(Applications);
