import React from 'react';

function Accordion({title, background, children}) {
  return (
    <div className={`block ${background}`}>
      <div className="block__wrapper wrapper">
        <div className="block__header">
          <h2 className="block__title">{title}</h2>
        </div>
        <div className="block__content">
          <ul className="link-list link-list--single accordion">
            {children}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default Accordion;