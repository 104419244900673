import React from "react";

const BlogOverviewItem = ({index, title, text, image}) => {
  const content = `<span class="blog-overview__title blog-overview__title--limited text-title-s mb-xs">${title}</span>${text}`;
  return (
    <li key={index} className="blog-overview__item">
      <section>
          <span className="bg-brand-secondary blog-overview__media aspect-ratio aspect-ratio--4-3 media-block--center">
              <img data-rs-carousel-image=""
                   src={image}
                   alt="" />
          </span>
        <div className="blog-overview__content body-copy" dangerouslySetInnerHTML={{__html: content}} />
      </section>
    </li>
  )
}

export default BlogOverviewItem;