import React, {useContext, useState} from 'react';
import {injectIntl} from "react-intl";
import ListItem from "@Components/ListItem";
import {DefaultDsarValues} from "@Components/PersonalDataParagraph";

const PersonalDataInfo = ({intl, dsarSettings}) => {
    const dsarStatus = (dsarSettings !== undefined && dsarSettings.status !== undefined) ? dsarSettings.status  : 0;

    // variables from CMS side VS i18n files
    const dsarinfoTitle = (dsarSettings !== undefined && dsarSettings['dsar_personal_paragraph_title'] !== undefined
        && dsarSettings['dsar_personal_paragraph_title']) ?
        dsarSettings['dsar_personal_paragraph_title'] :
        DefaultDsarValues['PersonalInformation.PersonalData.Title'];
    const dsarinfoText = (dsarSettings !== undefined && dsarSettings['dsar_personal_paragraph_text'] !== undefined
        && dsarSettings['dsar_personal_paragraph_text']['value']) ?
        dsarSettings['dsar_personal_paragraph_text']['value'] :
        DefaultDsarValues['PersonalInformation.PersonalData.Text'];


    const RequestDataInfoParagraph = () => {
        const htmltext = dsarinfoText;
        return (
            <div dangerouslySetInnerHTML={{__html: htmltext}} />
        )
    }

    return (
        <>
            {dsarStatus == 1 &&
                <ListItem title={dsarinfoTitle}>
                    <div id="access-to-your-personal-data-body" className="my-environment-item__content text--alternative mt-xs l:mt-s">
                        <RequestDataInfoParagraph/>
                    </div>
                </ListItem>
            }
        </>
    );
};

export default injectIntl(PersonalDataInfo);
