import React, {useEffect, useRef} from "react";
import Icon from "@Components/Icon";
import { injectIntl } from "react-intl";

const Modal = ({ intl, title, children, onClose, footer, footerDivider = true, footerDividerOnly = false, modalOverflow = true }) => {
  const modalRef = useRef();

  useEffect(() => {
    const { Modal: ModalJS } = require("@ffw/randstad-local-orbit/js/components/modal")
    const ModalJSInit = new ModalJS(modalRef.current);
    ModalJSInit.openModal(false);

    let timer;

		const closingModal = () => {
			timer = setTimeout(() => {
				onClose();
			}, 350);
		};

    // Handle X button event.
    modalRef.current.addEventListener('modal-close', closingModal);

    return () => {
      ModalJSInit.closeModal(false);
      modalRef.current && modalRef.current.removeEventListener('modal-close', closingModal);
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <div className="modal" ref={modalRef}>
        <div className={`modal__dialog ${process.env.REACT_APP_COUNTRY === "sph" ? "bg-brand--light-grey" : "bg-variant-brand-tertiary"}`} role="dialog" aria-modal="true" aria-labelledby="#" data-rs-modal-dialog="">
          <div className="modal__header" data-rs-modal-header="">
            <h3 className="modal__title">{title}</h3>
            <button className="button--icon-only modal__close" data-rs-modal-close-trigger="" aria-label={intl.formatMessage({ id: "Close" })}>
              <Icon type='close' className='icon icon--inline hidden--from-l fill-brand--blue icon--alternative'/>
              <Icon type='close-30' className='icon icon--l icon--inline hidden--until-l icon--alternative'/>
            </button>
          </div>
          <div className={`modal__main ${modalOverflow ? 'modal__main--overflow' : ''}`} data-rs-modal-main="">{children}</div>
          {footer &&
          <div className={`modal__footer ${footerDivider ? 'divider divider--top' : ''} ${footerDividerOnly ? 'divider' : ''}`} data-rs-modal-footer="">{footer}</div>}
        </div>
      </div>
    </>
  );
};

export default injectIntl(Modal);
