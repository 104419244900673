import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import SelectField from "@FormElements/SelectField";

function YearSelectField({intl, settings, ...rest}) {
  const YearField = () => {
    const yearOptions = [];
    let currentYear = new Date().getFullYear()
    yearOptions.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
    for (let year = 1970; year <= currentYear ; year ++){
        yearOptions.push({
          value: year,
          label: year,
        });
    }

    settings.options = yearOptions;
    return (
      <SelectField
        settings={settings}
        {...rest}
      />
    )
  };


  return (
    <>
      <YearField />

    </>
  )
}

export default injectIntl(YearSelectField);
