import React, {useContext, useEffect, useState} from 'react';
import {injectIntl} from "react-intl";
import ExperienceItemList from "@Components/ExperienceItemList";
import Loading from "@Components/Loading";
import Modal from "@Components/Modal";
import Button from "@FormElements/Button";
import Icon from "@Components/Icon";
import Form from 'form';
import FormContext from "formContext";
import axios from "axios";
import Logger from '@ffw/logger-lib';

function QualificationsList({intl, staticContext, match, qualifications}) {
  const {
    formData,
    setFormData,
    handleErrors,
    additionalData,
    currentStep,
    getFormSchema,
    formName,
    setFormName,
    showToast,
    setToastMessage,
    setErrors,
    triggerRerender,
    editedItemKey,
    setEditedItemKey
  } = useContext(FormContext);
  const {user} = additionalData;
  const qualificationsFormName = "user-qualifications";
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  if (!user || !qualifications) {
    return <Loading/>
  }

  const handleChange = event => {
    let name = event.target.name;
    let value = event.target.value;
    const skipField = event.target.getAttribute('data-skip-empty-field');
    if (event.target.type === 'checkbox') {
      let emptyValue = event.target.getAttribute('data-empty-field-value');
      let checkboxValue = event.target.value;
      if (typeof checkboxValue === 'string' && checkboxValue === 'true') {
        checkboxValue = true;
      }
      value = event.target.checked ? checkboxValue :
        emptyValue ? emptyValue : null;
    }

    // Handle values of fields that are hidden based on "type".
    let hiddenFieldValues = [];
    if (name === 'qualificationType' && formName === qualificationsFormName) {
      if (value !== 'training') {
        hiddenFieldValues['sub_type'] = '';
      }

      if (value !== 'education') {
        hiddenFieldValues['degree'] = '';
      }
    }

    setFormData((prev) => {
      if (!prev[currentStep]) {
        prev[currentStep] = {};
      }
      prev[currentStep][name] = value;

      for (let field in hiddenFieldValues) {
        if (hiddenFieldValues.hasOwnProperty(field) && prev[currentStep].hasOwnProperty(field)) {
          prev[currentStep][field] = hiddenFieldValues[field];
        }
      }

      // In some cases we do not want empty values, i.e. some checkboxes.
      if (!value && skipField) {
        delete prev[currentStep][name];
      }
      return {
        ...prev
      }
    });
  };

  const handleSubmit = event => {
    event.preventDefault();

    setLoading(true)
    let submitApiPath, method;
    const data = formData[currentStep];
    if (formName === qualificationsFormName) {
      if (editedItemKey) {
        method = "put";
        submitApiPath = `/user/qualifications/${editedItemKey}`;
      } else {
        method = "post";
        submitApiPath = "/user/qualifications/";
      }
    }

    if (!submitApiPath) {
      Logger.info(`Unable to update data. Missing API path for ${formName} form`, "QualificationsList");
      return null;
    }

    axios({
      method: method,
      url: `${process.env.REACT_APP_API_PREFIX}${submitApiPath}`,
      data: data
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalOpen(false);
          setFormData([]);
          setErrors();
          setEditedItemKey('');
          setLoading(false)
          setTimeout(() => {
            const message = intl.formatMessage({id: 'Profile.AccountUpdated.Toast.SuccessMessage'});
            setToastMessage(message);
            showToast();
          }, 500);
          triggerRerender();
        }
      })
      .catch(error => {
        setLoading(false)
        handleErrors(error.response.data);
      });
  };

  const onCloseModal = () => {
    setFormData([]);
    setErrors();
    setFormName('');
    setEditedItemKey('');
    setModalOpen(false)
  };

  const handleQualificationItemAdd = (event) => {
    event.preventDefault();
    setFormData([]);
    setErrors();
    setEditedItemKey('');
    setFormName(qualificationsFormName);
    setModalOpen(true);
  };

  const modalFooter = () => {
    const modalButtonSettings = {
      medium: true,
      filled: true,
      fullWidth: true,
      text: getFormSchema(formName)[currentStep].modalButton,
      loading
    }

    return <Button onClick={handleSubmit} settings={modalButtonSettings} />
  }

  const renderModal = () => {
    if (!modalOpen || !formName) {
      return '';
    }

    return (
      <Modal
        title={intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form name={formName} handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit}/>
      </Modal>
    );
  };

  return (
    <>
      <div className="my-environment-container">
        <div className="my-environment-action-header mb-s l:mb-m">
          <h2 className="my-environment-action-header__title">{intl.formatMessage({id: 'PersonalInformation.Qualifications.Qualification.Section.Title'})}</h2>

          {handleQualificationItemAdd && (
            <a href="#" className="button button--s mt-s" onClick={handleQualificationItemAdd}>
              <Icon type="add" className="icon icon--s icon--inline"/>
              {intl.formatMessage({id: 'PersonalInformation.Qualifications.Qualification.Button.Add'})}
            </a>
          )}
        </div>
        <ExperienceItemList items={qualifications} listType="qualifications" setModalOpen={setModalOpen}/>
      </div>
      {renderModal()}
    </>
  )
}

export default injectIntl(QualificationsList);
