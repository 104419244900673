import React, {useEffect} from 'react';
import {injectIntl} from "react-intl";

function Captcha({intl, settings, ...rest}) {
  const {errors} = settings || {};
  const hasErrors = (errors && errors.captchaToken);
  const renderErrors = () => {
    const renderable = [];
    if (hasErrors) {
      renderable.push(
        <div key={`errorField-captchaToken`} className="form-group__feedback">{intl.formatMessage({id: "Schema.Captcha.ValidationError"})}</div>
      );
    }
    return renderable;
  };

  useEffect(() => {
    window.captcha?.init('mrnd_captcha', window.rbdCaptchaSiteKey, window.captchaType);
  }, []);

  return (
    <div className={`form-group ${hasErrors ? 'form-group--error' : ''}`}>
      <div data-captcha-widget-id="mrnd_captcha"></div>
      {renderErrors()}
    </div>
  );

}

export default injectIntl(Captcha);
