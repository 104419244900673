import React from 'react';
import {injectIntl} from "react-intl";

function TextWithHtml({intl, settings}) {
  const {text, divClasses = "form-group", pClasses = ""} = settings || {};

  return (
    <div className={divClasses}>
      <p className={pClasses} dangerouslySetInnerHTML={{ __html: intl.formatMessage({id: text})}} />
    </div>
  )
}

export default injectIntl(TextWithHtml);
