import React from "react";
import useDateHelper from "dateHelper";
import Icon from "@Components/Icon";

function StepItem({ item, passed, lastStep, intl }) {
  const { timestamp, status, active, rejected } = item;
  const { customDateFormat } = useDateHelper();

/**
 * There are two options in which we mark something as passed:
 * 1. When the step is before the active one.
 * 2. When the active step is the last one and it is not rejected.
 */
  let markAsPassed = passed || (lastStep && (active && !rejected));

  let dateFormatted = customDateFormat(timestamp, "d. M Y", intl);
  const textRejectedClass = rejected ? 'text--negative' : '';
  const textActiveClass = active && (!rejected) ? 'text--blue' : '';
  const textPassedClass = (passed && (!active || !rejected)) || (lastStep && (active && !rejected))  ? 'text--blue' : '';

  const StepIndicator = ({ active, passed, rejected }) => {
    const passedClass = passed && (!active || !rejected) ? 'past' : '';
    const activeClass = active && !rejected ? 'current' : '';
    const rejectedClass = rejected ? 'declined' : '';

    return (
      <div className={`indicator-step__dot ${passedClass} ${activeClass} ${rejectedClass} ml-none p-none pt-xxs l:pt-none`}>
        <div className={`indicator-step__dot-inner ${passed ? 'bg-brand--blue' : ''} flex items-center justify-center`}>
          {passed && (!rejected || !active) && (
            <Icon type="check" className="icon icon--inline" />
          )}
          {active && (!rejected) && (!passed) && (
            <span className="icon fill-brand--off-white flex items-center justify-center">
              <span className="blue-dot"></span>
            </span>
          )}
          {rejected && (
            <Icon type="close-8" className="icon icon-size" />
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="flex">
      <div className="flex flex-col items-center">
        <StepIndicator active={active} passed={markAsPassed} rejected={rejected} />
        {!lastStep && (
          <div
            className={`mt-xxs l:my-xxs ${
              passed ? "border--done" : "border--todo"
            }`}
          ></div>
        )}
      </div>
      <div className="flex flex-col mb-m ml-s l:mb-l">
        <span className={`text-body-l l:text-title-xs ${textRejectedClass} ${textActiveClass} ${textPassedClass}`}>{status}</span>
        <span className="text-body-m text--alternative l:mt-xxs">
          {timestamp ? dateFormatted : ""}
        </span>
      </div>
    </div>
  );
}

export default StepItem;
