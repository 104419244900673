import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import SelectField from "@FormElements/SelectField";

function JobTypeSelectField({intl, settings, ...rest}) {
  const {additionalData: {jobTypes}} = useContext(FormContext);

  const JobTypeField = () => {
    if (!jobTypes || !Object.keys(jobTypes).length) {
      return null;
    }

    const jobTypeOptions = [];
    jobTypeOptions.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
    jobTypes.forEach((jobType) => {
      jobTypeOptions.push({
        value: jobType.id,
        label: jobType.name,
      });
    });

    settings.options = jobTypeOptions;

    return (
      <SelectField
        settings={settings}
        {...rest}
      />
    )
  };


  return (
    <>
      <JobTypeField />
    </>
  )
}

export default injectIntl(JobTypeSelectField);
