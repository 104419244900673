import { useEffect, useRef } from "react";

/**
 * @param  {} closeCb
 * Close callback from the component that toggle toast visibility.
 */

function useToast(closeCb) {
	const instanceRef = useRef(null);
	const ref = useRef(null);

	let timer;

	// Add some delay until animation is done. Strictly visual and doesn't affect the toast screen appearance time. That time is set by Orbit.
	const onCloseWithDelay = () => {
		timer = setTimeout(() => {
			closeCb();
		}, 350);
	};

	// Used for the close button.
	const onCloseButtonClick = () => {
		// Hack to close toast. The Orbit way is using closable, but it remove the element from DOM and breaks react VDOM.
		instanceRef.current && instanceRef.current.hide();
	};

	useEffect(() => {
		if (!ref.current) return;

		const {
			Toast: Orbit,
		} = require("@ffw/randstad-local-orbit/js/components/toast");

		// Init toast.
		const toastInstance = new Orbit(ref.current);
		toastInstance.show();
		toastInstance.createCloseTimeOut();

		// To keep instance accessible for clean up function.
		instanceRef.current = toastInstance;

		ref.current.addEventListener("toast-close", onCloseWithDelay);

		// Hack for close button. The Orbit way is using closable, but it remove the element from DOM and breaks react VDOM.
		ref.current.querySelector("[data-rs-closable]") &&
			ref.current
				.querySelector("[data-rs-closable]")
				.addEventListener("click", onCloseButtonClick);

		// Clean up events and timers.
		return () => {
			// Clear toast timer and prevent error. Cause Uncaught TypeError: e.parentElement is null
			instanceRef.current && instanceRef.current.destroyCloseTimeout();

			ref.current &&
				ref.current.removeEventListener("toast-close", onCloseWithDelay);

			ref.current &&
				ref.current.querySelector("[data-rs-closable]") &&
				ref.current
					.querySelector("[data-rs-closable]")
					.removeEventListener("click", onCloseButtonClick);
			clearTimeout(timer);
		};
	}, []);

	return [ref];
}

export default useToast;
