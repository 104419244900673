import React from "react";
import Logger from '@ffw/logger-lib';

function scrollTo(selector, transition = "smooth", timeout = 500) {
  setTimeout(() => {
    // IE fallback.
    try {
      if (document.querySelector(selector)) {
        document.querySelector(selector).scrollIntoView({behavior: transition});
      }
      else {
        scrollTo(selector, transition, timeout);
      }
    } catch (error) {
      Logger.warning("The browser doesn't support Smooth scroll", "scrollTo");
    }
  }, timeout);
}

export default scrollTo;
