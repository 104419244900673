import React, {useEffect} from 'react';
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon";
import GetRouteByName from 'getRouteByName';
import Link from "@Components/Link";
import dispatchRerender from 'dispatchRerender';

function PasswordField({intl, settings, ...rest}) {
  const {label, placeholder, required = true, state, validationSchema, errors, minChars, eye = true, disabled = false, hidden = false, divClasses = ''} = settings || {};
  let {name} = settings || {};
  if (rest.hasOwnProperty('name')) {
    name = rest.name;
  }

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError')));
  const id = `field--input--${name}`;

  // Depend on undefined and hidden.
  // Undefined, because we need the trigger on mount.
  // Hidden, because we need the trigger on hidden true/false.
  useEffect(() => {
    dispatchRerender();
  }, [undefined, hidden]);

  const renderValidationSchema = () => {
    const validationMarkup = [];
    if (settings && settings.hasOwnProperty('validationSchema') && Object.keys(settings.validationSchema).length > 0) {
      const possibleTypes = ['minSign', 'useChar', 'useUpper', 'useDigit'];
      possibleTypes.forEach(type => {
        if (validationSchema.hasOwnProperty(type)) {
          validationMarkup.push(
            <li data-rs-password-validator-validate={type} key={`validation-${type}`}>
              {intl.formatMessage({id: `Password.Validator.${type}`}, {number: validationSchema[type]})}
            </li>
          );
        }
      });
    }

    return (
      <div className="password-validator__validate-list" data-rs-password-validator-checklist="" hidden={(validationMarkup.length === 0)}>
        {(validationMarkup.length !== 0) && <ul>{validationMarkup}</ul>}
      </div>
    )
  };

  const renderLabel = () => {
    if (!label) {
      return <label className="form-group__label" />;
    }
    else {
      return (
        <label className="form-group__label flex-shrink-0" htmlFor={id}>
          {intl.formatMessage({id: label})}
          {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </label>
      );
    }
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  const renderForgottenPassword = () => {
    if (!settings.hasOwnProperty('forgotten')) {
      return null;
    }
    const {forgotten: {link, linkText}} = settings;
    const url = GetRouteByName(link).url;
    return (
      <Link to={url} className="form-group__action-link">
        {intl.formatMessage({id: linkText})}
      </Link>
    );
  };

  const renderEye = () => {
    if (!eye) {
      return '';
    }
    return (
      <button type="button" data-rs-password-visibility-trigger="" className="button--icon-only show-password" aria-label="show password">
        <Icon type='eye'/>
      </button>
    )
  };

  const additionalProps = {};
  if (eye) {
    additionalProps['data-rs-password-visibility'] = '';
  }

  if (minChars) {
    additionalProps['data-rs-password-validator-min-chars'] = minChars;
  }

  if (hidden) {
    return '';
  }

  return (
    <div className={`form-group form-group--password ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
      <div className="flex justify-between">
        {renderLabel()}
        {renderForgottenPassword()}
      </div>
      <div className="form-group__input form-group__input--button password-validator" data-rs-password-validator="" {...additionalProps} >
        <input
          type='password'
          name={name}
          id={id}
          required={required}
          disabled={disabled}
          placeholder={placeholder && intl.formatMessage({id: placeholder}).toLowerCase()}
          value={(state && state.hasOwnProperty(name) ? state[name] : '')}
          {...rest}
        />
        {renderEye()}
      </div>
      {renderErrors()}
      {renderValidationSchema()}
    </div>
  )

}

export default injectIntl(PasswordField);
