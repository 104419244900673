import React, {useContext, useEffect, useState} from 'react';
import {injectIntl} from "react-intl";
import { useDataLayer } from "dataLayerHelper";
import Modal from "@Components/Modal";
import ExperienceItemList from "@Components/ExperienceItemList";
import Loading from "@Components/Loading";
import Button from "@FormElements/Button";
import Icon from "@Components/Icon";
import Form from 'form';
import FormContext from "formContext";
import axios from "axios";
import Logger from '@ffw/logger-lib';

function WorkExperienceList({intl, staticContext, match, workXp}) {
  const {
    formData,
    setFormData,
    handleErrors,
    currentStep,
    getFormSchema,
    formName,
    setFormName,
    showToast,
    setToastMessage,
    setErrors,
    triggerRerender,
    handleChange,
    editedItemKey,
    setEditedItemKey
  } = useContext(FormContext);
  const workExperienceFormName = 'user-work-xp';
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);


  if (!workXp) {
    return <Loading/>
  }

  const handleWorkXPSubmit = (e) => {
    e.preventDefault();

    setLoading(true)
    let submitApiPath, method;
    const data = formData[currentStep];
    if (formName === workExperienceFormName) {
      if (editedItemKey) {
        method = "put";
        submitApiPath = `/user/work-xp/${editedItemKey}`;
      } else {
        method = "post";
        submitApiPath = "/user/work-xp/";
      }
    }

    if (!submitApiPath) {
      Logger.info(`Unable to update data. Missing API path for ${formName} form`, "WorkExperienceList/de");
      return null;
    }

    axios({
      method: method,
      url: `${process.env.REACT_APP_API_PREFIX}${submitApiPath}`,
      data: data
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalOpen(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          setLoading(false)
          setTimeout(() => {
            const message = intl.formatMessage({id: 'Profile.AccountUpdated.Toast.SuccessMessage'});
            setToastMessage(message);
            showToast();
          }, 500);
          triggerRerender();
        }
      })
      .catch(error => {
        setLoading(false)
        handleErrors(error.response.data);
      });
  };

  const onCloseModal = () => {
    setFormData([]);
    setErrors();
    setFormName('');
    setEditedItemKey('');
    setModalOpen(false)
  };

  const handleWorkExpAdd = (event) => {
    event.preventDefault();
    setFormData([]);
    setEditedItemKey('');
    setFormName(workExperienceFormName);
    setModalOpen(true);
  };

  const modalFooter = () => {
    const modalButtonSettings = {
      medium: true,
      filled: true,
      fullWidth: true,
      text: getFormSchema(formName)[currentStep].modalButton,
      loading
    }

    return <Button onClick={handleWorkXPSubmit} settings={modalButtonSettings} />
  }

  const renderModal = () => {
    if (!modalOpen || !formName) {
      return '';
    }

    return (
      <Modal
        title={intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form name={formName} handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleWorkXPSubmit}/>
      </Modal>
    );
  };

  return (
    <>
      <div className="my-environment-container">
        <div className="my-environment-action-header mb-s l:mb-m">
          <h2 className="my-environment-action-header__title">{intl.formatMessage({id: 'PersonalInformation.Experience.Section.Title'})}</h2>

          {handleWorkExpAdd && (
            <a href="#" className="button button--s mt-s" onClick={handleWorkExpAdd}>
              <Icon type="add" className="icon icon--s icon--inline"/>
              {intl.formatMessage({id: 'PersonalInformation.Experience.Button.Add'})}
            </a>
          )}
        </div>
        <ExperienceItemList items={workXp} setModalOpen={setModalOpen}/>
      </div>
      {renderModal()}
    </>
  )
}

export default injectIntl(WorkExperienceList);
