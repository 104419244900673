import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";

function UserImageBlock({intl, settings}) {
  const {text} = settings || {};
  const {additionalData} = useContext(FormContext);
  const {user} = additionalData;

  const renderProfileImage = () => {
    if (!user) {
      return '';
    }

    if (user.photo && user.photo.url) {
      return (
        <div className="account-info__photo">
          <img src={user.photo.url} />
        </div>
      );
    }
    else {
      const initials = user.first_name && user.last_name ? (user.first_name[0] + user.last_name[0]).toUpperCase() : '';
      return (
        <div className="account-info__initials account-info__initials--white">{initials}</div>
      );
    }
  };

  return (
    <div className="account-info account-info--medium">
      <div className="account-info__block-left">
        {renderProfileImage()}
      </div>
      <div className="account-info__block">
        <div className="account-info__text text--alternative">{intl.formatMessage({id: text})}</div>
      </div>
    </div>
  )
}

export default injectIntl(UserImageBlock);
