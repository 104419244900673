import React, {Fragment} from "react";
import {injectIntl} from "react-intl";

function JobAlertActiveFilters({intl, settings}) {
  const {state} = settings || {};
  const { searchFilters = {} } = state || {};
  const distanceUnit = process.env.REACT_APP_DISTANCE_UNIT || "km";
  const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL || "€";
  const count = Object.values(searchFilters).length;

  return (<div className="job-alerts-filters">
      {count > 0 ? <div>{intl.formatMessage({id: 'JobAlerts.ActiveFilters.Label'})}</div> : <div>{intl.formatMessage({id: 'JobAlerts.NoActiveFilters.Label'})}</div>}

      {Object.keys(searchFilters).map((filterName, i) => (
        <Fragment key={i}>
          <br/>
          <div className="text--alternative">{intl.formatMessage({id: `JobAlerts.Filter.Label.${filterName}`})}</div>
          <ul>
            {!Array.isArray(searchFilters[filterName]) ? (
              <li>
                {filterName === "hourSalary" ? currencySymbol : ""}
                {filterName === "daySalary" ? currencySymbol : ""}
                {filterName === "monthSalary" ? currencySymbol : ""}
                {filterName === "yearSalary" ? currencySymbol : ""}
                {searchFilters[filterName].replace("-", " ")}
                {filterName === "distance" ? distanceUnit : ""}
              </li>
            ) : (
              searchFilters[filterName].map((item, i) => <li
                key={i}>{item.replace("-", " ")}</li>)
            )}
          </ul>
        </Fragment>
      ))}
    </div>
  );
}

export default injectIntl(JobAlertActiveFilters);
