import React, {useEffect, useRef} from 'react';
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon";

function DatePicker({intl, settings, onChange, ...rest}) {
  const ref = useRef();
  const {locale} = intl;
  const {
    label,
    placeholder,
    hidden = false,
    required = true,
    disabled = false,
    defaultDate = "",
    altFormat = "Y-m-d",
    dateFormat = "Y-m-d",
    state,
    errors,
    divClasses = '',
    limitYears = null
  } = settings || {};
  let {name} = settings || {};
  if (rest.hasOwnProperty('name')) {
    name = rest.name;
  }

  useEffect(() => {
    if (!ref.current) return;

    const { DatePicker } = require("@ffw/randstad-local-orbit/js/components/date-picker")
    new DatePicker(ref.current);

    window.addEventListener('onFlatDatePickerChange', () => {
      if (!ref.current) return;

      const target = ref.current.querySelector('input.input');
      target.name = name;
      onChange({
        target: target
      })
    })
  }, [hidden]);

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError')));

  const id = `field--datepicker--${name}`;

  let limitDateProp = {};
  if (settings.onlyPastDates) {
    const today = new Date();
    const todayString = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;
    limitDateProp = {
      "data-rs-datepicker-max-date": todayString
    }
  }

  if (settings.limitYears) {
    const today = new Date();
    today.setFullYear(today.getFullYear() - settings.limitYears);
    let yearsFromToday = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;
    if (dateFormat === 'd-m-Y') {
      yearsFromToday = `${today.getDate()}-${today.getMonth()+1}-${today.getFullYear()}`;
    }
    limitDateProp = {
      "data-rs-datepicker-max-date": yearsFromToday
    };
  }

  const fieldProps = {
    type: "text",
    "data-rs-datepicker-input": "",
    "data-rs-datepicker-default-date": defaultDate,
    "data-rs-datepicker-language": locale,
    "data-rs-datepicker-alt-format": altFormat,
    "data-rs-datepicker-date-format": dateFormat,
    "data-rs-datepicker-clear": intl.formatMessage({id: "Register.Form.DatePickerClear", defaultMessage: "clear"}),
    name: name,
    ...limitDateProp
  };

  const renderLabel = () => {
    if (!label) {
      return <label className="form-group__label" />;
    }
    else {
      return (
        <label className="form-group__label" htmlFor={id}>
          {intl.formatMessage({id: label})}
          {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </label>
      );
    }
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  if (hidden) {
    return '';
  }

  return (
    <div className={`form-group form-group--datepicker ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
      {renderLabel()}
      <div className="form-group__input ">
        <div ref={ref} data-rs-datepicker="">
          <button className="button--clean" type="button" tabIndex="-1"/>
          <input
            id={id}
            placeholder={placeholder && intl.formatMessage({id: placeholder})}
            className="flatpickr"
            disabled={disabled}
            required={required}
            defaultValue={(state && state.hasOwnProperty(name) ? state[name] : '')}
            data-rs-datepicker-language={locale}
            {...fieldProps}
            {...rest}
          />
          <span className="button--icon-only button--form-group-style" role="button" aria-label="">
            <Icon type='calendar'/>
          </span>
        </div>
      </div>
      {renderErrors()}
    </div>
  );

}

export default injectIntl(DatePicker);
