import React from 'react';
import { useIntl } from 'react-intl';

const useDateHelper = () => {
  let intl = useIntl();

  // Allow creating dates from dateString and timestamp.
  const createNewDate = (date) => {
    if (date && typeof date.getMonth === 'function') {
      return date;
    }
    return isNaN(date) ? new Date(date) : new Date(date * 1000);
  }
  // Format days of date in north american style, i.e: January 18th, 2021
  const nth = function(d) {
    if (d > 3 && d < 21) return 'th';
    switch (d % 10) {
      case 1:  return "st";
      case 2:  return "nd";
      case 3:  return "rd";
      default: return "th";
    }
  }

  /**
   * @return {string}
   */
  const customDateFormat = (dateString = '', format = 'd M Y', customIntl) => {
    if (customIntl) {
      intl = customIntl;
    }
    const date = createNewDate(dateString);
    let formattedString = '';
    switch (format) {
      case "y.m.d":
        formattedString = `${date.getFullYear()}\.${`${date.getMonth() + 1}`.padStart(2, 0)}\.${`${date.getDate()}`.padStart(2, 0)}`;
        break;
      case "d M Y":
        formattedString = `${date.getDate()} ${intl.formatMessage({id: `Date.Month${date.getMonth()}`})} ${date.getFullYear()}`;
        break;
      case "j/m/Y":
        formattedString = `${date.getDate()}/${`${date.getMonth() + 1}`.padStart(2, 0)}/${date.getFullYear()}`;
        break;
      case "d. M Y":
        formattedString = `${date.getDate()}. ${intl.formatMessage({id: `Date.Month${date.getMonth()}`})} ${date.getFullYear()}`;
        break;
      case "northAmericanDate":
        let dayFormatted = date.getDate();
        formattedString = `${intl.formatMessage({id: `Date.Month${date.getMonth()}`})} ${dayFormatted}${nth(dayFormatted)}, ${date.getFullYear()}`;
        break;
      default:
        formattedString = `${date.getDate()} ${intl.formatMessage({id: `Date.Month${date.getMonth()}`})} ${date.getFullYear()}`;
        break;
    }
    return (process.env.REACT_APP_USE_LOWER_CASE === 'true') ? formattedString.toLowerCase() : formattedString;
  }

  // Check if a specific date has expired.
  const dateHasExpired = (dateInfo, expirationDate = false) => {
    if (expirationDate) {
      return (createNewDate(dateInfo) > createNewDate(expirationDate));
    }
    const tomorrow = new Date();
    tomorrow.setHours(0);
    tomorrow.setMinutes(0);
    tomorrow.setSeconds(0);
    tomorrow.setMilliseconds(0);
    const expiryDate = createNewDate(dateInfo);
    expiryDate.setHours(0);
    expiryDate.setMinutes(0);
    expiryDate.setSeconds(0);
    expiryDate.setMilliseconds(1);
    return (expiryDate < tomorrow);
  }

  // Calculate days difference between two dates
  const toDays = (startDate, endDate) => {
    let firstDate = createNewDate(startDate);
    let secondDate = createNewDate(endDate);

    let timeDiff = Math.abs(secondDate.getTime() - firstDate.getTime());
    return Math.round(timeDiff / (1000 * 3600 * 24));
  }

  return { customDateFormat, toDays, dateHasExpired, createNewDate }
}

export default useDateHelper;
