import React from 'react';
import GetRouteByName from 'getRouteByName';
import Logger from '@ffw/logger-lib';

const useJobCardHelper = () => {
  const sanitizePathParam = (param) => {
    // Possibly different for every country. Possibly transliterate needed.
    // For PT we are doing what we did for UK, and then in addition removing accents from characters.
    return String(param)
      .trim()
      .toLowerCase()
      .replace(/[.,!?/&#:%()]/g, "")
      .replace(/[ -]+/g, "-")
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
  }

  function buildJobUrl(jobTitle, jobCity, DovaJobId) {
    const jobsUrl = GetRouteByName('jobs').url;
    let title = jobTitle
      .replace(/[/&,()–#]/g, "")
      .replace(/[ -]+/g, "-")
      .toLowerCase();
    let city = jobCity
      .replace(/[/&,()–]/g, "")
      .replace(/[ -]+/g, "-")
      .toLowerCase();
    return `${jobsUrl}${title}_${city}_${DovaJobId}/`;
  }


  /**
   * Prepare job feed for printing.
   *
   * @param {array} jobData Raw feed from api.
   *
   * @return {array} Ready for printing fields.
   */
  const formatJobRawFeed = (jobData) => {
    let url = buildJobUrl(
      jobData.BlueXSanitized.Title.replace("/", ""),
      jobData.BlueXSanitized.City,
      jobData.BlueXJobData.JobId
    );

    let json_data = {
      jobId: jobData.BlueXJobData.JobId,
      title: jobData.BlueXJobData.Title,
      description:
        jobData.JobInformation.Description.replace(/<[^>]*>?/gm, ""),
      url: url,
      jobPostDate: jobData.JobDates.DateCreated,
      jobExpireDate: jobData.JobDates.DateExpire,
      details: {}
    };

    if (jobData.JobLocation.Region) json_data["details"]["region"] = jobData.JobLocation.Region;
    if (jobData.JobLocation.City) json_data["details"]["city"] = jobData.JobLocation.City;

    if (jobData.JobLocation.Latitude) json_data["details"]["latitude"] = jobData.JobLocation.Latitude;
    if (jobData.JobLocation.Longitude) json_data["details"]["longitude"] = jobData.JobLocation.Longitude;

    if (jobData.Salary.CompensationTypeId) json_data["details"]["compensationTypeId"] = jobData.Salary.CompensationTypeId;
    if (jobData.Salary.CurrencyId) json_data["details"]["currencyId"] = jobData.Salary.CurrencyId;
    if (jobData.Salary.Rate) json_data["details"]["salaryRate"] = jobData.Salary.Rate;
    if (jobData.Salary.SalaryMax) json_data["details"]["salaryMax"] = jobData.Salary.SalaryMax;
    if (jobData.Salary.SalaryMin) json_data["details"]["salaryMin"] = jobData.Salary.SalaryMin;
    if (jobData.JobInformation.JobTypeId) json_data["details"]["jobTypeId"] = jobData.JobInformation.JobTypeId;
    if (jobData.JobInformation.JobType) json_data["details"]["jobType"] = jobData.JobInformation.JobType;
    if (jobData.JobInformation.Hours) json_data["details"]["hours"] = jobData.JobInformation.Hours;
    if (jobData.JobIdentity.CompanyName) json_data["details"]["company"] = jobData.JobIdentity.CompanyName;

    return json_data;
  }

  /**
   * Updates the fav jobs heart counter in header.
   * This is a DOM manipulation, so use it only if DOM is accessible.
   * @param {boolean} inc Increase or decrease the counter.
   */
  const increaseCounterHeader = (inc = true) => {
    let hearted = document.getElementById("bluex-nav-favorites-counter");
    if (hearted) {
      let count = hearted.textContent;
      inc ? count++ : count--;
      hearted.textContent = count;
    }
  }

  /**
   * Calculate current user distance with pass ones.
   *
   * @param {number|string} jobLat Job latitude.
   * @param {number|string} jobLon Job longitude.
   * @param {bool|string} unit Unit used for th calculation. By default
   *   calculate in miles. Available option:
   * - K - calculate in km.
   *
   * @return {number} Calculate distance.
   */
   const getDistance = async (jobLat = "", jobLon = "", unit = false) => {
    if (jobLat === "" || jobLon === "") {
      return false;
    }

    if (navigator.permissions !== undefined) {
      const { state } = await navigator.permissions.query({ name: "geolocation" });

      if (state === "granted" || state === "prompt") {
        let position = false;

        if (navigator.geolocation) {
          try {
            position = await getPosition();
          } catch (err) {
            Logger.error(err.message, "jobCardHelper/getDistance");
          }

          if (!position) {
            return false;
          }

          return calculateDistance(position, unit);
        }
      }
    }

    function getPosition() {
      return new Promise((res, rej) => navigator.geolocation.getCurrentPosition(res, rej));
    }

    function calculateDistance(position) {
      const lat1 = position.coords.latitude;
      const lon1 = position.coords.longitude;
      const lat2 = jobLat;
      const lon2 = jobLon;

      if (lat1 === lat2 && lon1 === lon2) {
        return false;
      }
      else {
        let radlat1 = (Math.PI * lat1) / 180;
        let radlat2 = (Math.PI * lat2) / 180;
        let theta = lon1 - lon2;
        let radtheta = (Math.PI * theta) / 180;
        let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit === "K") {
          dist = dist * 1.609344;
        }

        // By default calculate in miles.
        return dist;
      }
    }

    return false;
  };

  return { sanitizePathParam, formatJobRawFeed, increaseCounterHeader, getDistance }
}

export default useJobCardHelper;
