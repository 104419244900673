import React from "react";

const BlogOverview = ({title, subtitle, children}) => {
  subtitle = subtitle.replace(/<[^>]+>/g, '');

  return (
    <div className="block blog-overview blog-overview--grid blog-overview--carousel-on-s bg-variant-white">
      <div className="block__wrapper block__wrapper--stacked wrapper">
        <div className="block__header block__header--l block__header--split">
          <h2 className="block__title">{title}</h2>
          <div className="block__description block__description--s block__description--dark">
            {subtitle}
          </div>
        </div>
        <div className="block__content">
          <div className="blog-overview__wrapper " data-rs-carousel-wrapper="">
            <ul className="blog-overview__list" data-rs-carousel="blog-overview--grid">
              {children}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BlogOverview;