import React, {useContext, useEffect, useState} from 'react';
import {injectIntl} from "react-intl";
import SkillItemList from "@Components/SkillItemList";
import Loading from "@Components/Loading";
import Modal from "@Components/Modal";
import Button from "@FormElements/Button";
import Icon from "@Components/Icon";
import Form from 'form';
import FormContext from "formContext";
import axios from "axios";
import Logger from '@ffw/logger-lib';

function SkillsList({intl, staticContext, match, skills = null, languages = null}) {
  const {
    formData,
    setFormData,
    handleErrors,
    currentStep,
    getFormSchema,
    showToast,
    setToastMessage,
    setErrors,
    additionalData,
    formName,
    setFormName,
    modalOpen,
    setModalOpen,
    triggerRerender,
    handleChange,
    editedItemKey,
    setEditedItemKey
  } = useContext(FormContext);
  const {user} = additionalData;
  const skillsFormName = "user-skills", languagesFormName = "user-languages";
  const [loading, setLoading] = useState(false);

  if (!user) {
    return <Loading/>
  }

  const modalFooter = () => {
    const modalButtonSettings = {
      medium: true,
      filled: true,
      fullWidth: true,
      text: getFormSchema(formName)[currentStep].modalButton,
      loading
    }

    return <Button onClick={handleSubmit} settings={modalButtonSettings} />
  }

  const renderModal = () => {
    if (!modalOpen || !formName) {
      return '';
    }

    return (
      <Modal
        title={intl.formatMessage({id: getFormSchema(formName)[currentStep].modalTitle})}
        onClose={onCloseModal}
        footer={modalFooter()}
        footerDivider={true}
      >
        <Form name={formName} handleChange={handleChange} state={formData[currentStep]} handleSubmit={handleSubmit}/>
      </Modal>
    );
  };

  const onCloseModal = () => {
    setFormData([]);
    setFormName('');
    setEditedItemKey('');
    setErrors();
    setModalOpen(false)
  };

  const handleSubmit = event => {
    event.preventDefault();

    setLoading(true)
    let submitApiPath, method;
    const data = formData[currentStep];
    if (formName === skillsFormName) {
      if (editedItemKey) {
        method = "put";
        submitApiPath = `/user/skills/${editedItemKey}`;
      } else {
        method = "post";
        submitApiPath = "/user/skills/";
      }
    } else if (formName === languagesFormName) {
      method = "post";
      submitApiPath = "/user/languages/";
    }

    if (!submitApiPath) {
      Logger.info(`Unable to update data. Missing API path for ${formName} form`, "SkillsList");
      return null;
    }

    axios({
      method: method,
      url: `${process.env.REACT_APP_API_PREFIX}${submitApiPath}`,
      data: data
    })
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          setModalOpen(false);
          setFormData([]);
          setEditedItemKey('');
          setErrors();
          setLoading(false)
          setTimeout(() => {
            const message = intl.formatMessage({id: 'Profile.AccountUpdated.Toast.SuccessMessage'});
            setToastMessage(message);
            showToast();
          }, 500);
          triggerRerender();
        }
      })
      .catch(error => {
        setLoading(false)
        handleErrors(error.response.data);
      });
  };

  const handleSkillsAdd = (event) => {
    event.preventDefault();
    setFormData([]);
    setEditedItemKey('');
    setFormName(skillsFormName);
    setModalOpen(true);
  };

  const handleLanguageAdd = (event) => {
    event.preventDefault();
    setFormData([]);
    setEditedItemKey('');
    setFormName(languagesFormName);
    setModalOpen(true);
  }

  return (
    <>
      {
        skills &&
        <div className="my-environment-container">
          <div className="my-environment-action-header mb-s l:mb-m">
            <h2 className="my-environment-action-header__title">{intl.formatMessage({id: 'PersonalInformation.Skills.List.Title'})}</h2>
            <a href="#" className="button button--s mt-s" onClick={handleSkillsAdd}>
              <Icon type="add" className="icon icon--s icon--inline"/>
              {intl.formatMessage({id: 'Profile.Preferences.Skills.Button.Add'})}
            </a>
          </div>

          <SkillItemList skills={skills} handleSkillsAdd={handleSkillsAdd} skillsAddButtonLabel={'Profile.Preferences.Skills.Button.Add'}/>
        </div>
      }
      {
        languages &&
        <div className="my-environment-container">
          <div className="my-environment-action-header mb-s l:mb-m">
            <h2 className="my-environment-action-header__title">{intl.formatMessage({id: 'PersonalInformation.Languages.List.Title'})}</h2>
            <a href="#" className="button button--s mt-s" onClick={handleLanguageAdd}>
              <Icon type="add" className="icon icon--s icon--inline"/>
              {intl.formatMessage({id: 'Profile.Preferences.Languages.Button.Add'})}
            </a>
          </div>

          <SkillItemList skills={languages} handleSkillsAdd={handleLanguageAdd} skillsAddButtonLabel={'PersonalInformation.Languages.Button.Add'}/>
        </div>
      }
      <div>
        {renderModal()}
      </div>
    </>
  )
}

export default injectIntl(SkillsList);
