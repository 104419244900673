import React, {useContext, useState} from 'react';
import {injectIntl} from "react-intl";
import Button from "@FormElements/Button";
import FormContext from "formContext";
import NoticeInPage from "@Components/NoticeInPage";
import ListItem from "@Components/ListItem";

const DefaultDsarValues = {
        "AccountSettings.PersonalData.Text": "<p>By clicking on the button below, you can request Randstad about the personal data we hold about you such as:<ul><li>● details you provided when filling in a form, applying for a job, or registering  your account</li><li>● aggregated statistics on product or service use on the website and Randstad NL app.</li></ul></br>What you can expect after submitting the request:<ol><li>1. You will receive a confirmation email</li><li>2. A member of staff will get in touch with you to follow up and process your request.</li></ol></br>More information about how Randstad processes your personal data is available on our <a id=\"privacy-policy-link\" href=\"/your-privacy-explained\">Privacy Notice</a>.</p>",
        "AccountSettings.PersonalData.Title": "request access to your personal data",
        "AccountSettings.PersonalData.Button.Text": "request your personal data",
        "AccountSettings.PersonalData.Modal.Title": "request your personal data?",
        "AccountSettings.PersonalData.Modal.Text": "Click on the button below to request access to your personal data.",
        "AccountSettings.PersonalData.Modal.Button.Text": "yes, request",
        "AccountSettings.PersonalData.SuccessMessage": "Your request to access your personal data has been submitted. Check your email for more information.",
        "AccountSettings.PersonalData.ErrorMessage": "Something went wrong. Please try again later.",
        "AccountSettings.PersonalData.ConfirmationEmail.Subject": "Re: Request reference no. [###]",
        "AccountSettings.PersonalData.ConfirmationEmail.Body": "<p>Dear [name],</p><p>Re: Request reference no. [###]</p><p>Thank you for your request.</p><p>We have received your request to access your personal data on [date] and will be dealt with in accordance with [insert relevant legislation].</p><p>A member of staff will contact you as soon as possible, but no later than [insert deadline] to follow up on your request.</p><p>[Optional paragraphs could address any need to obtain identification proof, clarify the request, or address fees].</p><p>For more information on how Randstad handles your personal data, please, see our privacy notice (add link).</p><p>If you didn't initiate this request or if you have any queries about your request, contact XXXX@randstad.com.</p><p>Please use the reference number above in any future correspondence.</p><p>Sincerely,<br />[signature]</p>",
        "AccountSettings.PersonalData.ServiceEmail.Subject": "Action required - You have received a data subject access request - [###]",
        "AccountSettings.PersonalData.ServiceEmail.Body": "<p>Dear colleague,</p><p>You have just received a data subject access request from [IAM] on [date].</p><p>Please, process the request according to the current processes and procedures in place.</p><p>Kind regards,<br />[###]</p>",
        "PersonalInformation.PersonalData.Title": "access to your personal data",
        "PersonalInformation.PersonalData.Text": "<p>You have the right to access the personal data Randstad holds about you. In case you wish to exercise such right, you can do that in Settings, under the <a class='dsar-paragraph-link' href='/my-randstad/my-account#dsar'>request access to your personal data</a> section.</p>",
    };

const PersonalDataParagraph = ({intl, requestOff, dsarSettings}) => {
    const {setModalOpen, setFormName, buttonLoading, setButtonLoading} = useContext(FormContext);
    const dsarStatus = (dsarSettings !== undefined && dsarSettings.status !== undefined) ? dsarSettings.status  : 0;
    
    // variables from CMS side VS i18n files
    const successMessage = (dsarSettings !== undefined && dsarSettings['confirmation_message_text'] !== undefined
        && dsarSettings['confirmation_message_text']['value']) ?
        dsarSettings['confirmation_message_text']['value'] :
        DefaultDsarValues['AccountSettings.PersonalData.SuccessMessage'];
    const errorMessage = (dsarSettings !== undefined && dsarSettings['error_message_text'] !== undefined
        && dsarSettings['error_message_text']['value']) ?
        dsarSettings['error_message_text']['value'] :
        DefaultDsarValues['AccountSettings.PersonalData.ErrorMessage'];
    const dsarTitle = (dsarSettings !== undefined && dsarSettings['dsar_title'] !== undefined
        && dsarSettings['dsar_title']) ?
        dsarSettings['dsar_title'] :
        DefaultDsarValues['AccountSettings.PersonalData.Title'];
    const dsarText = (dsarSettings !== undefined && dsarSettings['dsar_text'] !== undefined
        && dsarSettings['dsar_text']['value']) ?
        dsarSettings['dsar_text']['value'] :
        DefaultDsarValues['AccountSettings.PersonalData.Text'];
    const dsarButton = (dsarSettings !== undefined && dsarSettings['dsar_button_label'] !== undefined
        && dsarSettings['dsar_button_label']) ?
        dsarSettings['dsar_button_label'] :
        DefaultDsarValues['AccountSettings.PersonalData.Button.Text'];

    const handlePersonalDataButtonClick = () => {
        setFormName('personal-data');
        setModalOpen(true);
        setButtonLoading(true);
    };

    const RequestDataParagraph = () => {
        const htmltext = dsarText;
        return (
            <div dangerouslySetInnerHTML={{__html: htmltext}} />
        )
    }

    const noticeSettings = {
        type: 'positive',
        message: successMessage ? successMessage : ' '
    }
    const errorSettings = {
        type: 'negative',
        message: errorMessage ? errorMessage : ' '
    }

    return (
        <>
            {dsarStatus == 1 &&
                <ListItem title={dsarTitle} divider="true">
                    <div className="my-environment-item__content mt-xs l:mt-s personal-data">
                        <RequestDataParagraph/>
                        <div className="form-group form-group--selection-control">
                            {requestOff == 1 &&
                                <NoticeInPage settings={noticeSettings}/>
                            }
                            {requestOff == -1 &&
                                <NoticeInPage settings={errorSettings}/>
                            }
                            <Button
                                onClick={handlePersonalDataButtonClick}
                                className="button button--m personal-data-button"
                                settings={{
                                    text: dsarButton,
                                    withWrapperDiv: false,
                                    disabled: requestOff,
                                    loading: buttonLoading,
                                    staticText: false
                                }}/>
                        </div>
                    </div>
                </ListItem>
            }
        </>
    );
};

export {
    PersonalDataParagraph,
    DefaultDsarValues
};
