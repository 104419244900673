import React from 'react';
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon";

function AccordionWithText({intl, settings}) {
  const {label, text, divClasses = "form-group", textClasses = ""} = settings || {};

  return (
    <div className={`${divClasses} accordion-text`}>
      <div className="collapsible__trigger" data-rs-collapsible="" role="button" aria-expanded="false">
        <h3 className="link-list__link">
          <Icon type='chevron-down' className="icon icon--inline"/>
          <span>{intl.formatMessage({id: label})}</span>
        </h3>

        <div className={`${textClasses} collapsible__content`} data-rs-collapsible-content="" aria-hidden="true">
          <div className="collapsible__content--wrapper body-copy"
               dangerouslySetInnerHTML={{__html: intl.formatMessage({id: text})}}>
          </div>
        </div>
      </div>
   </div>
  )
}

export default injectIntl(AccordionWithText);