import React from 'react';

function Spacer({settings}) {
  const {hidden = false} = settings || {};
  if (hidden) {
    return '';
  }
  return (
    <hr style={{
      width: 'calc((38 / 43) * 100%)',
      margin: '50px 0',
      border: 'none',
      height: '1px',
      backgroundColor: '#d7d7d7',
    }}/>
  )
}

export default Spacer;
