import React, {Fragment, useContext, useEffect} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import Spacer from "@Components/Spacer";
import TextWithLink from "@Components/TextWithLink";
import TextWithHtml from "@Components/TextWithHtml";
import InputField from "@FormElements/InputField";
import TextArea from "@FormElements/TextArea";
import DatePicker from "@FormElements/DatePicker";
import JobAlertActiveFilters from "@Components/JobAlertActiveFilters";
import PasswordField from "@FormElements/PasswordField";
import Checkbox from "@FormElements/Checkbox";
import Button from "@FormElements/Button";
import UploadField from "@FormElements/UploadField";
import RadioButtons from "@FormElements/RadioButtons";
import SelectField from "@FormElements/SelectField";
import AutoSuggestField from "@FormElements/AutoSuggestField";
import AutoSuggestWithTags from "@FormElements/AutoSuggestWithTags";
import SpecialismSelectFields from "@FormElements/SpecialismSelectFields";
import LanguageLevelSelectFields from "@FormElements/LanguageLevelSelectFields";
import RegionSelectField from "@FormElements/RegionSelectField";
import JobTypeSelectField from "@FormElements/JobTypeSelectField";
import BullhornListDataSelectField from "@FormElements/BullhornListDataSelectField";
import CountrySelectField from "@FormElements/CountrySelectField";
import SkillRadioButtons from "@Components/SkillRadioButtons";
import IndustryPreferences from "@FormElements/IndustryPreferences";
import WorkplaceAutosuggest from "@FormElements/WorkplaceAutosuggest";
import Error from "@Components/Error";
import Icon from "@Components/Icon";
import ResetPasswordEmailText from "@Components/ResetPasswordEmailText";
import CountryAutoSuggest from "@FormElements/CountryAutoSuggest";
import UserImageBlock from "@Components/UserImageBlock";
import SalarySelectField from "@FormElements/SalarySelectField";
import NoticePeriodField from "@FormElements/NoticePeriodField";
import StackableCheckboxes from "@FormElements/StackableCheckboxes";
import SpecialismAutoSuggestField from "@FormElements/SpecialismAutoSuggestField";
import PermSalarySelectField from "@FormElements/PermSalarySelectField";
import TempSalarySelectField from "@FormElements/TempSalarySelectField";
import JobTitleAutoSuggestField from "@FormElements/JobTitleAutoSuggestField";
import StateAutosuggestField from "@FormElements/StateAutosuggestField";
import BranchSelectField from "@FormElements/BranchSelectField";
import AccordionWithText from "@Components/AccordionWithText";
import NoticeInPage from "@Components/NoticeInPage";
import ButtonWithLink from "@Components/ButtonWithLink";
import PostalAutosuggestField from "@FormElements/PostalAutosuggestField";
import MultipleCheckboxes from "@FormElements/MultipleCheckboxes";
import Captcha from "@FormElements/Captcha";
import YearSelectField from "@FormElements/YearSelectField";
import CheckboxWithToast from "@FormElements/CheckboxWithToast";
import MultipleLanguages from "@Components/MultipleLanguages";
import Logger from '@ffw/logger-lib';
import JobTypeSalaryField from "@FormElements/JobTypeSalaryField";
import JobTypeAutoSuggestField from "@FormElements/JobTypeAutoSuggestField";

const supportedComponents = {
  InputField,
  PasswordField,
  Checkbox,
  Button,
  Spacer,
  TextWithLink,
  UploadField,
  RadioButtons,
  SelectField,
  SpecialismSelectFields,
  LanguageLevelSelectFields,
  RegionSelectField,
  BullhornListDataSelectField,
  JobTypeSelectField,
  CountrySelectField,
  SkillRadioButtons,
  TextArea,
  DatePicker,
  AutoSuggestField,
  AutoSuggestWithTags,
  IndustryPreferences,
  WorkplaceAutosuggest,
  JobAlertActiveFilters,
  ResetPasswordEmailText,
  CountryAutoSuggest,
  UserImageBlock,
  SalarySelectField,
  NoticePeriodField,
  StackableCheckboxes,
  TextWithHtml,
  SpecialismAutoSuggestField,
  PermSalarySelectField,
  TempSalarySelectField,
  JobTitleAutoSuggestField,
  StateAutosuggestField,
  BranchSelectField,
  AccordionWithText,
  NoticeInPage,
  ButtonWithLink,
  PostalAutosuggestField,
  MultipleCheckboxes,
  CheckboxWithToast,
  MultipleLanguages,
  JobTypeSalaryField,
  Captcha,
  JobTypeAutoSuggestField,
  YearSelectField
};

function Form({ name, intl, handleChange, state, handleSubmit, hideGeneralErrors = false , noticeSettings, divClasses}) {
  const {getFormSchema, errors, currentStep, setCurrentStep, setFormName, renderStep, loading, formData} = useContext(FormContext);
  const schema = getFormSchema(name);
  let formSchema;
  let isModal;
  const sections = [];

  useEffect(() => {
    setFormName(name);
    setCurrentStep(0);
  }, [name]);

  if (schema[currentStep] !== undefined) {
    formSchema = schema[currentStep].data;
    isModal = schema[currentStep].isModal;
  }
  else {
    formSchema = schema[0].data;
    isModal = schema[0].isModal;
  }

  const renderComponents = () => {
    formSchema.forEach((section, sectionIndex) => {
      const sectionParts = [];
      const elementItems = [];
      const components = section.components;
      // Render the section title if available.
      if (section.hasOwnProperty('title') && section.title.length > 0) {
        sectionParts.push(
          <div className="block__header" key={`section-title-${sectionIndex}`}>
            {noticeSettings && noticeSettings.type && (
              <div className="hidden--from-l">
                <NoticeInPage settings={noticeSettings} />
                <br/>
              </div>
            )}
            <h2 className="block__title">{intl.formatMessage({id: section.title})}</h2>
          </div>
        );
      }

      // Iterate through all components in the section and push them to elementItems array.
      components.forEach((component, componentIndex) => {
        if (!supportedComponents.hasOwnProperty(component.component)) {
          Logger.warning(`The component "${component.component}" is not supported, but used in forms.config.json.`, "form");
          return;
        }
        const Component = supportedComponents[component.component];
        const settings = component.settings || {};
        settings.state = state;
        if (settings.hasOwnProperty('originStep')) {
          if (!settings.state) {
            settings.state = {};
          }
          settings.state[settings.name] = formData[settings.originStep][settings.name]
        }
        settings.errors = errors;
        settings.loading = loading;
        // Handle effects if any,
        if (component.effects) {
          component.effects.forEach(effect => {
            handleEffect(effect, settings, state);
          });
        }

        if (sectionIndex === 0 && componentIndex === 0) {
          // Check if we should render the general error message.
          if (errors && errors.hasOwnProperty('generalError') && !isModal && !hideGeneralErrors) {
            elementItems.push(
              <Error message={errors.generalError} key={`error-${new Date().getTime()}`} />
            );
          }
        }

        elementItems.push(
          <Component settings={settings} key={`section-${sectionIndex}-component-${componentIndex}`} onChange={handleChange}/>
        );
      });

      if (isModal === true) {
        sectionParts.push(
          <Fragment key={`section-${sectionIndex}-components`}>
            {elementItems}
          </Fragment>
        );
        sections.push(
          <Fragment key={`section-${sectionIndex}-components`}>
            {renderPreviousStep()}
            {sectionParts}
          </Fragment>
        );
      }
      else {
        sectionParts.push(
          <div className="block__content block__content--s block__content--align-left" key={`section-${sectionIndex}-components`}>
            {noticeSettings && noticeSettings.type && (
              <div className="hidden--until-l">
                <NoticeInPage settings={noticeSettings} />
                <br/>
              </div>
            )}
            <div className="my-randstad__content">
              {elementItems}
            </div>
          </div>
        );
        sections.push(
          <div className={`${divClasses ? divClasses : "block block--my-randstad bg-variant-white block--s"}`} key={`section-wrapper-${sectionIndex}`}>
            <div className={`block__wrapper wrapper`} style={{flexWrap: "wrap"}}>
              {renderPreviousStep()}
              {sectionParts}
            </div>
          </div>
        );
      }

    });
    return sections;
  };

  const handleEffect = (effect, settings, state) => {
    let shouldApply = false;
    const {dependentField} = effect;
    switch (effect.type) {
      case 'isEmpty':
        if (!state || !state.hasOwnProperty(dependentField) || !state[dependentField]) {
          shouldApply = true;
        }
        break;
      case 'includes':
        if (state && state.hasOwnProperty(dependentField) && effect.dependentFieldValues.includes(state[dependentField])) {
          shouldApply = true;
        }
        else if ((!state || !state.hasOwnProperty(dependentField)) && effect.trueWhenDependentFieldEmpty) {
          shouldApply = true;
        }
        break;
      case 'notIncludes':
        if (!state || (state && state.hasOwnProperty(dependentField) && !effect.dependentFieldValues.includes(state[dependentField])) || !state.hasOwnProperty(dependentField)) {
          shouldApply = true;
        }
        break;
    }

    switch (effect.effect) {
      case 'changeSetting':
        if (effect.settingValue) {
          if (shouldApply) {
            settings[effect.settingName] = effect.valueIsTranslatable ? intl.formatMessage({id: effect.settingValue}) : effect.settingValue;
          } else {
            settings[effect.settingName] = effect.valueIsTranslatable ? intl.formatMessage({id: effect.settingDefaultValue}) : effect.settingDefaultValue;
          }
        } else if (effect.settingValueMapping) {
          if (shouldApply) {
            settings[effect.settingName] = effect.valueIsTranslatable ? intl.formatMessage({id: effect.settingValueMapping[state[dependentField]]}) : effect.settingValueMapping[state[dependentField]];
          } else {
            settings[effect.settingName] = effect.valueIsTranslatable ? intl.formatMessage({id: effect.settingDefaultValue}) : effect.settingDefaultValue;
          }
        }
        break;
      case 'disabled':
        settings.disabled = shouldApply;
        break;
      case 'hidden':
        settings.hidden = shouldApply;
        break;
    }
  };

  const currentStepSchema = schema && schema[currentStep];

  const handlePrevStepClick = e => {
    e.preventDefault();

    let previousStep = 'prev';

    if (currentStepSchema && currentStepSchema.hasOwnProperty('previousStepIndex')) {
      previousStep = currentStepSchema.previousStepIndex;
    }

    renderStep(previousStep);
  };

  const renderPreviousStep = () => {
    if (!currentStepSchema || !currentStepSchema.hasOwnProperty('previousStepText')) {
      return '';
    }
    const {previousStepText} = currentStepSchema;
    return (
      <div className="back-step-button">
        <a href="#" onClick={handlePrevStepClick}>
          <Icon type='arrow-left' className="icon icon--inline fill-brand--blue"/>{intl.formatMessage({id: previousStepText})}
        </a>
      </div>
    );
  };

  return (
    <form method="POST" onSubmit={handleSubmit} noValidate name={`${name}-form`} aria-label={`${name}-form`}>
      {renderComponents()}
    </form>
  )
}

export default injectIntl(Form);
