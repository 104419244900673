import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import SelectField from "@FormElements/SelectField";

function LanguageLevelSelectFields({intl, settings, ...rest}) {
  const {state, name} = settings || {};
  const {onChange} = rest;
  const {additionalData: {languages, languageLevels}, currentStep, setFormData} = useContext(FormContext);

  const LanguageField = () => {
    if (!Array.isArray(languages) || !Array.isArray(languageLevels)) {
      return null;
    }
    const options = [];
    options.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
    languages.forEach((language) => {
      options.push({
        value: language.id,
        label: language.name,
      });
    });

    settings.options = options;
    return (
      <SelectField
        settings={settings}
        {...rest}
        onChange={handleLanguageChange}
      />
    )
  };

  const handleLanguageChange = event => {
    if (settings && settings.languageLevel && settings.languageLevel.name) {
      setFormData(prev => {
        if (!prev[currentStep]) {
          prev[currentStep] = {};
        }
        const languageLevelName = settings.languageLevel.name;
        prev[currentStep][languageLevelName] = '';
        return {
          ...prev
        }
      });
    }
    onChange(event);
  };

  const LanguageLevelField = () => {
    if (!Array.isArray(languages) || !Array.isArray(languageLevels)) {
      return null;
    }

    let selectedLanguage;
    if (state && state.hasOwnProperty(name) && state[name] && state[name].length) {
      selectedLanguage = state[name];
    }
    if (!selectedLanguage) {
      return null;
    }

    const options = [];
    options.push({ value: "", label: intl.formatMessage({id: 'SelectField.EmptyOption.Label'}) });
    languageLevels.forEach(languageLevel => {
      options.push({ value: languageLevel.id, label: languageLevel.name });
    });
    if (!options.length) {
      return '';
    }
    settings.languageLevel.options = options;
    return (
      <SelectField
        settings={{...settings, ...settings.languageLevel}}
        {...rest}
      />
    )
  };

  return (
    <>
      <LanguageField />
      <LanguageLevelField />
    </>
  )
}

export default injectIntl(LanguageLevelSelectFields);
