import React, {useRef, useState, useContext} from 'react';
import axios from 'axios';
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import formatFileSize from 'formatFileSize';
import GooglePicker from 'react-google-picker';
import DropboxChooser from 'react-dropbox-chooser'
import Icon from "@Components/Icon"
import Logger from '@ffw/logger-lib';

function UploadField({intl, settings}) {
  const {state, name, maxNumberOfFiles, allowedFileFormats, maxFileSize, useGoogleDrive, useDropbox, parseCV, errors, required=true, preloadAlreadyExistingFile = false, showUploaderWidget = true, label, isEditable = false, divClasses = '', keepInContext = false} = settings || {};
  const {currentStep, setFormData, additionalData, setAdditionalData, setErrors} = useContext(FormContext);
  let initialUploadedFiles = {};
  if (preloadAlreadyExistingFile) {
    const {user} = additionalData;

    if (user && user.photo && user.photo.filename && user.photo.size) {
      initialUploadedFiles = {
        [user.photo.filename]: {
          uploadProgress: 100,
          size: user.photo.size
        }
      };
    }

    if (name==='CV' && user && user.CV && user.CV.filename) {
      initialUploadedFiles = {
        [user.CV.filename]: {
          uploadProgress: 100,
          size: user.CV.size || 0
        }
      };
    }

    if (keepInContext && additionalData && additionalData.uploadedFiles) {
      Object.entries(additionalData.uploadedFiles).forEach(file => {
        const [key, value] = file;
          initialUploadedFiles = {
            ...initialUploadedFiles,
            [key]: {
              uploadProgress: 100,
              size: value.size || 0,
            }
          }
      });
    }
  }

  const [uploadedFiles, setUploadedFiles] = useState(initialUploadedFiles);
  const [dragInProgress, setDragInProgress] = useState(false);
  const [errorWithoutFilesPreview, setErrorWithoutFilesPreview] = useState(null);
  const [errorWithReadOnly, setErrorWithReadOnly] = useState(false);
  const [showUploaderWidgetBox, setShowUploaderWidget] = useState(showUploaderWidget);
  const [googleToken, setGoogleToken] = useState('');
  const inputRef = useRef();
  const hasErrors = errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError'));
  const clientId = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;
  const developerKey = (global || window).REACT_APP_GOOGLE_DRIVE_DEVELOPER_KEY;
  const dropboxKey = (global || window).REACT_APP_DROPBOX_KEY;

  const maxFileSizeNumber = settings.maxFileSize ? settings.maxFileSize : process.env.REACT_APP_CV_MAX_SIZE_MB;
  const cvMaxSizeInBytes = 1048576 * Number(maxFileSizeNumber);

  let fileFormatsAllowed = 'jpeg|jpg|png|gif';
  const fileFormats = settings.allowedFileFormats;
  if (fileFormats) {
    fileFormatsAllowed = fileFormats.replace(/ /g, "").replace(/,./g, "|").replace(".","");
  }
  const fileTypeRegex = new RegExp('^[^\\x00-\\x1f/]+\\.(?:' + fileFormatsAllowed + ')$', 'i');

  const isInitialFileAlreadyExists = preloadAlreadyExistingFile && Object.keys(uploadedFiles).length;
  const tooManyFilesErrorMessage = {
    title: intl.formatMessage({id: 'CVFieldMaxItems'}),
    message: intl.formatMessage({id: 'CVFieldMaxItems.Message'}, {n: maxNumberOfFiles}),
    generalMessage: intl.formatMessage({id: 'CVFieldMaxItems.GeneralMessage'}, {n: maxNumberOfFiles}),
  };
  const unsupportedFileFormatErrorMessage = intl.formatMessage({id: 'Schema.UserCv.Filename.Pattern'});
  let maxSizeErrorMessage =  {
    title: intl.formatMessage({id: 'Schema.UserCv.MaxSize.Title'}),
    message: intl.formatMessage({id: 'Schema.UserCv.MaxSize.Message'}, {n: maxFileSizeNumber}),
    generalMessage: intl.formatMessage({id: 'Schema.UserCv.MaxSize'}, {n: maxFileSizeNumber}),
  };

  const sendFileToServer = (file) => {
    axios.post(`${process.env.REACT_APP_API_PREFIX}/init-file-upload`, {
      fileName: file.name,
      contentType: file.type
    }).then(res => {
      const {token, putUrl} = res.data;
      // Create a new axios instance in order to avoid axios interceptors.
      // If we change something in the URL - the PUT request will fail with 403.
      return axios.create({ headers: { "Content-Type": file.type } }).put(putUrl, file, {
        onUploadProgress: progressEvent => {
          return setUploadedFiles(prev => ({
            ...prev,
            [file.name]: {
              uploadProgress: Math.round(progressEvent.loaded * 100 / progressEvent.total),
              size: file.size
            }
          }));
        }
      }).then(() => {
        if (currentStep !== undefined && preloadAlreadyExistingFile) {
          setAdditionalData(prev => ({
            ...prev,
            uploadedFiles: {
              [file.name]: {
                uploadProgress: 100,
                size: file.size
              }
            }
          }));
        }

        // Getting token from init-file-upload response data, as "uploadedFiles" appears to always be empty.
        // The re-rendering is the likely cause.
        if (parseCV === true) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            const base64 = fileReader.result.split(',')[1];
            axios.post('/api/my-randstad/cv-parsing', {
              fileBase64: base64,
              fileName: file.name
            }).then(response => {
              const parseResult = response.data.data;
              setFormData(prev => {
                prev[0]['first_name'] = parseResult.FirstName ? parseResult.FirstName : prev[0]['first_name'];
                prev[0]['last_name'] = parseResult.LastName ? parseResult.LastName : prev[0]['last_name'];
                prev[0]['phone'] = parseResult.MobileNumber ? parseResult.MobileNumber : prev[0]['phone'];
                prev[0]['email'] = parseResult.Email && !prev[0]['email'] ? parseResult.Email : prev[0]['email'];
                return {
                  ...prev,
                  [currentStep]: {
                    ...(prev[currentStep] || {})
                  }
                }
              });
            })
              .catch(error => {
                Logger.error(error, "FormElements/UploadField");
              })
          }
          fileReader.readAsDataURL(file);
        }
        setFormData(prev => {
          let fileData = {token: token};
          if (maxNumberOfFiles > 1) {
            // This doesn't handle multiple files, as we clobber [currentStep][name] every time.
            fileData = [{token: token}];
          }
          return {
            ...prev,
            [currentStep]: {
              ...(prev[currentStep] || {}),
              [name]: fileData,
            }
          }
        });
      });
    }).catch(() => {
      Logger.error("Error while uploading your file occurred.", "FormElements/UploadField");
    });
  };

  const setFileError = (file, message) => {
    setUploadedFiles(prev => {
      return {
        ...prev,
        [file.name]: {
          'error': message
        }
      }
    });
  };

  const renderLabel = () => {
    if (!label) {
      return;
    }
    else {
      return (
        <label className="form-group__label" htmlFor={name}>
          {intl.formatMessage({id: label})}
        </label>
      );
    }
  }

  const onChange = event => {
    clearAllErrors();

    const filesArray = Array.from(event.target.files);

    if (filesArray.length > maxNumberOfFiles) {
      setErrorWithoutFilesPreview(tooManyFilesErrorMessage);
      setErrors(prev => {
        return {
          ...prev,
          [name]: tooManyFilesErrorMessage.generalMessage
        }
      });

      setUploadedFiles({});
      setFormData(prev => {
        const data = prev[currentStep] ? {...prev[currentStep]} : {};
        delete data[name];
        return {...prev, [currentStep]: data};
      });
      return;
    }

    const currentFilesUsedCount = Object.keys(uploadedFiles).length;
    if (hasErrors) {
      setErrors(prev => {
        delete prev[name];
        return {
          ...prev
        }
      });
    }

    filesArray.forEach((file, index) => {
      let fileAlreadyAdded = false;

      Object.entries(uploadedFiles).forEach(data => {
        if (data[0] === file.name) {
          fileAlreadyAdded = true;
        }
      });

      if (!fileTypeRegex.test(file.name)) {
        setErrorWithReadOnly(true);
        setFileError(file, unsupportedFileFormatErrorMessage);
        setErrors(prev => {
          return {
            ...prev,
            [name]: ''
          }})
        return;
      }

      if (file.size > cvMaxSizeInBytes) {
        setErrorWithoutFilesPreview(maxSizeErrorMessage);
        setErrors(prev => {
          return {
            ...prev,
            [name]: maxSizeErrorMessage.generalMessage
          }
        });
        setUploadedFiles({});
        setFormData(prev => {
          const data = prev[currentStep] ? {...prev[currentStep]} : {};
          delete data[name];
          return {...prev, [currentStep]: data};
        });
        return;
      }

      if (!fileAlreadyAdded) {
        if ((currentFilesUsedCount + (index + 1)) > maxNumberOfFiles) {
          setErrorWithoutFilesPreview(tooManyFilesErrorMessage);
          setErrors(prev => {
            return {
              ...prev,
              [name]: tooManyFilesErrorMessage.generalMessage
            }
          });

          setUploadedFiles({});
          setFormData(prev => {
            const data = prev[currentStep] ? {...prev[currentStep]} : {};
            delete data[name];
            return {...prev, [currentStep]: data};
          });
        }
        else {
          sendFileToServer(file);
          setShowUploaderWidget(false);
        }
      }
    });
  };

  const removeFile = filename => {
    setUploadedFiles(prev => {
      const data = {...prev};
      delete data[filename];
      return data;
    });
    setAdditionalData(prev => {
      const data = {...prev};
      if (data.uploadedFiles) {
        delete data.uploadedFiles[filename];
      }
      return data;
    })
    if (hasErrors) {
      setErrors(prev => {
        delete prev[name];
        return {
          ...prev
        }
      });
    }
    // This doesn't actually handle multiple files, as we remove all the files here, not just one.
    setFormData(prev => {
      const data = prev[currentStep] ? {...prev[currentStep]} : {};
      if (preloadAlreadyExistingFile && isEditable) {
        data[name] = {
          delete: true
        };
      }
      else {
        delete data[name];
      }
      return {...prev, [currentStep]: data};
    });
    // setState((prev) => {
    //   prev.files.forEach((fileData, index) => {
    //     if (fileData.originalname === filename) {
    //       prev.files.splice(index, 1);
    //     }
    //   });
    //   return {
    //     ...prev
    //   }
    // });

    clearAllErrors();
  };

  const clearAllErrors = () => {
    setErrorWithoutFilesPreview(null);
    setErrorWithReadOnly(false);
    setErrors(prev => {
      if (prev && prev[name]) {
        delete prev[name];
      }
      return {
        ...prev
      }
    });
  };

  const googleDriveMaxSizeValidation = () => {
    return setErrors(prev => {
      return {
        ...prev,
        [name]: maxSizeErrorMessage.generalMessage
      }})
  }

  const handleGoogleDrive = data => {
    clearAllErrors();

    if (data.action === 'picked') {
      if (!googleToken) {
        return false;
      }

      let successfullyUploadedFiles = [];
      Object.entries(uploadedFiles).forEach(data => {
        if (!data.error) {
          successfullyUploadedFiles.push(data);
        }
      });

      const currentFilesUsedCount = successfullyUploadedFiles.length;
      data.docs.forEach((doc, index) => {
        const {id, name} = doc;
        let fileAlreadyAdded = false;

        successfullyUploadedFiles.forEach(data => {
          if (data[0] === name) {
            fileAlreadyAdded = true;
          }
        });

        if (!fileAlreadyAdded) {
          if ((currentFilesUsedCount + (index + 1)) > maxNumberOfFiles) {
            setFileError({name}, tooManyFilesErrorMessage.title);
          }
          if (doc.sizeBytes > cvMaxSizeInBytes) {
            // Removing files.
            setUploadedFiles({});
            setFormData(prev => {
              const data = prev[currentStep] ? {...prev[currentStep]} : {};
              delete data[name];
              return {...prev, [currentStep]: data};
            });
            setErrors(prev => {
              return {
                ...prev,
                [name]: maxSizeErrorMessage.generalMessage
              }})
            setErrorWithoutFilesPreview(maxSizeErrorMessage);
            return googleDriveMaxSizeValidation();
          }
          else {
            let blob = null;
            const xhr = new XMLHttpRequest();
            xhr.open('GET', `https://www.googleapis.com/drive/v2/files/${id}?alt=media`);
            xhr.setRequestHeader('Authorization', `Bearer ${googleToken}`);
            xhr.responseType = "blob";
            xhr.onload = function () {
              blob = xhr.response;
              const file = new Blob([blob], {type: doc.mimeType});
              file.name = doc.name;
              file.originalname = doc.name;
              sendFileToServer(file);
            };
            xhr.send();
            setShowUploaderWidget(false);
          }
        }
      })

    }
  };

  const isReadOnly = (state && state.hasOwnProperty(name) && state[name] && !state[name].hasOwnProperty('delete') && Object.keys(state[name]).length >= maxNumberOfFiles);
  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  const uploadedItems = [];
  if (Object.keys(uploadedFiles).length) {
    // Reorder files, so that the errors are at the end.
    let errorFiles = {};
    let successFiles = {};
    Object.keys(uploadedFiles).forEach(filename => {
      const file = uploadedFiles[filename];
      if (file.error) {
        errorFiles[filename] = file;
      }
      else {
        successFiles[filename] = file;
      }
    });
    let reorderedFiles = {...successFiles, ...errorFiles};
    Object.keys(reorderedFiles).map((filename, index) => {
      const file = uploadedFiles[filename];
      const renderSize = () => {
        return process.env.REACT_APP_HIDE_SIZE_MB && process.env.REACT_APP_HIDE_SIZE_MB === 'true' ? '' :
          <span className="upload-list__info text--alternative" data-rs-closable-fadeout="">{formatFileSize(file.size)}</span>;
      }
      if (hasErrors || file.hasOwnProperty('error')) {
        // Render errors.
        uploadedItems.push(
          <li className="closable upload-list__item upload-list__item--error" {...{[`data-rs-file-upload-${index}`]: ''}} key={filename}>
            <span className="upload-list__link" data-rs-closable-fadeout="">{filename}</span>
            <span className="upload-list__info text--alternative" data-rs-closable-fadeout="">{intl.formatMessage({id: 'UploadField.Error'})}</span>
            <div className="tooltip tooltip--icon" data-rs-closable-fadeout="">
              <div className="tooltip__trigger" tabIndex={index}>
                <Icon type='info' className='icon' />
                <span className="tooltip__content">
                  <span className="tooltip__text">{(errors && errors.hasOwnProperty(name) && errors[name]) || (file.hasOwnProperty('error') && file.error)}</span>
                  <span className="tooltip__pointer"/>
                </span>
              </div>
            </div>
            <button className="button--icon-only upload-list__remove" data-rs-closable={`data-rs-file-upload-${index}`} onClick={() => removeFile(filename)} aria-label={intl.formatMessage({ id: "Close" })} type="button">
              <Icon type='close-16' className='icon icon--inline icon--s' />
            </button>
          </li>
        );
      }
      else if (file.uploadProgress !== 100) {
        // Render upload progress.
        uploadedItems.push(
          <li className="closable upload-list__item upload-list__item--uploading" key={filename}>
            <a className="upload-list__link" data-rs-closable-fadeout="">{filename}</a>
            <div className="indicator-percentage">
              <div className="indicator-percentage__background">
                <div className="indicator-percentage__amount" style={{width: `${file.uploadProgress}%`}}/>
              </div>
              <span className="indicator-percentage__percentage">{file.uploadProgress}%</span>
            </div>
          </li>
        );
      }
      else {
        let cvInState = state && state[name];
        // Render completed.
        uploadedItems.push(
          <li className={`closable upload-list__item ${!isInitialFileAlreadyExists && 'upload-list__item--success'}`} {...{[`data-rs-file-upload-${index}`]: ''}} key={filename}>
            <span className="upload-list__link" data-rs-closable-fadeout="">{filename}</span>
            {renderSize()}
            <Icon type='check' className='icon upload-list__success' />
            <button className="button--icon-only upload-list__remove" data-rs-closable={`data-rs-file-upload-${index}`} onClick={() => removeFile(filename)} aria-label={intl.formatMessage({ id: "Close" })} type="button">
              <Icon type='close-16' className='icon icon--inline icon--s' />
            </button>
          </li>
        );
      }
    });
  }

  if (isInitialFileAlreadyExists && !showUploaderWidgetBox) {
    return (
      <div className={`form-group form-group--upload ${hasErrors ? 'form-group--error' : null}`}>
        {renderLabel()}
        <ul className="upload-list">{uploadedItems}</ul>
        {renderErrors()}
      </div>
    );
  }

  // Needed when validating the same file multiple times.
  const onClick = (e) => {
    e.target.value = '';
  }

  const onDragStart = () => {
    setDragInProgress(true);
  }

  const onDragEnd = () => {
    setDragInProgress(false);
  }

  const onDrop = () => {
    // Resetting all errors.
    setDragInProgress(false);
    clearAllErrors();
  }

  return (
    <>
      <div className={`form-group form-group--upload ${hasErrors ? 'form-group--error' : ''} ${(isReadOnly || errorWithReadOnly) ? 'form-group--read-only' : ''} ${divClasses}`}>
        {renderLabel()}
        {!required && <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        <div className="form-group__input">
          <input id={name} type="file" title="" tabIndex="0" required={required} name={name} accept={allowedFileFormats} onChange={onChange} multiple={true} ref={inputRef} onClick={onClick} onDrop={onDrop} disabled={(isReadOnly || errorWithReadOnly)} />
          <div className={`upload ${dragInProgress ? 'upload--drag-over' : ''}`} data-rs-upload="" onDragEnter={onDragStart} onDragOver={onDragStart} onDragEnd={onDragEnd} onDragLeave={onDragEnd}>
            <div className="upload__content">
              {
                (isReadOnly || errorWithReadOnly) ?
                  <span>
                    <div className="upload__text">
                      <span className="text--alternative">{intl.formatMessage({id: "UploadField.UploadSuccessful"})}</span>
                    </div>
                    <p className="text--alternative"> {intl.formatMessage({id: "UploadField.SuccessfulSubText"})}</p>
                  </span>
                  :
                  (!errorWithoutFilesPreview ?
                    <>
                    <div className="upload__text">
                      <Icon type='attachment' className='icon icon--inline' />
                      <span className="upload__add">
                        {intl.formatMessage({id: "UploadField.AddFiles"})}
                      </span>
                      <span> </span>
                      <span className="hidden--until-l">
                        {intl.formatMessage({id: "UploadField.OrDropHere"})}
                      </span>
                    </div>
                    <p>
                      {
                        intl.formatMessage({id: "UploadField.AlternativeText"}, {
                          allowedFiles: allowedFileFormats,
                          maxFileSize: maxFileSize
                        })
                      }
                    </p>
                  </>
                    :
                    <>
                      <div className="upload__text">
                        {errorWithoutFilesPreview.title}
                      </div>
                      <p>
                        {errorWithoutFilesPreview.message}
                      </p>
                    </>)
              }
            </div>
            <div className="upload__content upload__content--drop">
              <span>{intl.formatMessage({id: "UploadField.DropFileHere"})}</span>
            </div>
          </div>
        </div>
        <div className="upload-choices">
          {
            useGoogleDrive === true ?
              <GooglePicker
                clientId={clientId}
                developerKey={developerKey}
                scope={['https://www.googleapis.com/auth/drive.file']}
                onChange={handleGoogleDrive}
                onAuthFailed={data => Logger.error(data, "FormElements/UploadField", {"context": "on auth failed"})}
                multiselect={maxNumberOfFiles > 1}
                navHidden={true}
                authImmediate={false}
                mimeTypes={process.env.REACT_APP_GOOGLE_DRIVE_ALLOWED_FILE_FORMATS.split(', ')}
                viewId={'DOCS'}
                onAuthenticate={token => setGoogleToken(token)}
                disabled={(isReadOnly || errorWithReadOnly)}
              >
                <button type="button" className="upload google_picker button--clean" data-attr-dropzone-id="edit-choose-file">
                  <span className="upload__add">{intl.formatMessage({id: 'UploadField.GoogleDrive.Label'})}</span>
                </button>
              </GooglePicker> : null
          }
          {
            useDropbox === true ?
              <DropboxChooser
                appKey={dropboxKey}
                success={files => {
                  clearAllErrors();

                  files.forEach(file => {
                    if (file.bytes > cvMaxSizeInBytes) {
                      setUploadedFiles({});
                      setFormData(prev => {
                        const data = prev[currentStep] ? {...prev[currentStep]} : {};
                        delete data[name];
                        return {...prev, [currentStep]: data};
                      });
                      setErrors(prev => {
                        return {
                          ...prev,
                          [name]: maxSizeErrorMessage.generalMessage
                        }})
                      setErrorWithoutFilesPreview(maxSizeErrorMessage);
                      return;
                    }
                    let blob = null;
                    const xhr = new XMLHttpRequest();
                    xhr.open('GET', file.link);
                    xhr.responseType = "blob";
                    xhr.onload = function () {
                      blob = xhr.response;
                      const fileBlob = new Blob([blob]);
                      fileBlob.name = file.name;
                      fileBlob.originalname = file.name;
                      sendFileToServer(fileBlob);
                    };
                    xhr.send();
                    setShowUploaderWidget(false);
                  })
                }}
                cancel={e => {}}
                multiselect={maxNumberOfFiles > 1}
                linkType={'direct'}
                disabled={(isReadOnly || errorWithReadOnly)}
                sizeLimit={cvMaxSizeInBytes}
                extensions={allowedFileFormats.split(', ')} >
                <button type="button" className="upload dropbox_chooser button--clean">
                  <span className="upload__add">{intl.formatMessage({id: 'UploadField.Dropbox.Label'})}</span>
                </button>
              </DropboxChooser> : null
          }
        </div>
        { !errorWithoutFilesPreview && uploadedItems && <ul className="upload-list">{uploadedItems}</ul>}
        {renderErrors()}
      </div>
    </>
  )
}

export default injectIntl(UploadField);
