import React, { useState } from "react";
import {injectIntl} from "react-intl";

import Checkbox from "@FormElements/Checkbox";
import Toast from "@Components/Toast";

const CheckboxWithToast = (props) => {
	const [showToast, setShowToast] = useState(false);
	const { intl, settings: { toastText } } = props;

	const onToastShow = (e) => {
		e.preventDefault();

		setShowToast(true);
	};

	const onToggleHide = (e) => {
		e.preventDefault();
		setShowToast(false);
	};

	return (
		<>
			{showToast && toastText && (
				<>
					<div className="form-group"></div>
					<Toast message={intl.formatMessage({ id: toastText })} show={showToast} onClose={onToggleHide} />
				</>
			)}
			<Checkbox {...props} onClickLinkCb={onToastShow} />
		</>
	);
};

export default injectIntl(CheckboxWithToast);
