import React from 'react';
import {injectIntl} from "react-intl";
import Icon from "@Components/Icon";

function SelectField({intl, settings, ...rest}) {
  const {label, required = true, state, hidden = false, skipOnEmpty = false, errors, disabled = false, description = false, divClasses = ''} = settings || {};
  const {options} = settings || [];
  let {name} = settings || {};
  if (rest.hasOwnProperty('name')) {
    name = rest.name;
  }

  if (skipOnEmpty) {
    rest["data-skip-empty-field"] = true;
  }

  const hasErrors = (errors && ((errors.hasOwnProperty(name) && errors[name].length) || errors.hasOwnProperty('generalError')));
  const id = `field--select--${name}`;

  const renderLabel = () => {
    if (!label) {
      return <label className="form-group__label" />
    }
    else {
      return (
        <label className="form-group__label" htmlFor={id}>
          {intl.formatMessage({id: label})}
          {!required &&
          <span className="form-group__optional"> {intl.formatMessage({id: 'FormElements.Label.Optional'})}</span>}
        </label>
      );
    }
  };

  const renderOptions = () => {
    const optionsRenderable = [];
    if (options) {
      options.forEach((option, index) => {
        optionsRenderable.push(
          <option value={option.value} key={`select-${name}-option-${index}`}>{option.isTranslation ? intl.formatMessage({id: option.label}) : option.label}</option>
        );
      });
    }
    return optionsRenderable;
  };

  const renderErrors = () => {
    const renderable = [];
    if (hasErrors && errors.hasOwnProperty(name) && errors[name].length) {
      renderable.push(
        <div key={`errorField-${name}`} className="form-group__feedback">{errors[name]}</div>
      );
    }
    return renderable;
  };

  if (hidden) {
    return '';
  }

  const renderDescription = () => {
    if (!description) {
      return '';
    }
    return (
      <div className="form-group__message">{intl.formatMessage({id: description})}</div>
    )
  };

  return (
    <div className={`form-group ${hasErrors ? 'form-group--error' : ''} ${divClasses}`}>
      {renderLabel()}
      <div className="form-group__input">
        <select
          id={id}
          name={name}
          required={required}
          data-rs-untouched=""
          value={(state && state.hasOwnProperty(name) ? state[name] : '')}
          disabled={disabled}
          {...rest}
        >
          {renderOptions()}
        </select>
        <Icon className='select-arrow icon' type='chevron-down'/>
      </div>
      {renderDescription()}
      {renderErrors()}
    </div>

  );

}

export default injectIntl(SelectField);
