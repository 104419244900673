import React from 'react';
import {Link as RouterLink} from "react-router-dom";

const Link = ({to, children, ...rest}) => {
  if (rest['attributes']) {
    return <a href={to} {...rest['attributes']}>{children}</a>;
  }
  if (to.startsWith('http')) {
    return <a href={to} target="_blank" rel="noopener noreferrer" {...rest}>{children}</a>;
  } else {
    return <RouterLink to={to} {...rest}>{children}</RouterLink>;
  }
}

export default Link;
