import React, { useState, useContext } from "react";
import axios from "axios";
import { injectIntl } from "react-intl";
import FormContext from "formContext";
import { useCookies } from "react-cookie";
import JobCard from "@Components/JobCard";
import ShowMore from "@Components/ShowMore";
import useDateHelper from "dateHelper";
import {
  pushFavJobRemovedFromListEvent,
  pushFavoriteDatalayerEvent,
} from "dataLayerHelper";
import useJobCardHelper from "jobCardHelper";
import useApplicationStatuses from "useApplicationStatuses";
import useOrbitComponent from "useOrbitComponent";

function JobCardList({jobs, intl, showMoreVisibility, offsetPerPage, showDefaultCard, jobsPageUrl, isSlider, isRecommendedJob}) {
  const trackAndTrace = process.env.REACT_APP_TRACK_AND_TRACE === 'true';
  const enabledRejectedApplications = process.env.REACT_APP_CARDS_REJECTED_APPLICATIONS === 'true';

  const {additionalData, isAuthenticated, showToast, hideToast, setToastMessage, setToastLinks, setAdditionalData} = useContext(FormContext);
  const { user } = additionalData;
  const { increaseCounterHeader } = useJobCardHelper();
  const { dateHasExpired } = useDateHelper();
  const applicationStatuses = useApplicationStatuses(trackAndTrace ? user : undefined);
  const [offset, setOffset] = useState(1);
  const [cookies, setCookie] = useCookies([]);
  const [ref] = useOrbitComponent(isSlider ? 'carousel' : '');
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1);

  // Event handlers
  const onUndo = async (lastJobData) => {
    let lastDeletedJob = JSON.parse(localStorage.getItem("LastDeletedfavJob"));

    // Handle cases where there is one and no fav job left
    const { id } = lastJobData;

    // Insert item in API if a user is logged in and update local storage.
    if (isAuthenticated) {
      putToServer(id, lastDeletedJob);
    }
    else {
      updateLocalStorage(jobs);
      increaseCounterHeader();

      // Put it back to fav jobs cookie (used on job search).
      const favoriteJobs = cookies.favJobs ? cookies.favJobs : [];
      favoriteJobs.push(id);
      setCookie("favJobs", favoriteJobs, {expires: expirationDate, path: '/', secure: !process.env.REACT_APP_ISDEV, sameSite: 'lax'});
    }

    const event = new Event("saveFavoriteJob");
    document.dispatchEvent(event);
    updateJobsState(jobs);
    hideToast();
  }

  // Insert favourite jobs to server.
  const putToServer = (jobId, payload) => {
    // Remove properties from object to avoid conflict with API.
    delete payload["jobId"];
    delete payload["userId"];
    delete payload["created_timestamp"];

    axios.put(`${process.env.REACT_APP_API_PREFIX}/user/saved-jobs/${jobId}`,payload).then(response => {
      increaseCounterHeader();
      updateLocalStorage({...jobs});
    });
  }
  // Updates local storage with favorite jobs list
  const updateLocalStorage = jobs => {
    if (jobs.length < 1) return;
    localStorage.setItem("favJobs", JSON.stringify(jobs));
  }

  // Remove favourite jobs from server and trigger jobslist re render to display correct fav jobs.
  const removeFromServer = (jobId) => {
    axios.delete(`${process.env.REACT_APP_API_PREFIX}/user/saved-jobs/${jobId}`).then(response => {
      removeFromLocalStorage(jobId);
    });
  }

  const removeFromLocalStorage = (jobId) => {
    const temp = [...jobs];
    const newJobs = temp.filter(item => item.jobId !== jobId);

    increaseCounterHeader(false);
    localStorage.setItem("favJobs", JSON.stringify(newJobs));

    // Handle fav jobs cookie (used on job search).
    const favoriteJobs = cookies.favJobs ? cookies.favJobs : [];
    favoriteJobs.splice(favoriteJobs.indexOf(jobId), 1);
    setCookie("favJobs", favoriteJobs, {expires: expirationDate, path: '/', secure: !process.env.REACT_APP_ISDEV, sameSite: 'lax'});
  }

  const onRemoveJob = (id) => {
    const temp = [...jobs];
    const job = temp.filter(item => item.jobId === id)[0];
    const newJobs = temp.filter(item => item.jobId !== id);
    const index = temp.findIndex(el => el.jobId === id);
    const expired = dateHasExpired(job.jobExpireDate);

    // If user is logged in, delete from both API and local storage.
    if (isAuthenticated) {
      removeFromServer(id)
    }
    else {
      removeFromLocalStorage(id);
    }

    // Update local state after removing a job.
    updateJobsState(newJobs);

    // Store last deleted job to allow undo
    localStorage.setItem("LastDeletedfavJob", JSON.stringify(job));

    // Dispatch event, a listener will be added in the shared scripts
    // for the OpCos that need to act on this.
    const event = new Event("deleteFavoriteJob");
    document.dispatchEvent(event);

    if (process.env.REACT_APP_CARDS_FAVJOBS_DATALAYER === "true") {
      pushFavJobRemovedFromListEvent();
    }

    const lastJobData = {
      id: job?.jobId,
      index: index
    };

    const links = [{
      text: intl.formatMessage({id: 'Undo'}),
      onClick: e => onUndo(lastJobData)
    }];

    // Move to remove job function
    if (!expired) {
      setToastMessage(intl.formatMessage({id: 'Job.SuccessfullyRemoved'}));
      setToastLinks(links);
      showToast();
    }

    // Send unfavorite job to dataLayer
    pushFavoriteDatalayerEvent(job.title);
  };

  const updateJobsState = (jobs) => {
    setAdditionalData(prev => {
      return {
        ...prev,
        favouriteJobs: {
          count: jobs.length,
          jobs: jobs
        }
      }
    })
  }

  let jobsList = jobs;

  if (showMoreVisibility) {
    jobsList = jobs.slice(0, offsetPerPage * offset);
  }
  else {
    jobsList = jobs.slice(0, offsetPerPage);
  }

  const renderCards = () => {
    return jobsList.map((job) => {
      let applicationId, applicationStatus, applicationRejected;

      if (trackAndTrace) {
        applicationId = job.details && job.details.applicationId;

        if (applicationId) {
          applicationStatuses && Array.isArray(applicationStatuses) && applicationStatuses.find((application) => {
            if (application.applicationId === applicationId) {
              applicationStatus = application.applicationStatus;
              if (enabledRejectedApplications) {
                applicationRejected = application.rejected;
              }
            }
          });
        }
      }

      const jobCardProps = isRecommendedJob ? {} : {
        onFavIconClick: onRemoveJob,
        application: trackAndTrace && {
          hasId: applicationId,
          status: applicationStatus,
          rejected: applicationRejected && applicationRejected === 'true',
        }
      }

      return (
        <JobCard
          job={job}
          key={`job-${job.jobId}-${job.jobPostDate}-${job.jobExpireDate}`}
          bg={"bg-variant-brand-tertiary"}
          {...jobCardProps}
        />
      );
    });
  }

  const onShowMore = () => {
    let newOffset = offset + 1;
    setOffset(newOffset);
  };

  const DefaultJobCard = ({ label }) => (
    <li className="cards__item cards__item--more bg-variant-brand-primary" data-rs-card="">
      <a href={jobsPageUrl} className="cards__link" tabIndex="0">
        <img src="/myrandstad-app/assets/image/favorites/Binoculars_Icon_White_RGB.svg" alt="" />
        <div className="cards__footer">
          <span className="button button--s button--full-width button--off-white">{label}</span>
        </div>
      </a>
    </li>
  );


  let cardClasses = "cards__list--format-grid";
  let dataCarousel = "";

  if (isSlider) {
    cardClasses = "cards__list--format-carousel carousel--on-s carousel--on-m";
    dataCarousel = "cards";
  }

  let seeMoreCount = offsetPerPage;
  if (process.env.REACT_APP_CARDS_CUSTOM_OFFSET === "true") {
    seeMoreCount =  (jobs.length - jobsList.length) >= offsetPerPage ? offsetPerPage : jobs.length - jobsList.length;
  }

  return (
    <>
      <div className="cards">
        <ul ref={ref} className={`cards__list ${cardClasses}`} data-rs-carousel={dataCarousel} key={jobs.length}>
          {jobs && renderCards()}
          {showDefaultCard && <DefaultJobCard label={intl.formatMessage({id: 'Job.Find.Other.Jobs'})} />}
        </ul>
      </div>
      {showMoreVisibility && (
        <ShowMore jobsList={jobsList} totalJobs={jobs} offsetPerPage={seeMoreCount} onClick={onShowMore} />
      )}
    </>
  )
}

export default injectIntl(JobCardList)
