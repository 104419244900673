import React, {useContext} from 'react';
import {injectIntl} from "react-intl";
import AutoSuggestField from "@FormElements/AutoSuggestField";
import FormContext from "formContext";

function CountryAutoSuggest({intl, settings, ...rest}) {
  const {additionalData, currentStep, setFormData, formData} = useContext(FormContext);

  const countryList = additionalData.countries;
  let countryOptions = [];
  let countryFiltered = [];
  if (countryList) {
    countryOptions = Object.keys(countryList).map(function(key) {
      const value = countryList[key];
      if (formData && formData[currentStep] && formData[currentStep][settings.name]) {
        const formValue = formData[currentStep][settings.name];
        const match = value.toLowerCase().indexOf(formValue.toLowerCase()) !== -1;

        if (match) {
          countryFiltered.push(countryList[key]);
        }
      }
      return countryList[key];
    })
  }

  if (countryFiltered.length > 0) {
    settings.options = countryFiltered;
  } else {
    settings.options = countryOptions;
  }

  rest.onChange = e => {
    const {value} = e.target;
    const match = countryOptions.find(el => el === value);
    let matchedCountryCode = '';
    // Get country code from additionalData and store in form
    Object.keys(countryList).map(key => {
      let currentValue = additionalData.countries[key];
      if (currentValue.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
        matchedCountryCode = key;
      }
    });
    if (match) {
      setFormData(prev => {
        if (!prev[currentStep][settings.name]) {
          prev[currentStep][settings.name] = '';
          prev[currentStep]['countryCode'] = '';
        }
        prev[currentStep][settings.name] = match;
        prev[currentStep]['countryCode'] = matchedCountryCode;
        return {
          ...prev
        }
      });
    }
    else {
      setFormData(prev => {
        if (!prev[currentStep]) {
          prev[currentStep] = {};
        }
        prev[currentStep][settings.name] = value;
        prev[currentStep]['countryCode'] = '';
        return {
          ...prev
        }
      });
    }
  };

  return (
    <AutoSuggestField
      settings={settings}
      {...rest}
    />
  );
}

export default injectIntl(CountryAutoSuggest);
