/**
 * React hook to change all occurrences of the user names and initials in login popup and header.
 * @param {object} formData The formData state object.
 */

const useReplaceInitials = (formData) => {
  const userName = document.querySelector('#navigation__service-user-text');
  const userInitials = document.querySelectorAll('.navigation__service-my-randstad__placeholder');
  const popupInitials = document.querySelector('.avatar__initials');
  const popupUserName = document.querySelector('#person-profile__name');

  const userFirstName = formData.first_name ? formData.first_name : formData.FirstName ? formData.FirstName : '';
  const userLastName = formData.last_name ? formData.last_name : formData.LastName ? formData.LastName : ''

  if (userFirstName && userLastName) {
    if (!!userName) {
      userName.textContent = `${userFirstName} ${userLastName}`;
    }
    if (!!userInitials) {
      userInitials.forEach(item => {
        item.textContent = `${userFirstName.charAt(0)}${userLastName.charAt(0)}`;
      })
    }
    if (!!popupInitials) {
      popupInitials.textContent = `${userFirstName.charAt(0)}${userLastName.charAt(0)}`;
    }
    if (!!popupUserName) {
      popupUserName.textContent = `${userFirstName} ${userLastName}`;
    }
  }
};

export default useReplaceInitials;