import React, {useState} from 'react';
import {injectIntl} from "react-intl";
import Link from "@Components/Link";
import Icon from "@Components/Icon";
import GetRouteByName from "getRouteByName";

function DashboardJobAlerts({intl, children, id, leftLinkText, leftLinkPath, isLeftLinkExternal, linkText, linkPath, isExternal = false}) {

  const [modal, setModal] = useState(false);
  const jobsPageUrl = GetRouteByName('jobs').url;

  const renderLink = () => {
    if (!linkPath || !linkText) {
      return '';
    }
    if (isExternal) {
      return (
        <a className="button button--m mt-m" href={linkPath}>{linkText}</a>
      );
    }
    else {
      return (
        <Link className="button button--m mt-m" to={linkPath}>{linkText}</Link>
      );
    }
  }

  const modalOpen = (event) => {
    event.preventDefault();

    setModal(true);
    if (typeof window !== undefined) {
      document.querySelector("html").classList.add("modal-open")
    }
  }

  const modalClose = () => {
    setModal(false);
    if (typeof window !== "undefined") {
      document.querySelector("html").classList.remove("modal-open");
    }
  }

  const getModal = () => {
    return (
      <>
        <div className="modal modal--active modal__job-alerts" onClick={modalClose}>
          <div className="modal__dialog bg-variant-brand-tertiary" role="dialog" aria-modal="true" aria-labelledby="#" data-rs-modal-dialog="">
            <div className="modal__header" data-rs-modal-header="">
              <div className="modal__title" style={{ textTransform: 'none' }}>{intl.formatMessage({id: "JobAlerts.Section.Modal.HeaderText"})}</div>
              <button onClick={modalClose} className="button--icon-only modal__close" data-rs-modal-close-trigger="" aria-label={intl.formatMessage({ id: "Close" })}>
                <Icon type='close' className='icon icon--inline hidden--from-l fill-brand--blue icon--alternative'/>
                <Icon type='close-30' className='icon icon--l icon--inline hidden--until-l icon--alternative'/>
              </button>
            </div>
            <div className="modal__main" data-rs-modal-main="">
              <img className="modal__image mb-s" src="/myrandstad-app/assets/image/job-alerts/Loudspeaker_Icon_Blue_RGB.svg"/>
              <p className="form__header">{intl.formatMessage({id: "JobAlerts.Section.Modal.MainText"})}</p>
            </div>
            <div className="modal__footer" data-rs-modal-footer="">
              <a href={jobsPageUrl} className="button button--m button--filled button--full-width">
                {intl.formatMessage({id: "JobAlerts.Section.Modal.ButtonText"})}
              </a>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      { modal && getModal() }

      <div id={`${id}`} className="block--s">
        <div className="block__wrapper wrapper pt-m l:pt-xl">
          <div className="block__header">
            <h2 className="block__title">{intl.formatMessage({id: 'JobAlerts.Section.Title'})}</h2>
            <a href={'#'} className="block__control" onClick={modalOpen}>
              <Icon type="add" className="icon icon--inline fill-brand--blue"/> {intl.formatMessage({id: 'JobAlerts.BlockControl.Label'})}
            </a>
          </div>
          <div className="block__content block__content--s block__content--align-right">
            { children }
            {renderLink()}
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(DashboardJobAlerts);
