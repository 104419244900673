import React, {useContext, useState, useEffect} from "react";
import {injectIntl} from "react-intl";
import FormContext from "formContext";
import Icon from "@Components/Icon";
import {createIntl, createIntlCache} from 'react-intl';

function JobAlertList({intl, jobAlerts, perPage = 5, email = null, uuid = null}) {
  const {
    setFormName,
    setFormData,
    setModalOpen,
    setEditedItemKey,
    translations,
  } = useContext(FormContext);
  const [page, setPage] = useState(0);
  const [currentPageItems, setCurrentPageItems] = useState([]);
  const totalPages = Math.ceil(jobAlerts.alerts.length / perPage);

  useEffect(() => {
    // If an item was deleted last on a page, we load the previous page.
    if (page > totalPages - 1) {
      setPage(totalPages - 1);
    }
    const pageItems = jobAlerts.alerts.slice(page * perPage, page * perPage + perPage);
    if (!pageItems) return;

    setCurrentPageItems(pageItems);
  }, [page, jobAlerts])

  // Opens edit form modal.
  const handleEditJobAlert = (index) => {
    const item = currentPageItems[index];
    const {key} = item;
    const itemProps = ['searchName', 'frequency', 'searchFilters'];
    let data = {};
    itemProps.forEach(key => item[key] !== undefined ? data[key] = item[key] : false);

    setFormName('job-alert');
    setEditedItemKey(key);
    setFormData([data]);
    setModalOpen(true);
  }

  // Opens ConfirmationModal component from dashboard page.
  const openDeleteConfirmationModal = (index) => {
    if (index === null) {
      setFormData([{index: null}]);
      setModalOpen(true);
      return;
    }

    const calcPageItemIndex = page * perPage + index;
    const item = jobAlerts.alerts[calcPageItemIndex];
    let data = {};
    data['searchName'] = item['searchName'] || '';
    data['index'] = calcPageItemIndex;

    setFormName('');
    setFormData([data]);
    setModalOpen(true);
  }

  const pager = () => {
    const items = [];

    if (page > 0) {
      items.push(
        <li className="pagination__item" key="job-alerts-page-prev">
          <a className="pagination__control" onClick={() => setPage(page - 1)}>
            <Icon type="arrow-left" className="icon icon--inline"/>
            <span className="hidden--visually">{intl.formatMessage({id: 'Utils.Previous'})}</span>
          </a>
        </li>
      );
    }

    for (let i = 0; i < totalPages; i++) {
      items.push(
        <li className="pagination__item" key={`job-alerts-page-${i}`}>
          {page === i && <span>{i + 1}</span>}
          {page !== i && <a className="link" onClick={() => setPage(i)}>{i + 1}</a>}
        </li>
      );
    }

    if (page < totalPages - 1) {
      items.push(
        <li className="pagination__item" key="job-alerts-page-next">
          <a className="pagination__control" onClick={() => setPage(page + 1)}>
            <Icon type="arrow-right" className="icon icon--inline"/>
            <span className="hidden--visually">{intl.formatMessage({id: 'Utils.Next'})}</span>
          </a>
        </li>
      );
    }

    return items;
  };

  const JobAlertItem = ({alert, index}) => {
    let intlMixed = intl;
    const jobLang = alert.searchPage[0] === '/' && alert.searchPage[3] === '/' ? alert.searchPage.substr(1, 2) : process.env.REACT_APP_DEFAULT_LANGUAGE;
    if (jobLang) {
      const cache = createIntlCache();
      intlMixed = createIntl({
        locale: jobLang,
        messages: translations[jobLang]
      }, cache);
    }

    const count = alert.searchFilters ? Object.values(alert.searchFilters).length : 0;
    const frequency = intlMixed.formatMessage({id: `JobAlerts.Frequency.${alert.frequency}`});

    const renderEditButton = () => {
      const variable = process.env.REACT_APP_DISABLE_EDIT_BUTTON_FROM_ALERTS;
      if (variable && variable === 'true') {
        return '';
      }
      return (
        <button data-label="edit job alert" className="button--clean" onClick={e => handleEditJobAlert(index)}>
          <Icon type="eye" className="icon icon--inline icon--hover"/>
          <span className="hidden--visually"/>
        </button>
      );
    }

    return (
      <div className="my-environment-container my-environment-item closable divider pb-m l:pb-l" key={alert.key} id={`job-alert-${alert.key}`}>
        <div className="my-environment-header">
          <h2 className="my-environment-header__title--ml mr-xxs" data-rs-closable-fadeout="">
            <a href={alert.searchPage}>
              {alert.searchName || "-"}
            </a>
          </h2>
          <div className="my-environment__controls mt-xxs">
            {renderEditButton()}
            <button data-label="delete job alert" className="button--clean" onClick={e => openDeleteConfirmationModal(index)}>
              <Icon type="trash" className="icon icon--inline icon--hover"/>
              <span className="hidden--visually"/>
            </button>
          </div>
        </div>
        <div className="my-environment-item__content my-environment-item__content--cta mt-xs l:mt-s">
          <div className="my-environment-item__description mt-xs body-copy">
            <span>{intlMixed.formatMessage({id: 'JobAlerts.JobAlert.FrequencyPerEmail.Text'}, {frequency: frequency})}</span>
            <span>{intlMixed.formatMessage({id: 'JobAlerts.JobAlert.SpecifiedSettings.Text'}, {count: count})}</span>
          </div>
          <a href={alert.searchPage} className="button button--m mt-m">
            {intl.formatMessage({id: 'Utils.ViewResults'})}
          </a>
        </div>
      </div>
    );
  }

  const renderJobAlerts = () => {
    if (!currentPageItems || !jobAlerts || !jobAlerts.count) return null;

    const alerts = [];
    currentPageItems.forEach((alert, index) => {
      alerts.push(<JobAlertItem key={alert.key} alert={alert} index={index}/>)
    });

    if (email && uuid) {
      alerts.push(
        <li className="list-item list-item--job list-item--button" key="delete-all-job-alerts">
          <a className="button button--m" onClick={() => openDeleteConfirmationModal(null)}>
            {intl.formatMessage({id: 'ManageEmailAlerts.DeleteAll.Button.Text'})}
          </a>
        </li>
      )
    }

    return alerts;
  }

  return (
    <>
      {renderJobAlerts()}
      {totalPages > 1 && (
        <nav className="pagination" data-rs-pagination="">
          <ul className="pagination__list" data-rs-pagination-list="">
            {pager()}
          </ul>
        </nav>
      )}
    </>
  )
}

export default injectIntl(JobAlertList);
